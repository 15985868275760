<script setup lang="js">
import FirstSection from '@/components/home-page/FirstSection.vue';
import ThirdSection from '@/components/home-page/ThirdSection.vue';
import FiveSection from '@/components/home-page/FourthSection.vue';
import SeventhSection from '@/components/home-page/SeventhSection.vue';
import NewsSection from '@/components/NewsSection.vue';
import ReviewsSection from '@/components/home-page/ReviewsSection.vue';
import AboutSection from '@/components/home-page/AboutSection.vue';
import SecondSection from '@/components/home-page/SecondSection.vue';
import SixthSection from '@/components/home-page/SixthSection.vue';
import CompanySlider from '@/components/home-page/CompanySlider.vue';
import UseScrollSmoother from '@/components/home-page/UseScrollSmoother.vue';
import {getPage} from '@/api/getPage';
import {ref, watch} from "vue";

const products = ref([]);
const categories = ref([]);
const shops = ref([]);
const posts = ref([]);

/*
async function pageData() {
    const data = await getPage('home');

    if (data.products) {
        products.value = data.products;
        // products.value = transformProducts(data.products);
        // localStorage.setItem('productsHome', JSON.stringify(transformProducts(data.products.slice(0, 4))));
    }

    if(data.categories){
        categories.value = data.categories;
        //localStorage.setItem('categoriesHome', JSON.stringify(data.categories.slice(0,5).reverse()));
    }

    if(data.shops){
        shops.value = [...data.shops.slice(4, 7), ...data.shops.slice(0, 4)];
        // shops.value = data.shops.slice(0, 7);
    }

    if(data.posts) {
        posts.value = data.posts;
    }
}
pageData();
*/

/*
function transformProducts1(products) {
    const result = [
        {
            title: "Для кошек",
            image: "cat-2x.png",
            products: []
        },
        {
            title: "Для собак",
            image: "dog-2x.png",
            products: []
        }
    ];

    const catsProducts = products.filter(product => products.value.animals.includes("cats")).slice(0, 4);
    const dogsProducts = products.filter(product => products.value.animals.includes("dogs")).slice(0, 4);

    result[0].products = catsProducts.map(product => ({
        title: products.value.name,
        slug: products.value.slug,
        image: products.value.image.urlSizeM,
        categoryId: products.value.category_id,
    }));

    result[1].products = dogsProducts.map(product => ({
        title: products.value.name,
        slug: products.value.slug,
        image: products.value.image.urlSizeM,
        categoryId: products.value.category_id,
    }));
    return result;
}

function transformProducts(products) {
    const result = [
        {
            title: "Для кошек",
            image: "cat-2x.png",
            products: []
        },
        {
            title: "Для собак",
            image: "dog-2x.png",
            products: []
        }
    ];

    products.forEach(product => {
        const { name, slug, image, category_id } = product;
        const productEntry = {
            title: name,
            slug: slug,
            image: image.urlSizeM,
            categoryId: category_id
        };

        if (product.animals.includes("cats")) {
            result[0].products.push(productEntry);
        } else if (product.animals.includes("dogs")) {
            result[1].products.push(productEntry);
        }
    });

    return result;
}
*/

</script>



<template>
    <UseScrollSmoother>
        <main class="">

            <FirstSection />
            <SecondSection />
            <ThirdSection />
            <FiveSection />
            <SixthSection />
            <CompanySlider />
            <SeventhSection />
            <NewsSection />
            <ReviewsSection />
            <AboutSection />

        </main>
    </UseScrollSmoother>
</template>

