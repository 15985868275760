﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="109" height="138" viewBox="0 0 109 138" fill="none">
<path d="M106.412 54.587C106.412 83.4195 83.0386 106.793 54.206 106.793C25.3734 106.793 2 83.4195 2 54.587C2 25.7544 25.3734 2.38098 54.206 2.38098C83.0386 2.38098 106.412 25.7544 106.412 54.587Z" fill="white"/>
<path d="M69.0205 51.883C65.9758 51.883 63.5166 54.3188 63.5166 57.34C63.5166 60.3613 65.9992 62.797 69.0205 62.797C72.0417 62.797 74.5243 60.3379 74.5243 57.34C74.5243 54.3422 72.0417 51.883 69.0205 51.883ZM69.0205 65.9354C64.2427 65.9354 60.3549 62.071 60.3549 57.34C60.3549 52.6091 64.2427 48.7447 69.0205 48.7447C73.7983 48.7447 77.6861 52.6091 77.6861 57.34C77.6861 62.071 73.7983 65.9354 69.0205 65.9354Z" fill="#54565A"/>
<path d="M28.6902 53.3353C40.0258 53.3353 44.9676 59.4949 49.3472 64.9519C53.5629 70.1981 57.1931 74.7417 65.8119 74.7417C76.0233 74.7417 84.361 66.4977 84.361 56.3565C84.361 45.3489 75.5549 33.498 56.2329 33.498C39.7916 33.498 28.5966 40.2666 24.8024 46.9649C23.608 49.0727 23.3269 50.9698 24.0061 52.094C24.6853 53.2182 26.3013 53.3821 27.5426 53.3353C27.9408 53.3353 28.3155 53.3353 28.7137 53.3353M65.8353 77.8801C55.6942 77.8801 51.2209 72.306 46.888 66.8958C42.4381 61.3451 38.2224 56.0989 27.6363 56.4736C23.6782 56.6142 21.9685 54.8108 21.2893 53.7334C19.9778 51.6022 20.2588 48.5809 22.0388 45.4425C24.2403 41.5313 28.4092 37.9011 33.7257 35.2312C40.0727 32.046 47.8483 30.3597 56.2329 30.3597C68.6926 30.3597 76.0467 34.997 80.0048 38.8848C84.7826 43.5689 87.4994 49.9159 87.4994 56.3331C87.4994 68.2074 77.7564 77.8566 65.8119 77.8566" fill="#54565A"/>
<path d="M28.7137 58.1597C37.6838 58.1597 41.1735 62.516 45.5531 67.973C49.9094 73.43 54.8745 79.5896 65.8354 79.5896C78.7401 79.5896 89.2326 69.1908 89.2326 56.3798C89.2326 49.5175 86.305 42.7022 81.2227 37.7136C77.0304 33.5915 69.2782 28.6966 56.2564 28.6966C47.6142 28.6966 39.5809 30.4298 32.9997 33.7321C27.3788 36.566 22.9757 40.4304 20.5868 44.6226C18.5024 48.3231 18.2447 51.9533 19.8842 54.6232C21.3597 57.0121 24.217 58.3003 27.7301 58.1832C28.0814 58.1832 28.4093 58.1832 28.7372 58.1832M65.8354 82.728C53.3522 82.728 47.4268 75.327 43.094 69.9403C38.808 64.577 35.9741 61.0405 27.8238 61.3215C23.1396 61.5089 19.2518 59.6587 17.1674 56.2627C14.919 52.6091 15.1532 47.8078 17.8232 43.1003C20.4931 38.3693 25.388 34.0599 31.5476 30.9684C38.5738 27.4553 47.0989 25.5817 56.2329 25.5817C70.3556 25.5817 78.8104 30.9684 83.4242 35.512C89.1154 41.0861 92.3709 48.6978 92.3709 56.4032C92.3709 70.924 80.4733 82.728 65.812 82.728" fill="#54565A"/>
<path d="M35.529 51.8832C35.248 51.8832 34.9669 51.8129 34.7327 51.6724C33.9833 51.2274 33.7256 50.2672 34.1706 49.5412L41.9463 36.5661C42.3912 35.8167 43.3515 35.5825 44.101 36.0275C44.8504 36.4725 45.108 37.4327 44.663 38.1587L36.8874 51.1338C36.5829 51.6256 36.0677 51.9066 35.529 51.9066" fill="#54565A"/>
<path d="M43.8667 57.4339C43.5856 57.4339 43.3046 57.3636 43.0704 57.2231C42.3209 56.7781 42.0867 55.8179 42.5083 55.0918L53.6097 36.5661C54.0546 35.8167 55.0149 35.5825 55.7644 36.0275C56.5138 36.4725 56.7714 37.4327 56.3264 38.1587L45.2251 56.6844C44.9206 57.1763 44.4054 57.4573 43.8667 57.4573" fill="#54565A"/>
<path d="M52.6729 64.7645C52.3919 64.7645 52.1108 64.6943 51.8766 64.5537C51.1271 64.1088 50.8929 63.1485 51.3145 62.4225L65.2029 39.2595C65.6479 38.51 66.6082 38.2758 67.3576 38.7208C68.1071 39.1658 68.3413 40.1261 67.8963 40.8521L54.0079 64.0151C53.7034 64.5069 53.1882 64.7879 52.6495 64.7879" fill="#54565A"/>
<path d="M75.4377 50.3138C75.1566 50.3138 74.8756 50.2435 74.6414 50.103C73.8919 49.658 73.6577 48.6978 74.1027 47.9717L76.234 44.4118C76.679 43.6623 77.6392 43.4281 78.3887 43.8731C79.1382 44.3181 79.3958 45.2783 78.9508 46.0044L76.8195 49.5643C76.515 50.0561 75.9998 50.3372 75.4611 50.3372" fill="#54565A"/>
<path d="M62.3691 72.3059C62.0881 72.3059 61.807 72.2356 61.5728 72.0951C60.8233 71.6501 60.5657 70.6899 61.0107 69.9638L63.0483 66.5444C63.4933 65.795 64.4535 65.5607 65.203 66.0057C65.9525 66.4507 66.1867 67.411 65.7417 68.137L63.7041 71.5564C63.3996 72.0483 62.8844 72.3293 62.3457 72.3293" fill="#54565A"/>
<path d="M54.0079 3.70687C25.9734 3.70687 3.16178 26.3312 3.16178 54.1314C3.16178 81.9317 25.9734 104.556 54.0079 104.556C82.0424 104.556 104.854 81.9317 104.854 54.1314C104.854 26.3312 82.0424 3.70687 54.0079 3.70687ZM54.0079 107.694C24.2169 107.694 0 83.6648 0 54.1314C0 24.598 24.2169 0.568512 54.0079 0.568512C83.7989 0.568512 108.016 24.598 108.016 54.1314C108.016 83.6648 83.7989 107.694 54.0079 107.694Z" fill="#54565A"/>
<path d="M92.7476 133.448C92.7476 132.96 92.6352 132.564 92.4106 132.258C92.186 131.947 91.8825 131.792 91.5002 131.792C91.156 131.792 90.8478 131.919 90.5754 132.172C90.3029 132.42 90.0879 132.765 89.9302 133.204C89.7724 133.639 89.6936 134.084 89.6936 134.538C89.6936 134.848 89.7485 135.13 89.8585 135.384C89.9684 135.632 90.1165 135.826 90.3029 135.964C90.4941 136.098 90.702 136.165 90.9266 136.165C91.266 136.165 91.5742 136.046 91.8514 135.807C92.1334 135.568 92.3533 135.24 92.511 134.824C92.6687 134.404 92.7476 133.945 92.7476 133.448ZM94.3749 133.527C94.3749 134.258 94.2267 134.93 93.9304 135.541C93.6341 136.148 93.2207 136.626 92.6902 136.975C92.1645 137.319 91.5862 137.491 90.9553 137.491C90.3961 137.491 89.8967 137.362 89.457 137.104C89.0173 136.846 88.678 136.483 88.439 136.014C88.2 135.541 88.0806 135.011 88.0806 134.423C88.0806 133.711 88.2335 133.051 88.5394 132.444C88.85 131.837 89.273 131.364 89.8083 131.025C90.3436 130.686 90.9219 130.516 91.5432 130.516C92.0928 130.516 92.5827 130.64 93.0128 130.889C93.4429 131.137 93.7775 131.493 94.0165 131.957C94.2554 132.416 94.3749 132.939 94.3749 133.527Z" fill="#33363C"/>
<path d="M87.2776 132.1C87.0816 132.033 86.8857 131.986 86.6897 131.957C86.4938 131.923 86.2572 131.907 85.98 131.907C85.5116 131.907 85.0791 132.017 84.6824 132.236C84.2905 132.451 83.9751 132.75 83.7361 133.133C83.502 133.515 83.3849 133.924 83.3849 134.358C83.3849 134.922 83.5402 135.36 83.8508 135.67C84.1615 135.981 84.5988 136.136 85.1628 136.136C85.4878 136.136 85.7817 136.098 86.0445 136.022C86.3122 135.945 86.63 135.819 86.998 135.642L86.8188 137.118C86.1975 137.367 85.5523 137.491 84.8832 137.491C84.2714 137.491 83.7266 137.362 83.2486 137.104C82.7755 136.846 82.4075 136.478 82.1446 136C81.8865 135.517 81.7575 134.97 81.7575 134.358C81.7575 133.656 81.9415 133.008 82.3095 132.416C82.6823 131.823 83.1961 131.359 83.8508 131.025C84.5104 130.686 85.2368 130.516 86.0302 130.516C86.2644 130.516 86.5129 130.533 86.7758 130.566C87.0386 130.595 87.2728 130.635 87.4783 130.688L87.2776 132.1Z" fill="#33363C"/>
<path d="M79.8434 137.312H78.2734L78.6104 134.86H78.3308C78.0297 134.86 77.7788 134.891 77.5781 134.953C77.3821 135.016 77.2005 135.116 77.0332 135.255C76.8707 135.393 76.658 135.649 76.3952 136.022L75.4991 137.312H73.6351L74.9399 135.477C75.1406 135.204 75.3628 134.977 75.6066 134.796C75.8551 134.609 76.1132 134.478 76.3808 134.401C75.9746 134.292 75.6472 134.103 75.3987 133.835C75.1549 133.563 75.0331 133.223 75.0331 132.817C75.0331 132.392 75.1502 132.017 75.3844 131.692C75.6233 131.362 75.9579 131.113 76.388 130.946C76.8181 130.774 77.32 130.688 77.8935 130.688H80.7897L79.8434 137.312ZM78.7609 133.785L79.0118 131.95H78.1301C77.6665 131.95 77.308 132.033 77.0547 132.201C76.8014 132.363 76.6748 132.609 76.6748 132.939C76.6748 133.503 77.1001 133.785 77.9508 133.785H78.7609Z" fill="#33363C"/>
<path d="M67.4627 130.688C67.7207 131.347 67.9764 131.988 68.2297 132.609C68.4878 133.231 68.7411 133.869 68.9896 134.523C69.4484 133.869 69.9025 133.231 70.3517 132.609C70.8058 131.988 71.2574 131.347 71.7067 130.688H73.205L72.7247 137.312H71.1188C71.1714 136.595 71.224 135.893 71.2765 135.204C71.3339 134.516 71.3841 133.814 71.4271 133.097C70.9539 133.814 70.4832 134.516 70.0148 135.204C69.5464 135.893 69.0733 136.595 68.5953 137.312C68.3229 136.586 68.0481 135.873 67.7709 135.176C67.4985 134.478 67.2213 133.766 66.9393 133.039C66.7912 133.766 66.6406 134.478 66.4877 135.176C66.3347 135.873 66.1818 136.586 66.0289 137.312H64.4589L65.907 130.688H67.4627Z" fill="#33363C"/>
<path d="M57.1035 130.516C57.6006 130.516 58.0426 130.633 58.4298 130.867C58.8217 131.097 59.1228 131.424 59.3331 131.849C59.5481 132.275 59.6557 132.755 59.6557 133.29C59.6557 133.529 59.6318 133.783 59.584 134.05H55.3686C55.34 134.217 55.3256 134.37 55.3256 134.509C55.3256 134.863 55.3949 135.173 55.5335 135.441C55.6769 135.704 55.8752 135.907 56.1285 136.05C56.3818 136.189 56.6662 136.258 56.9816 136.258C57.2923 136.258 57.634 136.194 58.0068 136.065C58.3796 135.931 58.8026 135.73 59.2757 135.462L59.0105 136.86C58.2458 137.281 57.4954 137.491 56.7594 137.491C56.162 137.491 55.6339 137.36 55.1751 137.097C54.721 136.834 54.3698 136.457 54.1212 135.964C53.8775 135.472 53.7556 134.908 53.7556 134.272C53.7556 133.589 53.9038 132.958 54.2001 132.38C54.4964 131.797 54.9003 131.34 55.4116 131.011C55.9278 130.681 56.4918 130.516 57.1035 130.516ZM58.1287 133.068V132.953C58.1287 132.571 58.0331 132.267 57.8419 132.043C57.6555 131.818 57.3879 131.706 57.039 131.706C56.3508 131.706 55.8561 132.16 55.555 133.068H58.1287Z" fill="#33363C"/>
<path d="M50.1927 130.516C50.6897 130.516 51.1318 130.633 51.5189 130.867C51.9108 131.097 52.2119 131.424 52.4222 131.849C52.6373 132.275 52.7448 132.755 52.7448 133.29C52.7448 133.529 52.7209 133.783 52.6731 134.05H48.4578C48.4291 134.217 48.4148 134.37 48.4148 134.509C48.4148 134.863 48.4841 135.173 48.6227 135.441C48.7661 135.704 48.9644 135.907 49.2177 136.05C49.471 136.189 49.7554 136.258 50.0708 136.258C50.3815 136.258 50.7232 136.194 51.096 136.065C51.4688 135.931 51.8917 135.73 52.3649 135.462L52.0996 136.86C51.3349 137.281 50.5846 137.491 49.8486 137.491C49.2512 137.491 48.7231 137.36 48.2642 137.097C47.8102 136.834 47.4589 136.457 47.2104 135.964C46.9667 135.472 46.8448 134.908 46.8448 134.272C46.8448 133.589 46.9929 132.958 47.2893 132.38C47.5856 131.797 47.9894 131.34 48.5008 131.011C49.017 130.681 49.5809 130.516 50.1927 130.516ZM51.2178 133.068V132.953C51.2178 132.571 51.1223 132.267 50.9311 132.043C50.7447 131.818 50.4771 131.706 50.1282 131.706C49.4399 131.706 48.9453 132.16 48.6442 133.068H51.2178Z" fill="#33363C"/>
<path d="M41.1957 130.688H42.7943L42.4287 133.333C42.7107 133.324 42.9449 133.25 43.1313 133.111C43.3224 132.968 43.5088 132.738 43.6904 132.423L44.0847 131.72C44.2281 131.457 44.3882 131.247 44.5651 131.089C44.7419 130.927 44.9331 130.81 45.1386 130.738C45.3489 130.666 45.6285 130.631 45.9773 130.631C46.1398 130.631 46.3358 130.65 46.5652 130.688L46.3358 131.907C46.1064 131.907 45.9367 131.921 45.8268 131.95C45.7169 131.978 45.6213 132.031 45.54 132.107C45.4636 132.184 45.3608 132.332 45.2318 132.552L44.9378 133.068C44.8136 133.283 44.6367 133.467 44.4073 133.62C44.1827 133.773 43.9485 133.873 43.7048 133.921C43.9485 133.993 44.1732 134.119 44.3787 134.301C44.5842 134.478 44.7753 134.758 44.9522 135.14L45.963 137.312H44.2496L43.5901 135.699C43.4467 135.36 43.3177 135.104 43.203 134.932C43.0883 134.76 42.9592 134.633 42.8158 134.552C42.6772 134.466 42.4932 134.416 42.2638 134.401L42.142 135.269L41.8552 137.312H40.2709L40.6651 134.401C40.3688 134.401 40.1275 134.442 39.9411 134.523C39.7595 134.605 39.5802 134.746 39.4034 134.946C39.2314 135.142 38.9613 135.544 38.5933 136.151L37.9051 137.312H36.1559L37.4391 135.269C37.6876 134.872 37.9505 134.566 38.2277 134.351C38.5049 134.131 38.8538 133.974 39.2744 133.878C39.0115 133.816 38.8012 133.694 38.6435 133.513C38.4906 133.331 38.3759 133.08 38.2994 132.76C38.2325 132.521 38.1632 132.344 38.0915 132.229C38.0198 132.11 37.9266 132.026 37.8119 131.978C37.6972 131.931 37.5132 131.907 37.2599 131.907L37.4535 130.688C37.6829 130.65 37.8932 130.631 38.0843 130.631C38.3902 130.631 38.6316 130.669 38.8084 130.745C38.9852 130.822 39.1358 130.946 39.26 131.118C39.3843 131.29 39.4847 131.52 39.5611 131.806C39.695 132.299 39.8073 132.64 39.8981 132.831C39.9889 133.018 40.0964 133.149 40.2207 133.226C40.3449 133.297 40.5528 133.333 40.8444 133.333L41.1957 130.688Z" fill="#33363C"/>
<path d="M33.2883 130.516C33.7853 130.516 34.2274 130.633 34.6145 130.867C35.0064 131.097 35.3075 131.424 35.5178 131.849C35.7329 132.275 35.8404 132.755 35.8404 133.29C35.8404 133.529 35.8165 133.783 35.7687 134.05H31.5534C31.5247 134.217 31.5104 134.37 31.5104 134.509C31.5104 134.863 31.5797 135.173 31.7183 135.441C31.8616 135.704 32.06 135.907 32.3133 136.05C32.5666 136.189 32.851 136.258 33.1664 136.258C33.477 136.258 33.8188 136.194 34.1916 136.065C34.5643 135.931 34.9873 135.73 35.4605 135.462L35.1952 136.86C34.4305 137.281 33.6802 137.491 32.9442 137.491C32.3467 137.491 31.8186 137.36 31.3598 137.097C30.9058 136.834 30.5545 136.457 30.306 135.964C30.0622 135.472 29.9404 134.908 29.9404 134.272C29.9404 133.589 30.0885 132.958 30.3848 132.38C30.6812 131.797 31.085 131.34 31.5964 131.011C32.1126 130.681 32.6765 130.516 33.2883 130.516ZM34.3134 133.068V132.953C34.3134 132.571 34.2178 132.267 34.0267 132.043C33.8403 131.818 33.5726 131.706 33.2237 131.706C32.5355 131.706 32.0409 132.16 31.7398 133.068H34.3134Z" fill="#33363C"/>
<path d="M23.0367 137.312L23.9471 130.882C24.3247 130.757 24.7309 130.666 25.1659 130.609C25.6008 130.547 25.9927 130.516 26.3416 130.516C27.1636 130.516 27.7777 130.662 28.184 130.953C28.5902 131.245 28.7933 131.646 28.7933 132.158C28.7933 132.578 28.6763 132.932 28.4421 133.219C28.2127 133.505 27.8901 133.701 27.4743 133.806C27.8757 133.897 28.1912 134.081 28.4206 134.358C28.65 134.636 28.7647 134.975 28.7647 135.376C28.7647 135.768 28.6619 136.11 28.4564 136.402C28.2509 136.693 27.9594 136.918 27.5818 137.075C27.2042 137.233 26.7717 137.312 26.2842 137.312H23.0367ZM25.9186 133.362C26.3153 133.362 26.6212 133.288 26.8362 133.14C27.0561 132.992 27.166 132.762 27.166 132.451C27.166 131.945 26.853 131.692 26.2269 131.692C25.9114 131.692 25.6271 131.73 25.3738 131.806L25.1372 133.362H25.9186ZM25.0225 134.344L24.7716 136.151H26.0333C26.387 136.151 26.6594 136.065 26.8506 135.893C27.0417 135.716 27.1373 135.484 27.1373 135.197C27.1373 134.91 27.0441 134.698 26.8577 134.559C26.6761 134.416 26.4013 134.344 26.0333 134.344H25.0225Z" fill="#33363C"/>
<path d="M22.5205 128.853C22.3772 128.814 22.1549 128.779 21.8538 128.745C21.5575 128.712 21.2803 128.695 21.0222 128.695C20.2814 128.695 19.6147 128.798 19.0221 129.003C18.4342 129.204 17.9276 129.5 17.5023 129.892C17.0769 130.279 16.7448 130.752 16.5058 131.312C16.2668 131.871 16.1473 132.451 16.1473 133.054C16.1473 133.627 16.2597 134.131 16.4843 134.566C16.7089 135.001 17.0267 135.336 17.4378 135.57C17.8488 135.799 18.3291 135.914 18.8787 135.914C19.371 135.914 19.8967 135.811 20.4559 135.606C21.0198 135.396 21.591 135.099 22.1693 134.717L21.904 136.609C20.8095 137.164 19.739 137.441 18.6923 137.441C17.8559 137.441 17.108 137.259 16.4484 136.896C15.7889 136.533 15.2775 136.017 14.9143 135.348C14.5511 134.679 14.3694 133.924 14.3694 133.082C14.3694 131.935 14.649 130.91 15.2082 130.007C15.7722 129.104 16.5655 128.406 17.5883 127.914C18.6159 127.417 19.7796 127.168 21.0796 127.168C21.3377 127.168 21.6435 127.185 21.9972 127.218C22.3557 127.252 22.6328 127.287 22.8288 127.326L22.5205 128.853Z" fill="#33363C"/>
</svg>

</template>
