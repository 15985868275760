﻿<script setup lang="js">
import { onMounted, ref } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import heartImage from '@/assets/images/heart-2x.png';
import DogPaw from '@/components/images/DogPaw.vue';
import britPremiumLogo from '@/assets/images/brit-premium-logo.svg';
import britSuperPremiumLogo from '@/assets/images/brit-superpremium-logo.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'

AOS.init();

gsap.registerPlugin(ScrollTrigger);
const wrap = ref(null);
const elem1 = ref(null);
const elem2 = ref(null);

function timeline1() {
    
    const card = elem1.value.querySelector('[data-animate="brand-card"]');
    
    const content = elem1.value.querySelector('[data-animate="content"]');

    gsap.set(card, { opacity: 0, yPercent: 50 });
    gsap.set(content, { opacity: 0, x: 400 });

    const cardTo = { opacity: 1, yPercent: 0 };
    const contentTo = { opacity: 1, x: 0 };

    return [
        { card, cardTo },
        { content, contentTo },
    ];
}

function timeline2() {
    
    const card = elem2.value.querySelector('[data-animate="brand-card"]');
    
    const content = elem2.value.querySelector('[data-animate="content"]');

    gsap.set(card, { opacity: 0, yPercent: 50 });
    gsap.set(content, { opacity: 0, x: -400 });

    const cardTo = { opacity: 1, yPercent: 0 };
    const contentTo = { opacity: 1, x: 0 };

    return [
        { card, cardTo },
        { content, contentTo },
    ];
}

const animateElements = () => {
    if (window.matchMedia('(max-width: 1024px)').matches) return;

    const [cardFirst, contentFirst] = timeline1();
    const [cardSecond, contentSecond] = timeline2();

    gsap.timeline({
        stagger: 1,
        scrollTrigger: {
            trigger: wrap.value,
            start: '-=10%',
            end: '+=120%',
            scrub: true,
            pin: true,
            // markers: true,
        },
    })

    .to(cardFirst.card, cardFirst.cardTo)
    .to(contentFirst.content, contentFirst.contentTo)

    .to(cardSecond.card, cardSecond.cardTo)
    .to(contentSecond.content, contentSecond.contentTo);
};

onMounted(animateElements);

// interface Props {
//   product?: any | null;
// }

// const props = defineProps(['product']);

let aosProperty = false;

function addAosAttributes() {
    if (window.innerWidth < 1024) {
        aosProperty = true;
    } else {
        aosProperty = false;
    }
}

addAosAttributes();

window.addEventListener('resize', addAosAttributes);

</script>

<template>
    <section class="lg:mt-17 md:mt-6.5 mt-4 md:mb-28 lg:mb-11 mb-32.5 px-5 xl:h-screen" ref="wrap">
        <div
            class="max-w-[1503px] mx-auto grid grid-rows-2 lg:gap-y-1 gap-y-10 xl:h-[85%] relative"
        >
            <div
                class="absolute z-10 top-auto block md:w-1/5 w-[158px] md:left-11.5 left-7.5 md:bottom-9.5 -bottom-11.5"
                data-speed="1.075"
            >
                <DogPaw color="#C2E9E9" class="h-auto w-full -rotate-[17deg]" />
            </div>
            <div
                class="absolute z-10 block md:w-1/5 w-[158px] top-17.5 left-auto md:right-11.5 right-20"
                data-speed="0.9"
            >
                <DogPaw color="#C2E9E9" class="h-auto w-full -rotate-[17deg]" />
            </div>

            <div
                :data-aos="aosProperty ? 'fade-right' : ''"
                :data-aos-duration="aosProperty ? '1000' : ''"
                :data-aos-delay="aosProperty ? '100' : ''"
                 class="flex lg:gap-x-20 md:gap-x-15 gap-x-3 relative h-full z-20" ref="elem1">
                <figure
                    class="bg-white rounded-60 flex items-center justify-center xl:p-11 lg:p-20 p-11 xl:w-[503px] lg:w-[400px] md:w-65 w-42 h-full shrink-0 grow-0 shadow-[0_11px_16px_0_rgba(0,0,0,.1)] relative z-10"
                    data-animate="brand-card"
                >
                    <img
                        :src="britPremiumLogo"
                        alt="img"
                        width="356"
                        height="285"
                        class="relative z-10 max-h-full"
                    />
                    <img
                        data-speed="1.05"
                        :src="heartImage"
                        alt="love brit"
                        class="absolute z-20 top-auto xl:w-[180px] md:w-33 w-17 h-auto xl:-bottom-12.5 md:-bottom-6.5 bottom-[-1.675rem] left-auto xl:-right-20 md:-right-13.5 -right-[0.675rem] rotate-[60deg]"
                        data-animate="heart"
                    />
                </figure>
                <div
                    class="xl:pt-26.5 lg:pt-15 pt-8 lg:basis-[45%] grow-0 lg:shrink-0 relative z-20"
                    data-animate="content"
                >
                    <p
                        class="font-gill lg:text-20 leading-1.2 lg:mb-10 mb-5 md:line-clamp-none line-clamp-3 font-light"
                        v-html="
                            'Корма Brit Premium - это сбалансированная формула, богатая витаминами, минералами, пребиотиками, органическими микроэлементами и другими необходимыми питательными веществами'
                        "
                    />
                    <RouterLink
                        to="/brit-premium"
                        class="bg-blue cursor-pointer text-white border border-blue rounded-full block w-fit lg:py-4.5 md:py-3 py-1 md:px-9.5 px-4.5 lg:text-20 leading-1.2 transition-all duration-300 hover:bg-transparent hover:text-blue"
                    >
                        Подробнее
                    </RouterLink>
                </div>
            </div>
            <div
                :data-aos="aosProperty ? 'fade-left' : ''"
                :data-aos-duration="aosProperty ? '1000' : ''"
                :data-aos-delay="aosProperty ? '100' : ''"
                class="flex lg:gap-x-20 md:gap-x-15 gap-x-3 relative h-full ml-auto text-right justify-end z-20"
                ref="elem2"
            >
                <figure
                    class="bg-white rounded-60 flex items-center justify-center xl:p-11 lg:p-20 p-11 xl:w-[503px] lg:w-[400px] md:w-65 w-42 h-full shrink-0 grow-0 shadow-[0_11px_16px_0_rgba(0,0,0,.1)] relative z-10 order-2"
                    data-animate="brand-card"
                >
                    <img :src="britSuperPremiumLogo" alt="img" class="relative z-10 max-h-full" />
                    <img
                        data-speed="1.05"
                        :src="heartImage"
                        alt="love brit"
                        class="absolute z-20 top-auto xl:w-[180px] md:w-33 w-17 h-auto xl:-bottom-16 md:-bottom-8.5 -bottom-[1.675rem] xl:-left-20 md:-left-16 -left-3.5 -rotate-[26deg]"
                        data-animate="heart"
                    />
                </figure>
                <div
                    class="xl:pt-26.5 lg:pt-15 pt-8 lg:basis-[45%] grow-0 lg:shrink-0 relative z-20"
                    data-animate="content"
                >
                    <p
                        class="font-gill lg:text-20 leading-1.2 lg:mb-10 mb-5 md:line-clamp-none line-clamp-3 font-light"
                        v-html="
                            'Brit Care - функциональные корма суперпремиум класса созданы для заботы о здоровье: это профессионально подобранные ингредиенты, которые поддерживают иммунитет, улучшают общее состояние и помогают устранить риск серьезных заболеваний.'
                        "
                    />
                    <RouterLink
                        to="/brit-care"
                        class="bg-blue cursor-pointer text-white border border-blue rounded-full block w-fit lg:py-4.5 md:py-3 py-1 md:px-9.5 px-4.5 lg:text-20 leading-1.2 transition-all duration-300 hover:bg-transparent hover:text-blue ml-auto"
                    >
                        Подробнее
                    </RouterLink>
                </div>
            </div>
        </div>
    </section>
</template>
