﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="109" height="141" viewBox="0 0 109 141" fill="none">
<path d="M106.412 54.587C106.412 83.4195 83.0386 106.793 54.206 106.793C25.3734 106.793 2 83.4195 2 54.587C2 25.7544 25.3734 2.38098 54.206 2.38098C83.0386 2.38098 106.412 25.7544 106.412 54.587Z" fill="white"/>
<path d="M59.657 70.4792L43.6842 67.9029L50.7103 60.8767L67.1516 63.5233C66.6129 64.1556 66.0508 64.788 65.4653 65.3735C63.4746 67.3642 61.5306 69.0271 59.6336 70.4558M34.3862 77.1775L39.5621 72.0015L53.5911 74.2499C45.1597 78.5593 38.2506 78.0675 34.3862 77.1541M33.9646 54.6L36.213 68.629L31.037 73.8049C30.1236 69.9405 29.6318 63.0314 33.9412 54.6M42.841 42.7258C43.4265 42.1403 44.0589 41.5782 44.6912 41.0395L47.3378 57.4808L40.3116 64.5069L37.7353 48.5341C39.164 46.637 40.8268 44.6931 42.8176 42.7258M72.632 32.1865L62.5846 42.2339L61.1793 33.5449C65.5121 32.6081 69.5639 32.2802 72.6086 32.1865M56.5655 34.7628L58.4391 46.356L51.4129 53.3821L48.9538 38.0885C51.3895 36.6833 53.9892 35.6059 56.5655 34.7862M70.0557 59.1905L54.7621 56.7313L61.7883 49.7051L73.3815 51.5788C72.5618 54.155 71.4844 56.7547 70.0792 59.1905M74.5993 46.9883L65.9103 45.5831L75.9577 35.5357C75.8641 38.5803 75.5362 42.6555 74.5993 46.9649M80.4076 27.7366L78.4403 27.5492C78.1827 27.5258 72.1168 26.9871 64.3177 28.1348C57.0807 29.1887 50.7103 31.3902 45.5812 34.5754C45.2299 34.6925 44.9254 34.9033 44.6912 35.1609C42.7708 36.4256 41.0142 37.8309 39.4684 39.3766C18.5539 60.2678 27.5005 78.4656 27.8987 79.2151L28.25 79.8943L28.9292 80.2456C29.2805 80.4329 33.4494 82.4705 39.9134 82.4705C47.2909 82.4705 57.6428 79.8006 68.791 68.6758C70.3368 67.13 71.742 65.3735 73.0302 63.4296C73.2878 63.172 73.4752 62.8675 73.6157 62.5396C75.7704 59.0265 77.4801 54.9747 78.6745 50.4546C78.7214 50.3375 78.7448 50.2204 78.7682 50.1033C79.2835 48.0891 79.7284 46.0047 80.0329 43.8265C81.1805 36.0275 80.6419 29.9616 80.6184 29.7039L80.4311 27.7366H80.4076Z" fill="#54565A"/>
<path d="M54.3172 105.352C26.2827 105.352 3.49448 82.5406 3.49448 54.5296C3.49448 26.5185 26.2827 3.73029 54.3172 3.73029C82.3516 3.73029 105.14 26.5185 105.14 54.553C105.14 82.5874 82.3516 105.376 54.3172 105.376M54.3172 0.568512C24.5496 0.568512 0.332703 24.7854 0.332703 54.553C0.332703 84.3206 24.5496 108.514 54.3172 108.514C84.0848 108.514 108.302 84.2971 108.302 54.553C108.302 24.8088 84.0613 0.568512 54.3172 0.568512Z" fill="#54565A"/>
<path d="M101.024 133.448C101.024 132.96 100.911 132.564 100.687 132.258C100.462 131.947 100.159 131.792 99.7764 131.792C99.4323 131.792 99.124 131.919 98.8516 132.172C98.5792 132.42 98.3641 132.765 98.2064 133.204C98.0487 133.639 97.9698 134.084 97.9698 134.538C97.9698 134.848 98.0248 135.13 98.1347 135.384C98.2446 135.632 98.3928 135.826 98.5792 135.964C98.7704 136.098 98.9783 136.165 99.2029 136.165C99.5422 136.165 99.8505 136.046 100.128 135.807C100.41 135.568 100.629 135.24 100.787 134.824C100.945 134.404 101.024 133.945 101.024 133.448ZM102.651 133.527C102.651 134.258 102.503 134.93 102.207 135.541C101.91 136.148 101.497 136.626 100.966 136.975C100.441 137.319 99.8624 137.491 99.2316 137.491C98.6724 137.491 98.1729 137.362 97.7332 137.104C97.2936 136.846 96.9542 136.483 96.7153 136.014C96.4763 135.541 96.3568 135.011 96.3568 134.423C96.3568 133.711 96.5098 133.051 96.8156 132.444C97.1263 131.837 97.5492 131.364 98.0845 131.025C98.6198 130.686 99.1981 130.516 99.8194 130.516C100.369 130.516 100.859 130.64 101.289 130.889C101.719 131.137 102.054 131.493 102.293 131.957C102.532 132.416 102.651 132.939 102.651 133.527Z" fill="#33363C"/>
<path d="M95.3747 130.688L94.4499 137.312H92.8655L93.267 134.523H90.6503L90.256 137.312H88.686L89.6108 130.688H91.1808L90.8296 133.247H93.439L93.8047 130.688H95.3747Z" fill="#33363C"/>
<path d="M87.704 130.688L86.7792 137.312H85.1949L85.5963 134.523H82.9797L82.5854 137.312H81.0154L81.9402 130.688H83.5102L83.1589 133.247H85.7684L86.134 130.688H87.704Z" fill="#33363C"/>
<path d="M77.4524 130.516C77.9494 130.516 78.3915 130.633 78.7786 130.867C79.1706 131.097 79.4716 131.424 79.6819 131.849C79.897 132.275 80.0045 132.755 80.0045 133.29C80.0045 133.529 79.9806 133.783 79.9328 134.05H75.7175C75.6888 134.217 75.6745 134.37 75.6745 134.509C75.6745 134.863 75.7438 135.173 75.8824 135.441C76.0258 135.704 76.2241 135.907 76.4774 136.05C76.7307 136.189 77.0151 136.258 77.3305 136.258C77.6412 136.258 77.9829 136.194 78.3557 136.065C78.7285 135.931 79.1514 135.73 79.6246 135.462L79.3593 136.86C78.5946 137.281 77.8443 137.491 77.1083 137.491C76.5109 137.491 75.9828 137.36 75.5239 137.097C75.0699 136.834 74.7186 136.457 74.4701 135.964C74.2264 135.472 74.1045 134.908 74.1045 134.272C74.1045 133.589 74.2526 132.958 74.549 132.38C74.8453 131.797 75.2491 131.34 75.7605 131.011C76.2767 130.681 76.8406 130.516 77.4524 130.516ZM78.4775 133.068V132.953C78.4775 132.571 78.382 132.267 78.1908 132.043C78.0044 131.818 77.7368 131.706 77.3879 131.706C76.6997 131.706 76.205 132.16 75.9039 133.068H78.4775Z" fill="#33363C"/>
<path d="M73.8392 130.688L73.6313 131.95H70.8569L70.1257 137.312H68.5414L69.4805 130.688H73.8392Z" fill="#33363C"/>
<path d="M65.9534 133.541C65.9534 132.977 65.8076 132.528 65.516 132.193C65.2245 131.859 64.8326 131.692 64.3403 131.692C64.1635 131.692 64.0082 131.706 63.8744 131.735C63.7405 131.759 63.5924 131.811 63.4299 131.892L62.842 136.122C62.8803 136.136 62.9615 136.151 63.0858 136.165C63.21 136.175 63.3295 136.179 63.4442 136.179C63.9365 136.179 64.3666 136.072 64.7346 135.857C65.1074 135.642 65.4037 135.324 65.6236 134.903C65.8434 134.478 65.9534 134.024 65.9534 133.541ZM67.5664 133.513C67.5664 133.995 67.478 134.473 67.3011 134.946C67.1243 135.419 66.8638 135.845 66.5197 136.222C66.1756 136.6 65.7502 136.906 65.2436 137.14C64.7418 137.374 64.1611 137.491 63.5016 137.491C63.4633 137.491 63.3128 137.484 63.0499 137.47C62.7919 137.455 62.658 137.441 62.6485 137.427L62.1968 140.689H60.6053L61.738 132.702C61.5229 132.86 61.3437 133.018 61.2003 133.176C61.0617 133.329 60.947 133.467 60.8562 133.591C60.7654 133.716 60.6914 133.816 60.634 133.892C60.5766 133.969 60.5193 134.007 60.4619 134.007C60.4046 134.007 60.3759 133.969 60.3759 133.892C60.3759 133.744 60.4428 133.529 60.5766 133.247C60.7152 132.965 60.8969 132.679 61.1215 132.387C61.3509 132.095 61.6066 131.837 61.8886 131.613L62.2255 129.175H63.8098L63.5876 130.688C63.9126 130.573 64.3045 130.516 64.7633 130.516C65.2938 130.516 65.7741 130.643 66.2043 130.896C66.6344 131.149 66.969 131.505 67.2079 131.964C67.4469 132.423 67.5664 132.939 67.5664 133.513Z" fill="#33363C"/>
<path d="M57.0782 130.516C57.5753 130.516 58.0174 130.633 58.4045 130.867C58.7964 131.097 59.0975 131.424 59.3078 131.849C59.5229 132.275 59.6304 132.755 59.6304 133.29C59.6304 133.529 59.6065 133.783 59.5587 134.05H55.3434C55.3147 134.217 55.3003 134.37 55.3003 134.509C55.3003 134.863 55.3696 135.173 55.5082 135.441C55.6516 135.704 55.85 135.907 56.1033 136.05C56.3566 136.189 56.6409 136.258 56.9564 136.258C57.267 136.258 57.6087 136.194 57.9815 136.065C58.3543 135.931 58.7773 135.73 59.2504 135.462L58.9852 136.86C58.2205 137.281 57.4701 137.491 56.7341 137.491C56.1367 137.491 55.6086 137.36 55.1498 137.097C54.6958 136.834 54.3445 136.457 54.096 135.964C53.8522 135.472 53.7303 134.908 53.7303 134.272C53.7303 133.589 53.8785 132.958 54.1748 132.38C54.4711 131.797 54.875 131.34 55.3864 131.011C55.9025 130.681 56.4665 130.516 57.0782 130.516ZM58.1034 133.068V132.953C58.1034 132.571 58.0078 132.267 57.8166 132.043C57.6303 131.818 57.3626 131.706 57.0137 131.706C56.3255 131.706 55.8308 132.16 55.5298 133.068H58.1034Z" fill="#33363C"/>
<path d="M51.085 137.312C50.9081 136.614 50.7361 135.924 50.5688 135.24C50.4063 134.557 50.2343 133.866 50.0526 133.168C49.2067 134.621 48.4205 136.003 47.6941 137.312H45.9735C46.6426 136.17 47.3117 135.037 47.9808 133.914C48.6547 132.791 49.331 131.658 50.0096 130.516H50.9774L52.8127 137.312H51.085Z" fill="#33363C"/>
<path d="M44.0881 137.312C43.9112 136.614 43.7392 135.924 43.5719 135.24C43.4094 134.557 43.2374 133.866 43.0558 133.168C42.2098 134.621 41.4236 136.003 40.6972 137.312H38.9766C39.6457 136.17 40.3148 135.037 40.9839 133.914C41.6578 132.791 42.3341 131.658 43.0127 130.516H43.9805L45.8158 137.312H44.0881Z" fill="#33363C"/>
<path d="M37.7866 137.312H36.2023L36.3026 136.624L36.2955 136.617L36.274 136.609C35.6813 137.164 35.0744 137.441 34.453 137.441C34.0372 137.441 33.6573 137.322 33.3132 137.083C32.9691 136.844 32.699 136.516 32.5031 136.1C32.3071 135.68 32.2092 135.219 32.2092 134.717C32.2092 133.876 32.4027 133.13 32.7899 132.48C33.177 131.83 33.7194 131.34 34.4172 131.011C35.115 130.681 35.9251 130.516 36.8475 130.516C37.4736 130.516 38.0949 130.588 38.7114 130.731L37.7866 137.312ZM36.9765 131.864C36.8331 131.849 36.6945 131.842 36.5607 131.842C36.164 131.842 35.8032 131.902 35.4782 132.021C35.1532 132.141 34.8712 132.322 34.6323 132.566C34.3933 132.805 34.1997 133.113 34.0516 133.491C33.9082 133.864 33.8365 134.246 33.8365 134.638C33.8365 135.111 33.9393 135.491 34.1448 135.778C34.3551 136.065 34.6347 136.208 34.9835 136.208C35.2082 136.208 35.4543 136.134 35.722 135.986C35.9896 135.838 36.2429 135.639 36.4819 135.391L36.9765 131.864Z" fill="#33363C"/>
<path d="M29.6068 133.448C29.6068 132.96 29.4945 132.564 29.2699 132.258C29.0452 131.947 28.7418 131.792 28.3594 131.792C28.0153 131.792 27.707 131.919 27.4346 132.172C27.1622 132.42 26.9471 132.765 26.7894 133.204C26.6317 133.639 26.5528 134.084 26.5528 134.538C26.5528 134.848 26.6078 135.13 26.7177 135.384C26.8276 135.632 26.9758 135.826 27.1622 135.964C27.3534 136.098 27.5613 136.165 27.7859 136.165C28.1252 136.165 28.4335 136.046 28.7107 135.807C28.9927 135.568 29.2125 135.24 29.3702 134.824C29.5279 134.404 29.6068 133.945 29.6068 133.448ZM31.2342 133.527C31.2342 134.258 31.086 134.93 30.7897 135.541C30.4934 136.148 30.08 136.626 29.5495 136.975C29.0237 137.319 28.4454 137.491 27.8146 137.491C27.2554 137.491 26.756 137.362 26.3163 137.104C25.8766 136.846 25.5372 136.483 25.2983 136.014C25.0593 135.541 24.9398 135.011 24.9398 134.423C24.9398 133.711 25.0928 133.051 25.3986 132.444C25.7093 131.837 26.1323 131.364 26.6675 131.025C27.2028 130.686 27.7811 130.516 28.4024 130.516C28.952 130.516 29.4419 130.64 29.8721 130.889C30.3022 131.137 30.6367 131.493 30.8757 131.957C31.1147 132.416 31.2342 132.939 31.2342 133.527Z" fill="#33363C"/>
<path d="M23.1046 137.312H21.5346L22.0436 133.627C22.0818 133.355 22.1057 133.159 22.1153 133.039C22.1296 132.92 22.1368 132.788 22.1368 132.645C22.1368 132.401 22.0747 132.201 21.9504 132.043C21.8309 131.885 21.6684 131.806 21.4629 131.806C21.2526 131.806 20.9874 131.907 20.6672 132.107C20.3469 132.303 19.9933 132.624 19.6061 133.068L19.0111 137.312H17.434L18.3588 130.688H19.9359L19.7854 131.749C20.1725 131.362 20.5501 131.07 20.9181 130.874C21.2861 130.674 21.6612 130.573 22.0436 130.573C22.4068 130.573 22.7151 130.654 22.9684 130.817C23.2265 130.979 23.42 131.202 23.5491 131.484C23.6829 131.766 23.7498 132.088 23.7498 132.451C23.7498 132.538 23.7474 132.619 23.7426 132.695C23.7426 132.772 23.7379 132.853 23.7283 132.939C23.7235 133.025 23.714 133.123 23.6996 133.233C23.6853 133.343 23.6686 133.479 23.6494 133.642L23.1046 137.312Z" fill="#33363C"/>
<path d="M16.545 130.688L15.6059 137.312H14.0359L14.6094 133.419L10.7883 137.312H9.93524L10.86 130.688H12.4515L11.835 134.695L15.5915 130.688H16.545Z" fill="#33363C"/>
<path d="M11.0894 127.297L10.8887 128.738H6.80241L5.58369 137.312H3.89899L5.33278 127.297H11.0894Z" fill="#33363C"/>
</svg>

</template>
