﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg width="87" height="87" viewBox="0 0 87 87" fill="none">
        <mask
            id="mask0_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="85"
            height="85"
        >
            <path d="M4.53939 24.9966H1.64648V27.8895H4.53939V24.9966Z" fill="white" />
            <path d="M4.53939 27.8896H1.64648V30.7826H4.53939V27.8896Z" fill="white" />
            <path d="M4.53939 30.7827H1.64648V33.6756H4.53939V30.7827Z" fill="white" />
            <path d="M4.53939 33.6753H1.64648V36.5682H4.53939V33.6753Z" fill="white" />
            <path d="M4.53939 39.4614H1.64648V42.3543H4.53939V39.4614Z" fill="white" />
            <path d="M4.53939 42.3545H1.64648V45.2474H4.53939V42.3545Z" fill="white" />
            <path d="M4.53939 51.0332H1.64648V53.9261H4.53939V51.0332Z" fill="white" />
            <path d="M4.53939 53.9253H1.64648V56.8182H4.53939V53.9253Z" fill="white" />
            <path d="M4.53939 59.7119H1.64648V62.6048H4.53939V59.7119Z" fill="white" />
            <path d="M7.43246 27.8896H4.53955V30.7826H7.43246V27.8896Z" fill="white" />
            <path d="M7.43246 42.3545H4.53955V45.2474H7.43246V42.3545Z" fill="white" />
            <path d="M7.43246 48.1401H4.53955V51.033H7.43246V48.1401Z" fill="white" />
            <path d="M7.43246 51.0332H4.53955V53.9261H7.43246V51.0332Z" fill="white" />
            <path d="M7.43246 53.9253H4.53955V56.8182H7.43246V53.9253Z" fill="white" />
            <path d="M10.325 24.9966H7.43213V27.8895H10.325V24.9966Z" fill="white" />
            <path d="M10.325 30.7827H7.43213V33.6756H10.325V30.7827Z" fill="white" />
            <path d="M10.325 36.5684H7.43213V39.4613H10.325V36.5684Z" fill="white" />
            <path d="M10.325 42.3545H7.43213V45.2474H10.325V42.3545Z" fill="white" />
            <path d="M10.325 48.1401H7.43213V51.033H10.325V48.1401Z" fill="white" />
            <path d="M10.325 51.0332H7.43213V53.9261H10.325V51.0332Z" fill="white" />
            <path d="M10.325 59.7119H7.43213V62.6048H10.325V59.7119Z" fill="white" />
            <path d="M13.2181 24.9966H10.3252V27.8895H13.2181V24.9966Z" fill="white" />
            <path d="M13.2181 27.8896H10.3252V30.7826H13.2181V27.8896Z" fill="white" />
            <path d="M13.2181 36.5684H10.3252V39.4613H13.2181V36.5684Z" fill="white" />
            <path d="M13.2181 45.2471H10.3252V48.14H13.2181V45.2471Z" fill="white" />
            <path d="M13.2181 48.1401H10.3252V51.033H13.2181V48.1401Z" fill="white" />
            <path d="M13.2181 53.9253H10.3252V56.8182H13.2181V53.9253Z" fill="white" />
            <path d="M13.2181 56.8188H10.3252V59.7118H13.2181V56.8188Z" fill="white" />
            <path d="M16.1112 30.7827H13.2183V33.6756H16.1112V30.7827Z" fill="white" />
            <path d="M16.1112 33.6753H13.2183V36.5682H16.1112V33.6753Z" fill="white" />
            <path d="M16.1112 36.5684H13.2183V39.4613H16.1112V36.5684Z" fill="white" />
            <path d="M16.1112 45.2471H13.2183V48.14H16.1112V45.2471Z" fill="white" />
            <path d="M16.1112 48.1401H13.2183V51.033H16.1112V48.1401Z" fill="white" />
            <path d="M16.1112 51.0332H13.2183V53.9261H16.1112V51.0332Z" fill="white" />
            <path d="M16.1112 53.9253H13.2183V56.8182H16.1112V53.9253Z" fill="white" />
            <path d="M19.0037 24.9966H16.1108V27.8895H19.0037V24.9966Z" fill="white" />
            <path d="M19.0037 27.8896H16.1108V30.7826H19.0037V27.8896Z" fill="white" />
            <path d="M19.0037 33.6753H16.1108V36.5682H19.0037V33.6753Z" fill="white" />
            <path d="M19.0037 39.4614H16.1108V42.3543H19.0037V39.4614Z" fill="white" />
            <path d="M19.0037 45.2471H16.1108V48.14H19.0037V45.2471Z" fill="white" />
            <path d="M19.0037 48.1401H16.1108V51.033H19.0037V48.1401Z" fill="white" />
            <path d="M19.0037 51.0332H16.1108V53.9261H19.0037V51.0332Z" fill="white" />
            <path d="M19.0037 53.9253H16.1108V56.8182H19.0037V53.9253Z" fill="white" />
            <path d="M19.0037 56.8188H16.1108V59.7118H19.0037V56.8188Z" fill="white" />
            <path d="M19.0037 59.7119H16.1108V62.6048H19.0037V59.7119Z" fill="white" />
            <path d="M21.8968 24.9966H19.0039V27.8895H21.8968V24.9966Z" fill="white" />
            <path d="M21.8968 30.7827H19.0039V33.6756H21.8968V30.7827Z" fill="white" />
            <path d="M21.8968 36.5684H19.0039V39.4613H21.8968V36.5684Z" fill="white" />
            <path d="M21.8968 42.3545H19.0039V45.2474H21.8968V42.3545Z" fill="white" />
            <path d="M21.8968 48.1401H19.0039V51.033H21.8968V48.1401Z" fill="white" />
            <path d="M21.8968 53.9253H19.0039V56.8182H21.8968V53.9253Z" fill="white" />
            <path d="M21.8968 59.7119H19.0039V62.6048H21.8968V59.7119Z" fill="white" />
            <path d="M24.7899 24.9966H21.897V27.8895H24.7899V24.9966Z" fill="white" />
            <path d="M24.7899 27.8896H21.897V30.7826H24.7899V27.8896Z" fill="white" />
            <path d="M24.7899 33.6753H21.897V36.5682H24.7899V33.6753Z" fill="white" />
            <path d="M24.7899 42.3545H21.897V45.2474H24.7899V42.3545Z" fill="white" />
            <path d="M24.7899 45.2471H21.897V48.14H24.7899V45.2471Z" fill="white" />
            <path d="M24.7899 56.8188H21.897V59.7118H24.7899V56.8188Z" fill="white" />
            <path d="M24.7899 59.7119H21.897V62.6048H24.7899V59.7119Z" fill="white" />
            <path d="M27.6825 4.74658H24.7896V7.63949H27.6825V4.74658Z" fill="white" />
            <path d="M27.6825 10.5322H24.7896V13.4251H27.6825V10.5322Z" fill="white" />
            <path d="M27.6825 16.3179H24.7896V19.2108H27.6825V16.3179Z" fill="white" />
            <path d="M27.6825 19.2109H24.7896V22.1038H27.6825V19.2109Z" fill="white" />
            <path d="M27.6825 22.104H24.7896V24.9969H27.6825V22.104Z" fill="white" />
            <path d="M27.6825 24.9966H24.7896V27.8895H27.6825V24.9966Z" fill="white" />
            <path d="M27.6825 30.7827H24.7896V33.6756H27.6825V30.7827Z" fill="white" />
            <path d="M27.6825 33.6753H24.7896V36.5682H27.6825V33.6753Z" fill="white" />
            <path d="M27.6825 36.5684H24.7896V39.4613H27.6825V36.5684Z" fill="white" />
            <path d="M27.6825 42.3545H24.7896V45.2474H27.6825V42.3545Z" fill="white" />
            <path d="M27.6825 51.0332H24.7896V53.9261H27.6825V51.0332Z" fill="white" />
            <path d="M27.6825 56.8188H24.7896V59.7118H27.6825V56.8188Z" fill="white" />
            <path d="M27.6825 59.7119H24.7896V62.6048H27.6825V59.7119Z" fill="white" />
            <path d="M27.6825 62.6045H24.7896V65.4974H27.6825V62.6045Z" fill="white" />
            <path d="M27.6825 65.4971H24.7896V68.39H27.6825V65.4971Z" fill="white" />
            <path d="M27.6825 68.3901H24.7896V71.283H27.6825V68.3901Z" fill="white" />
            <path d="M27.6825 74.1763H24.7896V77.0692H27.6825V74.1763Z" fill="white" />
            <path d="M27.6825 77.0693H24.7896V79.9622H27.6825V77.0693Z" fill="white" />
            <path d="M27.6825 82.8545H24.7896V85.7474H27.6825V82.8545Z" fill="white" />
            <path d="M30.5755 1.85352H27.6826V4.74642H30.5755V1.85352Z" fill="white" />
            <path d="M30.5755 10.5322H27.6826V13.4251H30.5755V10.5322Z" fill="white" />
            <path d="M30.5755 13.4253H27.6826V16.3182H30.5755V13.4253Z" fill="white" />
            <path d="M30.5755 22.104H27.6826V24.9969H30.5755V22.104Z" fill="white" />
            <path d="M30.5755 24.9966H27.6826V27.8895H30.5755V24.9966Z" fill="white" />
            <path d="M30.5755 33.6753H27.6826V36.5682H30.5755V33.6753Z" fill="white" />
            <path d="M30.5755 42.3545H27.6826V45.2474H30.5755V42.3545Z" fill="white" />
            <path d="M30.5755 45.2471H27.6826V48.14H30.5755V45.2471Z" fill="white" />
            <path d="M30.5755 51.0332H27.6826V53.9261H30.5755V51.0332Z" fill="white" />
            <path d="M30.5755 59.7119H27.6826V62.6048H30.5755V59.7119Z" fill="white" />
            <path d="M30.5755 62.6045H27.6826V65.4974H30.5755V62.6045Z" fill="white" />
            <path d="M30.5755 65.4971H27.6826V68.39H30.5755V65.4971Z" fill="white" />
            <path d="M30.5755 74.1763H27.6826V77.0692H30.5755V74.1763Z" fill="white" />
            <path d="M30.5755 82.8545H27.6826V85.7474H30.5755V82.8545Z" fill="white" />
            <path d="M33.4686 1.85352H30.5757V4.74642H33.4686V1.85352Z" fill="white" />
            <path d="M33.4686 4.74658H30.5757V7.63949H33.4686V4.74658Z" fill="white" />
            <path d="M33.4686 19.2109H30.5757V22.1038H33.4686V19.2109Z" fill="white" />
            <path d="M33.4686 24.9966H30.5757V27.8895H33.4686V24.9966Z" fill="white" />
            <path d="M33.4686 27.8896H30.5757V30.7826H33.4686V27.8896Z" fill="white" />
            <path d="M33.4686 33.6753H30.5757V36.5682H33.4686V33.6753Z" fill="white" />
            <path d="M33.4686 45.2471H30.5757V48.14H33.4686V45.2471Z" fill="white" />
            <path d="M33.4686 48.1401H30.5757V51.033H33.4686V48.1401Z" fill="white" />
            <path d="M33.4686 56.8188H30.5757V59.7118H33.4686V56.8188Z" fill="white" />
            <path d="M33.4686 65.4971H30.5757V68.39H33.4686V65.4971Z" fill="white" />
            <path d="M33.4686 68.3901H30.5757V71.283H33.4686V68.3901Z" fill="white" />
            <path d="M33.4686 71.2837H30.5757V74.1766H33.4686V71.2837Z" fill="white" />
            <path d="M33.4686 74.1763H30.5757V77.0692H33.4686V74.1763Z" fill="white" />
            <path d="M33.4686 79.9619H30.5757V82.8548H33.4686V79.9619Z" fill="white" />
            <path d="M36.3612 4.74658H33.4683V7.63949H36.3612V4.74658Z" fill="white" />
            <path d="M36.3612 10.5322H33.4683V13.4251H36.3612V10.5322Z" fill="white" />
            <path d="M36.3612 30.7827H33.4683V33.6756H36.3612V30.7827Z" fill="white" />
            <path d="M36.3612 33.6753H33.4683V36.5682H36.3612V33.6753Z" fill="white" />
            <path d="M36.3612 36.5684H33.4683V39.4613H36.3612V36.5684Z" fill="white" />
            <path d="M36.3612 39.4614H33.4683V42.3543H36.3612V39.4614Z" fill="white" />
            <path d="M36.3612 53.9253H33.4683V56.8182H36.3612V53.9253Z" fill="white" />
            <path d="M36.3612 62.6045H33.4683V65.4974H36.3612V62.6045Z" fill="white" />
            <path d="M36.3612 65.4971H33.4683V68.39H36.3612V65.4971Z" fill="white" />
            <path d="M36.3612 71.2837H33.4683V74.1766H36.3612V71.2837Z" fill="white" />
            <path d="M36.3612 77.0693H33.4683V79.9622H36.3612V77.0693Z" fill="white" />
            <path d="M36.3612 79.9619H33.4683V82.8548H36.3612V79.9619Z" fill="white" />
            <path d="M39.2542 10.5322H36.3613V13.4251H39.2542V10.5322Z" fill="white" />
            <path d="M39.2542 16.3179H36.3613V19.2108H39.2542V16.3179Z" fill="white" />
            <path d="M39.2542 19.2109H36.3613V22.1038H39.2542V19.2109Z" fill="white" />
            <path d="M39.2542 22.104H36.3613V24.9969H39.2542V22.104Z" fill="white" />
            <path d="M39.2542 24.9966H36.3613V27.8895H39.2542V24.9966Z" fill="white" />
            <path d="M39.2542 27.8896H36.3613V30.7826H39.2542V27.8896Z" fill="white" />
            <path d="M39.2542 30.7827H36.3613V33.6756H39.2542V30.7827Z" fill="white" />
            <path d="M39.2542 33.6753H36.3613V36.5682H39.2542V33.6753Z" fill="white" />
            <path d="M39.2542 36.5684H36.3613V39.4613H39.2542V36.5684Z" fill="white" />
            <path d="M39.2542 45.2471H36.3613V48.14H39.2542V45.2471Z" fill="white" />
            <path d="M39.2542 48.1401H36.3613V51.033H39.2542V48.1401Z" fill="white" />
            <path d="M39.2542 51.0332H36.3613V53.9261H39.2542V51.0332Z" fill="white" />
            <path d="M39.2542 53.9253H36.3613V56.8182H39.2542V53.9253Z" fill="white" />
            <path d="M39.2542 56.8188H36.3613V59.7118H39.2542V56.8188Z" fill="white" />
            <path d="M39.2542 59.7119H36.3613V62.6048H39.2542V59.7119Z" fill="white" />
            <path d="M39.2542 65.4971H36.3613V68.39H39.2542V65.4971Z" fill="white" />
            <path d="M39.2542 68.3901H36.3613V71.283H39.2542V68.3901Z" fill="white" />
            <path d="M39.2542 74.1763H36.3613V77.0692H39.2542V74.1763Z" fill="white" />
            <path d="M42.1473 1.85352H39.2544V4.74642H42.1473V1.85352Z" fill="white" />
            <path d="M42.1473 4.74658H39.2544V7.63949H42.1473V4.74658Z" fill="white" />
            <path d="M42.1473 7.63916H39.2544V10.5321H42.1473V7.63916Z" fill="white" />
            <path d="M42.1473 10.5322H39.2544V13.4251H42.1473V10.5322Z" fill="white" />
            <path d="M42.1473 24.9966H39.2544V27.8895H42.1473V24.9966Z" fill="white" />
            <path d="M42.1473 27.8896H39.2544V30.7826H42.1473V27.8896Z" fill="white" />
            <path d="M42.1473 30.7827H39.2544V33.6756H42.1473V30.7827Z" fill="white" />
            <path d="M42.1473 33.6753H39.2544V36.5682H42.1473V33.6753Z" fill="white" />
            <path d="M42.1473 39.4614H39.2544V42.3543H42.1473V39.4614Z" fill="white" />
            <path d="M42.1473 45.2471H39.2544V48.14H42.1473V45.2471Z" fill="white" />
            <path d="M42.1473 51.0332H39.2544V53.9261H42.1473V51.0332Z" fill="white" />
            <path d="M42.1473 56.8188H39.2544V59.7118H42.1473V56.8188Z" fill="white" />
            <path d="M42.1473 62.6045H39.2544V65.4974H42.1473V62.6045Z" fill="white" />
            <path d="M42.1473 68.3901H39.2544V71.283H42.1473V68.3901Z" fill="white" />
            <path d="M42.1473 74.1763H39.2544V77.0692H42.1473V74.1763Z" fill="white" />
            <path d="M42.1473 82.8545H39.2544V85.7474H42.1473V82.8545Z" fill="white" />
            <path d="M45.0404 1.85352H42.1475V4.74642H45.0404V1.85352Z" fill="white" />
            <path d="M45.0404 4.74658H42.1475V7.63949H45.0404V4.74658Z" fill="white" />
            <path d="M45.0404 10.5322H42.1475V13.4251H45.0404V10.5322Z" fill="white" />
            <path d="M45.0404 13.4253H42.1475V16.3182H45.0404V13.4253Z" fill="white" />
            <path d="M45.0404 19.2109H42.1475V22.1038H45.0404V19.2109Z" fill="white" />
            <path d="M45.0404 22.104H42.1475V24.9969H45.0404V22.104Z" fill="white" />
            <path d="M45.0404 36.5684H42.1475V39.4613H45.0404V36.5684Z" fill="white" />
            <path d="M45.0404 39.4614H42.1475V42.3543H45.0404V39.4614Z" fill="white" />
            <path d="M45.0404 42.3545H42.1475V45.2474H45.0404V42.3545Z" fill="white" />
            <path d="M45.0404 51.0332H42.1475V53.9261H45.0404V51.0332Z" fill="white" />
            <path d="M45.0404 53.9253H42.1475V56.8182H45.0404V53.9253Z" fill="white" />
            <path d="M45.0404 65.4971H42.1475V68.39H45.0404V65.4971Z" fill="white" />
            <path d="M45.0404 79.9619H42.1475V82.8548H45.0404V79.9619Z" fill="white" />
            <path d="M45.0404 82.8545H42.1475V85.7474H45.0404V82.8545Z" fill="white" />
            <path d="M47.9329 1.85352H45.04V4.74642H47.9329V1.85352Z" fill="white" />
            <path d="M47.9329 7.63916H45.04V10.5321H47.9329V7.63916Z" fill="white" />
            <path d="M47.9329 10.5322H45.04V13.4251H47.9329V10.5322Z" fill="white" />
            <path d="M47.9329 22.104H45.04V24.9969H47.9329V22.104Z" fill="white" />
            <path d="M47.9329 27.8896H45.04V30.7826H47.9329V27.8896Z" fill="white" />
            <path d="M47.9329 33.6753H45.04V36.5682H47.9329V33.6753Z" fill="white" />
            <path d="M47.9329 39.4614H45.04V42.3543H47.9329V39.4614Z" fill="white" />
            <path d="M47.9329 42.3545H45.04V45.2474H47.9329V42.3545Z" fill="white" />
            <path d="M47.9329 45.2471H45.04V48.14H47.9329V45.2471Z" fill="white" />
            <path d="M47.9329 51.0332H45.04V53.9261H47.9329V51.0332Z" fill="white" />
            <path d="M47.9329 53.9253H45.04V56.8182H47.9329V53.9253Z" fill="white" />
            <path d="M47.9329 56.8188H45.04V59.7118H47.9329V56.8188Z" fill="white" />
            <path d="M47.9329 59.7119H45.04V62.6048H47.9329V59.7119Z" fill="white" />
            <path d="M47.9329 68.3901H45.04V71.283H47.9329V68.3901Z" fill="white" />
            <path d="M47.9329 79.9619H45.04V82.8548H47.9329V79.9619Z" fill="white" />
            <path d="M50.826 7.63916H47.9331V10.5321H50.826V7.63916Z" fill="white" />
            <path d="M50.826 19.2109H47.9331V22.1038H50.826V19.2109Z" fill="white" />
            <path d="M50.826 30.7827H47.9331V33.6756H50.826V30.7827Z" fill="white" />
            <path d="M50.826 39.4614H47.9331V42.3543H50.826V39.4614Z" fill="white" />
            <path d="M50.826 42.3545H47.9331V45.2474H50.826V42.3545Z" fill="white" />
            <path d="M50.826 45.2471H47.9331V48.14H50.826V45.2471Z" fill="white" />
            <path d="M50.826 53.9253H47.9331V56.8182H50.826V53.9253Z" fill="white" />
            <path d="M50.826 56.8188H47.9331V59.7118H50.826V56.8188Z" fill="white" />
            <path d="M50.826 59.7119H47.9331V62.6048H50.826V59.7119Z" fill="white" />
            <path d="M50.826 62.6045H47.9331V65.4974H50.826V62.6045Z" fill="white" />
            <path d="M50.826 79.9619H47.9331V82.8548H50.826V79.9619Z" fill="white" />
            <path d="M53.7191 4.74658H50.8262V7.63949H53.7191V4.74658Z" fill="white" />
            <path d="M53.7191 7.63916H50.8262V10.5321H53.7191V7.63916Z" fill="white" />
            <path d="M53.7191 16.3179H50.8262V19.2108H53.7191V16.3179Z" fill="white" />
            <path d="M53.7191 24.9966H50.8262V27.8895H53.7191V24.9966Z" fill="white" />
            <path d="M53.7191 27.8896H50.8262V30.7826H53.7191V27.8896Z" fill="white" />
            <path d="M53.7191 33.6753H50.8262V36.5682H53.7191V33.6753Z" fill="white" />
            <path d="M53.7191 39.4614H50.8262V42.3543H53.7191V39.4614Z" fill="white" />
            <path d="M53.7191 45.2471H50.8262V48.14H53.7191V45.2471Z" fill="white" />
            <path d="M53.7191 48.1401H50.8262V51.033H53.7191V48.1401Z" fill="white" />
            <path d="M53.7191 51.0332H50.8262V53.9261H53.7191V51.0332Z" fill="white" />
            <path d="M53.7191 53.9253H50.8262V56.8182H53.7191V53.9253Z" fill="white" />
            <path d="M53.7191 68.3901H50.8262V71.283H53.7191V68.3901Z" fill="white" />
            <path d="M53.7191 77.0693H50.8262V79.9622H53.7191V77.0693Z" fill="white" />
            <path d="M53.7191 79.9619H50.8262V82.8548H53.7191V79.9619Z" fill="white" />
            <path d="M53.7191 82.8545H50.8262V85.7474H53.7191V82.8545Z" fill="white" />
            <path d="M56.6117 1.85352H53.7188V4.74642H56.6117V1.85352Z" fill="white" />
            <path d="M56.6117 7.63916H53.7188V10.5321H56.6117V7.63916Z" fill="white" />
            <path d="M56.6117 16.3179H53.7188V19.2108H56.6117V16.3179Z" fill="white" />
            <path d="M56.6117 19.2109H53.7188V22.1038H56.6117V19.2109Z" fill="white" />
            <path d="M56.6117 24.9966H53.7188V27.8895H56.6117V24.9966Z" fill="white" />
            <path d="M56.6117 39.4614H53.7188V42.3543H56.6117V39.4614Z" fill="white" />
            <path d="M56.6117 45.2471H53.7188V48.14H56.6117V45.2471Z" fill="white" />
            <path d="M56.6117 48.1401H53.7188V51.033H56.6117V48.1401Z" fill="white" />
            <path d="M56.6117 51.0332H53.7188V53.9261H56.6117V51.0332Z" fill="white" />
            <path d="M56.6117 59.7119H53.7188V62.6048H56.6117V59.7119Z" fill="white" />
            <path d="M56.6117 62.6045H53.7188V65.4974H56.6117V62.6045Z" fill="white" />
            <path d="M56.6117 68.3901H53.7188V71.283H56.6117V68.3901Z" fill="white" />
            <path d="M56.6117 74.1763H53.7188V77.0692H56.6117V74.1763Z" fill="white" />
            <path d="M56.6117 77.0693H53.7188V79.9622H56.6117V77.0693Z" fill="white" />
            <path d="M56.6117 79.9619H53.7188V82.8548H56.6117V79.9619Z" fill="white" />
            <path d="M56.6117 82.8545H53.7188V85.7474H56.6117V82.8545Z" fill="white" />
            <path d="M59.5047 7.63916H56.6118V10.5321H59.5047V7.63916Z" fill="white" />
            <path d="M59.5047 10.5322H56.6118V13.4251H59.5047V10.5322Z" fill="white" />
            <path d="M59.5047 13.4253H56.6118V16.3182H59.5047V13.4253Z" fill="white" />
            <path d="M59.5047 16.3179H56.6118V19.2108H59.5047V16.3179Z" fill="white" />
            <path d="M59.5047 36.5684H56.6118V39.4613H59.5047V36.5684Z" fill="white" />
            <path d="M59.5047 39.4614H56.6118V42.3543H59.5047V39.4614Z" fill="white" />
            <path d="M59.5047 42.3545H56.6118V45.2474H59.5047V42.3545Z" fill="white" />
            <path d="M59.5047 51.0332H56.6118V53.9261H59.5047V51.0332Z" fill="white" />
            <path d="M59.5047 53.9253H56.6118V56.8182H59.5047V53.9253Z" fill="white" />
            <path d="M59.5047 56.8188H56.6118V59.7118H59.5047V56.8188Z" fill="white" />
            <path d="M59.5047 68.3901H56.6118V71.283H59.5047V68.3901Z" fill="white" />
            <path d="M59.5047 74.1763H56.6118V77.0692H59.5047V74.1763Z" fill="white" />
            <path d="M62.3978 1.85352H59.5049V4.74642H62.3978V1.85352Z" fill="white" />
            <path d="M62.3978 7.63916H59.5049V10.5321H62.3978V7.63916Z" fill="white" />
            <path d="M62.3978 13.4253H59.5049V16.3182H62.3978V13.4253Z" fill="white" />
            <path d="M62.3978 16.3179H59.5049V19.2108H62.3978V16.3179Z" fill="white" />
            <path d="M62.3978 19.2109H59.5049V22.1038H62.3978V19.2109Z" fill="white" />
            <path d="M62.3978 22.104H59.5049V24.9969H62.3978V22.104Z" fill="white" />
            <path d="M62.3978 24.9966H59.5049V27.8895H62.3978V24.9966Z" fill="white" />
            <path d="M62.3978 30.7827H59.5049V33.6756H62.3978V30.7827Z" fill="white" />
            <path d="M62.3978 33.6753H59.5049V36.5682H62.3978V33.6753Z" fill="white" />
            <path d="M62.3978 45.2471H59.5049V48.14H62.3978V45.2471Z" fill="white" />
            <path d="M62.3978 48.1401H59.5049V51.033H62.3978V48.1401Z" fill="white" />
            <path d="M62.3978 51.0332H59.5049V53.9261H62.3978V51.0332Z" fill="white" />
            <path d="M62.3978 59.7119H59.5049V62.6048H62.3978V59.7119Z" fill="white" />
            <path d="M62.3978 62.6045H59.5049V65.4974H62.3978V62.6045Z" fill="white" />
            <path d="M62.3978 65.4971H59.5049V68.39H62.3978V65.4971Z" fill="white" />
            <path d="M62.3978 68.3901H59.5049V71.283H62.3978V68.3901Z" fill="white" />
            <path d="M62.3978 71.2837H59.5049V74.1766H62.3978V71.2837Z" fill="white" />
            <path d="M62.3978 74.1763H59.5049V77.0692H62.3978V74.1763Z" fill="white" />
            <path d="M62.3978 77.0693H59.5049V79.9622H62.3978V77.0693Z" fill="white" />
            <path d="M65.2904 24.9966H62.3975V27.8895H65.2904V24.9966Z" fill="white" />
            <path d="M65.2904 27.8896H62.3975V30.7826H65.2904V27.8896Z" fill="white" />
            <path d="M65.2904 33.6753H62.3975V36.5682H65.2904V33.6753Z" fill="white" />
            <path d="M65.2904 39.4614H62.3975V42.3543H65.2904V39.4614Z" fill="white" />
            <path d="M65.2904 42.3545H62.3975V45.2474H65.2904V42.3545Z" fill="white" />
            <path d="M65.2904 48.1401H62.3975V51.033H65.2904V48.1401Z" fill="white" />
            <path d="M65.2904 59.7119H62.3975V62.6048H65.2904V59.7119Z" fill="white" />
            <path d="M65.2904 71.2837H62.3975V74.1766H65.2904V71.2837Z" fill="white" />
            <path d="M65.2904 82.8545H62.3975V85.7474H65.2904V82.8545Z" fill="white" />
            <path d="M68.1829 24.9966H65.29V27.8895H68.1829V24.9966Z" fill="white" />
            <path d="M68.1829 27.8896H65.29V30.7826H68.1829V27.8896Z" fill="white" />
            <path d="M68.1829 30.7827H65.29V33.6756H68.1829V30.7827Z" fill="white" />
            <path d="M68.1829 39.4614H65.29V42.3543H68.1829V39.4614Z" fill="white" />
            <path d="M68.1829 51.0332H65.29V53.9261H68.1829V51.0332Z" fill="white" />
            <path d="M68.1829 59.7119H65.29V62.6048H68.1829V59.7119Z" fill="white" />
            <path d="M68.1829 65.4971H65.29V68.39H68.1829V65.4971Z" fill="white" />
            <path d="M68.1829 71.2837H65.29V74.1766H68.1829V71.2837Z" fill="white" />
            <path d="M68.1829 74.1763H65.29V77.0692H68.1829V74.1763Z" fill="white" />
            <path d="M68.1829 77.0693H65.29V79.9622H68.1829V77.0693Z" fill="white" />
            <path d="M68.1829 79.9619H65.29V82.8548H68.1829V79.9619Z" fill="white" />
            <path d="M71.076 24.9966H68.1831V27.8895H71.076V24.9966Z" fill="white" />
            <path d="M71.076 30.7827H68.1831V33.6756H71.076V30.7827Z" fill="white" />
            <path d="M71.076 33.6753H68.1831V36.5682H71.076V33.6753Z" fill="white" />
            <path d="M71.076 36.5684H68.1831V39.4613H71.076V36.5684Z" fill="white" />
            <path d="M71.076 39.4614H68.1831V42.3543H71.076V39.4614Z" fill="white" />
            <path d="M71.076 51.0332H68.1831V53.9261H71.076V51.0332Z" fill="white" />
            <path d="M71.076 59.7119H68.1831V62.6048H71.076V59.7119Z" fill="white" />
            <path d="M71.076 71.2837H68.1831V74.1766H71.076V71.2837Z" fill="white" />
            <path d="M71.076 77.0693H68.1831V79.9622H71.076V77.0693Z" fill="white" />
            <path d="M73.9696 27.8896H71.0767V30.7826H73.9696V27.8896Z" fill="white" />
            <path d="M73.9696 30.7827H71.0767V33.6756H73.9696V30.7827Z" fill="white" />
            <path d="M73.9696 36.5684H71.0767V39.4613H73.9696V36.5684Z" fill="white" />
            <path d="M73.9696 59.7119H71.0767V62.6048H73.9696V59.7119Z" fill="white" />
            <path d="M73.9696 62.6045H71.0767V65.4974H73.9696V62.6045Z" fill="white" />
            <path d="M73.9696 65.4971H71.0767V68.39H73.9696V65.4971Z" fill="white" />
            <path d="M73.9696 68.3901H71.0767V71.283H73.9696V68.3901Z" fill="white" />
            <path d="M73.9696 71.2837H71.0767V74.1766H73.9696V71.2837Z" fill="white" />
            <path d="M73.9696 74.1763H71.0767V77.0692H73.9696V74.1763Z" fill="white" />
            <path d="M76.8621 24.9966H73.9692V27.8895H76.8621V24.9966Z" fill="white" />
            <path d="M76.8621 33.6753H73.9692V36.5682H76.8621V33.6753Z" fill="white" />
            <path d="M76.8621 39.4614H73.9692V42.3543H76.8621V39.4614Z" fill="white" />
            <path d="M76.8621 45.2471H73.9692V48.14H76.8621V45.2471Z" fill="white" />
            <path d="M76.8621 48.1401H73.9692V51.033H76.8621V48.1401Z" fill="white" />
            <path d="M76.8621 51.0332H73.9692V53.9261H76.8621V51.0332Z" fill="white" />
            <path d="M76.8621 56.8188H73.9692V59.7118H76.8621V56.8188Z" fill="white" />
            <path d="M76.8621 59.7119H73.9692V62.6048H76.8621V59.7119Z" fill="white" />
            <path d="M76.8621 62.6045H73.9692V65.4974H76.8621V62.6045Z" fill="white" />
            <path d="M76.8621 65.4971H73.9692V68.39H76.8621V65.4971Z" fill="white" />
            <path d="M76.8621 71.2837H73.9692V74.1766H76.8621V71.2837Z" fill="white" />
            <path d="M76.8621 82.8545H73.9692V85.7474H76.8621V82.8545Z" fill="white" />
            <path d="M79.7552 36.5684H76.8623V39.4613H79.7552V36.5684Z" fill="white" />
            <path d="M79.7552 39.4614H76.8623V42.3543H79.7552V39.4614Z" fill="white" />
            <path d="M79.7552 42.3545H76.8623V45.2474H79.7552V42.3545Z" fill="white" />
            <path d="M79.7552 53.9253H76.8623V56.8182H79.7552V53.9253Z" fill="white" />
            <path d="M79.7552 56.8188H76.8623V59.7118H79.7552V56.8188Z" fill="white" />
            <path d="M79.7552 59.7119H76.8623V62.6048H79.7552V59.7119Z" fill="white" />
            <path d="M79.7552 71.2837H76.8623V74.1766H79.7552V71.2837Z" fill="white" />
            <path d="M79.7552 74.1763H76.8623V77.0692H79.7552V74.1763Z" fill="white" />
            <path d="M79.7552 77.0693H76.8623V79.9622H79.7552V77.0693Z" fill="white" />
            <path d="M79.7552 82.8545H76.8623V85.7474H79.7552V82.8545Z" fill="white" />
            <path d="M82.6478 24.9966H79.7549V27.8895H82.6478V24.9966Z" fill="white" />
            <path d="M82.6478 33.6753H79.7549V36.5682H82.6478V33.6753Z" fill="white" />
            <path d="M82.6478 45.2471H79.7549V48.14H82.6478V45.2471Z" fill="white" />
            <path d="M82.6478 51.0332H79.7549V53.9261H82.6478V51.0332Z" fill="white" />
            <path d="M82.6478 56.8188H79.7549V59.7118H82.6478V56.8188Z" fill="white" />
            <path d="M82.6478 59.7119H79.7549V62.6048H82.6478V59.7119Z" fill="white" />
            <path d="M82.6478 65.4971H79.7549V68.39H82.6478V65.4971Z" fill="white" />
            <path d="M82.6478 68.3901H79.7549V71.283H82.6478V68.3901Z" fill="white" />
            <path d="M82.6478 79.9619H79.7549V82.8548H82.6478V79.9619Z" fill="white" />
            <path d="M85.5404 27.8896H82.6475V30.7826H85.5404V27.8896Z" fill="white" />
            <path d="M85.5404 30.7827H82.6475V33.6756H85.5404V30.7827Z" fill="white" />
            <path d="M85.5404 36.5684H82.6475V39.4613H85.5404V36.5684Z" fill="white" />
            <path d="M85.5404 48.1401H82.6475V51.033H85.5404V48.1401Z" fill="white" />
            <path d="M85.5404 51.0332H82.6475V53.9261H85.5404V51.0332Z" fill="white" />
            <path d="M85.5404 59.7119H82.6475V62.6048H85.5404V59.7119Z" fill="white" />
            <path d="M85.5404 62.6045H82.6475V65.4974H85.5404V62.6045Z" fill="white" />
            <path d="M85.5404 65.4971H82.6475V68.39H85.5404V65.4971Z" fill="white" />
            <path d="M85.5404 71.2837H82.6475V74.1766H85.5404V71.2837Z" fill="white" />
            <path d="M85.5404 79.9619H82.6475V82.8548H85.5404V79.9619Z" fill="white" />
        </mask>
        <g mask="url(#mask0_14_1007)">
            <path d="M85.5408 1.85352H1.64648V85.7479H85.5408V1.85352Z" fill="#161345" />
        </g>
        <mask
            id="mask1_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="85"
            height="85"
        >
            <path d="M4.53939 24.9966H1.64648V27.8895H4.53939V24.9966Z" fill="white" />
            <path d="M4.53939 27.8896H1.64648V30.7826H4.53939V27.8896Z" fill="white" />
            <path d="M4.53939 30.7827H1.64648V33.6756H4.53939V30.7827Z" fill="white" />
            <path d="M4.53939 33.6753H1.64648V36.5682H4.53939V33.6753Z" fill="white" />
            <path d="M4.53939 39.4614H1.64648V42.3543H4.53939V39.4614Z" fill="white" />
            <path d="M4.53939 42.3545H1.64648V45.2474H4.53939V42.3545Z" fill="white" />
            <path d="M4.53939 51.0332H1.64648V53.9261H4.53939V51.0332Z" fill="white" />
            <path d="M4.53939 53.9253H1.64648V56.8182H4.53939V53.9253Z" fill="white" />
            <path d="M4.53939 59.7119H1.64648V62.6048H4.53939V59.7119Z" fill="white" />
            <path d="M7.43246 27.8896H4.53955V30.7826H7.43246V27.8896Z" fill="white" />
            <path d="M7.43246 42.3545H4.53955V45.2474H7.43246V42.3545Z" fill="white" />
            <path d="M7.43246 48.1401H4.53955V51.033H7.43246V48.1401Z" fill="white" />
            <path d="M7.43246 51.0332H4.53955V53.9261H7.43246V51.0332Z" fill="white" />
            <path d="M7.43246 53.9253H4.53955V56.8182H7.43246V53.9253Z" fill="white" />
            <path d="M10.325 24.9966H7.43213V27.8895H10.325V24.9966Z" fill="white" />
            <path d="M10.325 30.7827H7.43213V33.6756H10.325V30.7827Z" fill="white" />
            <path d="M10.325 36.5684H7.43213V39.4613H10.325V36.5684Z" fill="white" />
            <path d="M10.325 42.3545H7.43213V45.2474H10.325V42.3545Z" fill="white" />
            <path d="M10.325 48.1401H7.43213V51.033H10.325V48.1401Z" fill="white" />
            <path d="M10.325 51.0332H7.43213V53.9261H10.325V51.0332Z" fill="white" />
            <path d="M10.325 59.7119H7.43213V62.6048H10.325V59.7119Z" fill="white" />
            <path d="M13.2181 24.9966H10.3252V27.8895H13.2181V24.9966Z" fill="white" />
            <path d="M13.2181 27.8896H10.3252V30.7826H13.2181V27.8896Z" fill="white" />
            <path d="M13.2181 36.5684H10.3252V39.4613H13.2181V36.5684Z" fill="white" />
            <path d="M13.2181 45.2471H10.3252V48.14H13.2181V45.2471Z" fill="white" />
            <path d="M13.2181 48.1401H10.3252V51.033H13.2181V48.1401Z" fill="white" />
            <path d="M13.2181 53.9253H10.3252V56.8182H13.2181V53.9253Z" fill="white" />
            <path d="M13.2181 56.8188H10.3252V59.7118H13.2181V56.8188Z" fill="white" />
            <path d="M16.1112 30.7827H13.2183V33.6756H16.1112V30.7827Z" fill="white" />
            <path d="M16.1112 33.6753H13.2183V36.5682H16.1112V33.6753Z" fill="white" />
            <path d="M16.1112 36.5684H13.2183V39.4613H16.1112V36.5684Z" fill="white" />
            <path d="M16.1112 45.2471H13.2183V48.14H16.1112V45.2471Z" fill="white" />
            <path d="M16.1112 48.1401H13.2183V51.033H16.1112V48.1401Z" fill="white" />
            <path d="M16.1112 51.0332H13.2183V53.9261H16.1112V51.0332Z" fill="white" />
            <path d="M16.1112 53.9253H13.2183V56.8182H16.1112V53.9253Z" fill="white" />
            <path d="M19.0037 24.9966H16.1108V27.8895H19.0037V24.9966Z" fill="white" />
            <path d="M19.0037 27.8896H16.1108V30.7826H19.0037V27.8896Z" fill="white" />
            <path d="M19.0037 33.6753H16.1108V36.5682H19.0037V33.6753Z" fill="white" />
            <path d="M19.0037 39.4614H16.1108V42.3543H19.0037V39.4614Z" fill="white" />
            <path d="M19.0037 45.2471H16.1108V48.14H19.0037V45.2471Z" fill="white" />
            <path d="M19.0037 48.1401H16.1108V51.033H19.0037V48.1401Z" fill="white" />
            <path d="M19.0037 51.0332H16.1108V53.9261H19.0037V51.0332Z" fill="white" />
            <path d="M19.0037 53.9253H16.1108V56.8182H19.0037V53.9253Z" fill="white" />
            <path d="M19.0037 56.8188H16.1108V59.7118H19.0037V56.8188Z" fill="white" />
            <path d="M19.0037 59.7119H16.1108V62.6048H19.0037V59.7119Z" fill="white" />
            <path d="M21.8968 24.9966H19.0039V27.8895H21.8968V24.9966Z" fill="white" />
            <path d="M21.8968 30.7827H19.0039V33.6756H21.8968V30.7827Z" fill="white" />
            <path d="M21.8968 36.5684H19.0039V39.4613H21.8968V36.5684Z" fill="white" />
            <path d="M21.8968 42.3545H19.0039V45.2474H21.8968V42.3545Z" fill="white" />
            <path d="M21.8968 48.1401H19.0039V51.033H21.8968V48.1401Z" fill="white" />
            <path d="M21.8968 53.9253H19.0039V56.8182H21.8968V53.9253Z" fill="white" />
            <path d="M21.8968 59.7119H19.0039V62.6048H21.8968V59.7119Z" fill="white" />
            <path d="M24.7899 24.9966H21.897V27.8895H24.7899V24.9966Z" fill="white" />
            <path d="M24.7899 27.8896H21.897V30.7826H24.7899V27.8896Z" fill="white" />
            <path d="M24.7899 33.6753H21.897V36.5682H24.7899V33.6753Z" fill="white" />
            <path d="M24.7899 42.3545H21.897V45.2474H24.7899V42.3545Z" fill="white" />
            <path d="M24.7899 45.2471H21.897V48.14H24.7899V45.2471Z" fill="white" />
            <path d="M24.7899 56.8188H21.897V59.7118H24.7899V56.8188Z" fill="white" />
            <path d="M24.7899 59.7119H21.897V62.6048H24.7899V59.7119Z" fill="white" />
            <path d="M27.6825 4.74658H24.7896V7.63949H27.6825V4.74658Z" fill="white" />
            <path d="M27.6825 10.5322H24.7896V13.4251H27.6825V10.5322Z" fill="white" />
            <path d="M27.6825 16.3179H24.7896V19.2108H27.6825V16.3179Z" fill="white" />
            <path d="M27.6825 19.2109H24.7896V22.1038H27.6825V19.2109Z" fill="white" />
            <path d="M27.6825 22.104H24.7896V24.9969H27.6825V22.104Z" fill="white" />
            <path d="M27.6825 24.9966H24.7896V27.8895H27.6825V24.9966Z" fill="white" />
            <path d="M27.6825 30.7827H24.7896V33.6756H27.6825V30.7827Z" fill="white" />
            <path d="M27.6825 33.6753H24.7896V36.5682H27.6825V33.6753Z" fill="white" />
            <path d="M27.6825 36.5684H24.7896V39.4613H27.6825V36.5684Z" fill="white" />
            <path d="M27.6825 42.3545H24.7896V45.2474H27.6825V42.3545Z" fill="white" />
            <path d="M27.6825 51.0332H24.7896V53.9261H27.6825V51.0332Z" fill="white" />
            <path d="M27.6825 56.8188H24.7896V59.7118H27.6825V56.8188Z" fill="white" />
            <path d="M27.6825 59.7119H24.7896V62.6048H27.6825V59.7119Z" fill="white" />
            <path d="M27.6825 62.6045H24.7896V65.4974H27.6825V62.6045Z" fill="white" />
            <path d="M27.6825 65.4971H24.7896V68.39H27.6825V65.4971Z" fill="white" />
            <path d="M27.6825 68.3901H24.7896V71.283H27.6825V68.3901Z" fill="white" />
            <path d="M27.6825 74.1763H24.7896V77.0692H27.6825V74.1763Z" fill="white" />
            <path d="M27.6825 77.0693H24.7896V79.9622H27.6825V77.0693Z" fill="white" />
            <path d="M27.6825 82.8545H24.7896V85.7474H27.6825V82.8545Z" fill="white" />
            <path d="M30.5755 1.85352H27.6826V4.74642H30.5755V1.85352Z" fill="white" />
            <path d="M30.5755 10.5322H27.6826V13.4251H30.5755V10.5322Z" fill="white" />
            <path d="M30.5755 13.4253H27.6826V16.3182H30.5755V13.4253Z" fill="white" />
            <path d="M30.5755 22.104H27.6826V24.9969H30.5755V22.104Z" fill="white" />
            <path d="M30.5755 24.9966H27.6826V27.8895H30.5755V24.9966Z" fill="white" />
            <path d="M30.5755 33.6753H27.6826V36.5682H30.5755V33.6753Z" fill="white" />
            <path d="M30.5755 42.3545H27.6826V45.2474H30.5755V42.3545Z" fill="white" />
            <path d="M30.5755 45.2471H27.6826V48.14H30.5755V45.2471Z" fill="white" />
            <path d="M30.5755 51.0332H27.6826V53.9261H30.5755V51.0332Z" fill="white" />
            <path d="M30.5755 59.7119H27.6826V62.6048H30.5755V59.7119Z" fill="white" />
            <path d="M30.5755 62.6045H27.6826V65.4974H30.5755V62.6045Z" fill="white" />
            <path d="M30.5755 65.4971H27.6826V68.39H30.5755V65.4971Z" fill="white" />
            <path d="M30.5755 74.1763H27.6826V77.0692H30.5755V74.1763Z" fill="white" />
            <path d="M30.5755 82.8545H27.6826V85.7474H30.5755V82.8545Z" fill="white" />
            <path d="M33.4686 1.85352H30.5757V4.74642H33.4686V1.85352Z" fill="white" />
            <path d="M33.4686 4.74658H30.5757V7.63949H33.4686V4.74658Z" fill="white" />
            <path d="M33.4686 19.2109H30.5757V22.1038H33.4686V19.2109Z" fill="white" />
            <path d="M33.4686 24.9966H30.5757V27.8895H33.4686V24.9966Z" fill="white" />
            <path d="M33.4686 27.8896H30.5757V30.7826H33.4686V27.8896Z" fill="white" />
            <path d="M33.4686 33.6753H30.5757V36.5682H33.4686V33.6753Z" fill="white" />
            <path d="M33.4686 45.2471H30.5757V48.14H33.4686V45.2471Z" fill="white" />
            <path d="M33.4686 48.1401H30.5757V51.033H33.4686V48.1401Z" fill="white" />
            <path d="M33.4686 56.8188H30.5757V59.7118H33.4686V56.8188Z" fill="white" />
            <path d="M33.4686 65.4971H30.5757V68.39H33.4686V65.4971Z" fill="white" />
            <path d="M33.4686 68.3901H30.5757V71.283H33.4686V68.3901Z" fill="white" />
            <path d="M33.4686 71.2837H30.5757V74.1766H33.4686V71.2837Z" fill="white" />
            <path d="M33.4686 74.1763H30.5757V77.0692H33.4686V74.1763Z" fill="white" />
            <path d="M33.4686 79.9619H30.5757V82.8548H33.4686V79.9619Z" fill="white" />
            <path d="M36.3612 4.74658H33.4683V7.63949H36.3612V4.74658Z" fill="white" />
            <path d="M36.3612 10.5322H33.4683V13.4251H36.3612V10.5322Z" fill="white" />
            <path d="M36.3612 30.7827H33.4683V33.6756H36.3612V30.7827Z" fill="white" />
            <path d="M36.3612 33.6753H33.4683V36.5682H36.3612V33.6753Z" fill="white" />
            <path d="M36.3612 36.5684H33.4683V39.4613H36.3612V36.5684Z" fill="white" />
            <path d="M36.3612 39.4614H33.4683V42.3543H36.3612V39.4614Z" fill="white" />
            <path d="M36.3612 53.9253H33.4683V56.8182H36.3612V53.9253Z" fill="white" />
            <path d="M36.3612 62.6045H33.4683V65.4974H36.3612V62.6045Z" fill="white" />
            <path d="M36.3612 65.4971H33.4683V68.39H36.3612V65.4971Z" fill="white" />
            <path d="M36.3612 71.2837H33.4683V74.1766H36.3612V71.2837Z" fill="white" />
            <path d="M36.3612 77.0693H33.4683V79.9622H36.3612V77.0693Z" fill="white" />
            <path d="M36.3612 79.9619H33.4683V82.8548H36.3612V79.9619Z" fill="white" />
            <path d="M39.2542 10.5322H36.3613V13.4251H39.2542V10.5322Z" fill="white" />
            <path d="M39.2542 16.3179H36.3613V19.2108H39.2542V16.3179Z" fill="white" />
            <path d="M39.2542 19.2109H36.3613V22.1038H39.2542V19.2109Z" fill="white" />
            <path d="M39.2542 22.104H36.3613V24.9969H39.2542V22.104Z" fill="white" />
            <path d="M39.2542 24.9966H36.3613V27.8895H39.2542V24.9966Z" fill="white" />
            <path d="M39.2542 27.8896H36.3613V30.7826H39.2542V27.8896Z" fill="white" />
            <path d="M39.2542 30.7827H36.3613V33.6756H39.2542V30.7827Z" fill="white" />
            <path d="M39.2542 33.6753H36.3613V36.5682H39.2542V33.6753Z" fill="white" />
            <path d="M39.2542 36.5684H36.3613V39.4613H39.2542V36.5684Z" fill="white" />
            <path d="M39.2542 45.2471H36.3613V48.14H39.2542V45.2471Z" fill="white" />
            <path d="M39.2542 48.1401H36.3613V51.033H39.2542V48.1401Z" fill="white" />
            <path d="M39.2542 51.0332H36.3613V53.9261H39.2542V51.0332Z" fill="white" />
            <path d="M39.2542 53.9253H36.3613V56.8182H39.2542V53.9253Z" fill="white" />
            <path d="M39.2542 56.8188H36.3613V59.7118H39.2542V56.8188Z" fill="white" />
            <path d="M39.2542 59.7119H36.3613V62.6048H39.2542V59.7119Z" fill="white" />
            <path d="M39.2542 65.4971H36.3613V68.39H39.2542V65.4971Z" fill="white" />
            <path d="M39.2542 68.3901H36.3613V71.283H39.2542V68.3901Z" fill="white" />
            <path d="M39.2542 74.1763H36.3613V77.0692H39.2542V74.1763Z" fill="white" />
            <path d="M42.1473 1.85352H39.2544V4.74642H42.1473V1.85352Z" fill="white" />
            <path d="M42.1473 4.74658H39.2544V7.63949H42.1473V4.74658Z" fill="white" />
            <path d="M42.1473 7.63916H39.2544V10.5321H42.1473V7.63916Z" fill="white" />
            <path d="M42.1473 10.5322H39.2544V13.4251H42.1473V10.5322Z" fill="white" />
            <path d="M42.1473 24.9966H39.2544V27.8895H42.1473V24.9966Z" fill="white" />
            <path d="M42.1473 27.8896H39.2544V30.7826H42.1473V27.8896Z" fill="white" />
            <path d="M42.1473 30.7827H39.2544V33.6756H42.1473V30.7827Z" fill="white" />
            <path d="M42.1473 33.6753H39.2544V36.5682H42.1473V33.6753Z" fill="white" />
            <path d="M42.1473 39.4614H39.2544V42.3543H42.1473V39.4614Z" fill="white" />
            <path d="M42.1473 45.2471H39.2544V48.14H42.1473V45.2471Z" fill="white" />
            <path d="M42.1473 51.0332H39.2544V53.9261H42.1473V51.0332Z" fill="white" />
            <path d="M42.1473 56.8188H39.2544V59.7118H42.1473V56.8188Z" fill="white" />
            <path d="M42.1473 62.6045H39.2544V65.4974H42.1473V62.6045Z" fill="white" />
            <path d="M42.1473 68.3901H39.2544V71.283H42.1473V68.3901Z" fill="white" />
            <path d="M42.1473 74.1763H39.2544V77.0692H42.1473V74.1763Z" fill="white" />
            <path d="M42.1473 82.8545H39.2544V85.7474H42.1473V82.8545Z" fill="white" />
            <path d="M45.0404 1.85352H42.1475V4.74642H45.0404V1.85352Z" fill="white" />
            <path d="M45.0404 4.74658H42.1475V7.63949H45.0404V4.74658Z" fill="white" />
            <path d="M45.0404 10.5322H42.1475V13.4251H45.0404V10.5322Z" fill="white" />
            <path d="M45.0404 13.4253H42.1475V16.3182H45.0404V13.4253Z" fill="white" />
            <path d="M45.0404 19.2109H42.1475V22.1038H45.0404V19.2109Z" fill="white" />
            <path d="M45.0404 22.104H42.1475V24.9969H45.0404V22.104Z" fill="white" />
            <path d="M45.0404 36.5684H42.1475V39.4613H45.0404V36.5684Z" fill="white" />
            <path d="M45.0404 39.4614H42.1475V42.3543H45.0404V39.4614Z" fill="white" />
            <path d="M45.0404 42.3545H42.1475V45.2474H45.0404V42.3545Z" fill="white" />
            <path d="M45.0404 51.0332H42.1475V53.9261H45.0404V51.0332Z" fill="white" />
            <path d="M45.0404 53.9253H42.1475V56.8182H45.0404V53.9253Z" fill="white" />
            <path d="M45.0404 65.4971H42.1475V68.39H45.0404V65.4971Z" fill="white" />
            <path d="M45.0404 79.9619H42.1475V82.8548H45.0404V79.9619Z" fill="white" />
            <path d="M45.0404 82.8545H42.1475V85.7474H45.0404V82.8545Z" fill="white" />
            <path d="M47.9329 1.85352H45.04V4.74642H47.9329V1.85352Z" fill="white" />
            <path d="M47.9329 7.63916H45.04V10.5321H47.9329V7.63916Z" fill="white" />
            <path d="M47.9329 10.5322H45.04V13.4251H47.9329V10.5322Z" fill="white" />
            <path d="M47.9329 22.104H45.04V24.9969H47.9329V22.104Z" fill="white" />
            <path d="M47.9329 27.8896H45.04V30.7826H47.9329V27.8896Z" fill="white" />
            <path d="M47.9329 33.6753H45.04V36.5682H47.9329V33.6753Z" fill="white" />
            <path d="M47.9329 39.4614H45.04V42.3543H47.9329V39.4614Z" fill="white" />
            <path d="M47.9329 42.3545H45.04V45.2474H47.9329V42.3545Z" fill="white" />
            <path d="M47.9329 45.2471H45.04V48.14H47.9329V45.2471Z" fill="white" />
            <path d="M47.9329 51.0332H45.04V53.9261H47.9329V51.0332Z" fill="white" />
            <path d="M47.9329 53.9253H45.04V56.8182H47.9329V53.9253Z" fill="white" />
            <path d="M47.9329 56.8188H45.04V59.7118H47.9329V56.8188Z" fill="white" />
            <path d="M47.9329 59.7119H45.04V62.6048H47.9329V59.7119Z" fill="white" />
            <path d="M47.9329 68.3901H45.04V71.283H47.9329V68.3901Z" fill="white" />
            <path d="M47.9329 79.9619H45.04V82.8548H47.9329V79.9619Z" fill="white" />
            <path d="M50.826 7.63916H47.9331V10.5321H50.826V7.63916Z" fill="white" />
            <path d="M50.826 19.2109H47.9331V22.1038H50.826V19.2109Z" fill="white" />
            <path d="M50.826 30.7827H47.9331V33.6756H50.826V30.7827Z" fill="white" />
            <path d="M50.826 39.4614H47.9331V42.3543H50.826V39.4614Z" fill="white" />
            <path d="M50.826 42.3545H47.9331V45.2474H50.826V42.3545Z" fill="white" />
            <path d="M50.826 45.2471H47.9331V48.14H50.826V45.2471Z" fill="white" />
            <path d="M50.826 53.9253H47.9331V56.8182H50.826V53.9253Z" fill="white" />
            <path d="M50.826 56.8188H47.9331V59.7118H50.826V56.8188Z" fill="white" />
            <path d="M50.826 59.7119H47.9331V62.6048H50.826V59.7119Z" fill="white" />
            <path d="M50.826 62.6045H47.9331V65.4974H50.826V62.6045Z" fill="white" />
            <path d="M50.826 79.9619H47.9331V82.8548H50.826V79.9619Z" fill="white" />
            <path d="M53.7191 4.74658H50.8262V7.63949H53.7191V4.74658Z" fill="white" />
            <path d="M53.7191 7.63916H50.8262V10.5321H53.7191V7.63916Z" fill="white" />
            <path d="M53.7191 16.3179H50.8262V19.2108H53.7191V16.3179Z" fill="white" />
            <path d="M53.7191 24.9966H50.8262V27.8895H53.7191V24.9966Z" fill="white" />
            <path d="M53.7191 27.8896H50.8262V30.7826H53.7191V27.8896Z" fill="white" />
            <path d="M53.7191 33.6753H50.8262V36.5682H53.7191V33.6753Z" fill="white" />
            <path d="M53.7191 39.4614H50.8262V42.3543H53.7191V39.4614Z" fill="white" />
            <path d="M53.7191 45.2471H50.8262V48.14H53.7191V45.2471Z" fill="white" />
            <path d="M53.7191 48.1401H50.8262V51.033H53.7191V48.1401Z" fill="white" />
            <path d="M53.7191 51.0332H50.8262V53.9261H53.7191V51.0332Z" fill="white" />
            <path d="M53.7191 53.9253H50.8262V56.8182H53.7191V53.9253Z" fill="white" />
            <path d="M53.7191 68.3901H50.8262V71.283H53.7191V68.3901Z" fill="white" />
            <path d="M53.7191 77.0693H50.8262V79.9622H53.7191V77.0693Z" fill="white" />
            <path d="M53.7191 79.9619H50.8262V82.8548H53.7191V79.9619Z" fill="white" />
            <path d="M53.7191 82.8545H50.8262V85.7474H53.7191V82.8545Z" fill="white" />
            <path d="M56.6117 1.85352H53.7188V4.74642H56.6117V1.85352Z" fill="white" />
            <path d="M56.6117 7.63916H53.7188V10.5321H56.6117V7.63916Z" fill="white" />
            <path d="M56.6117 16.3179H53.7188V19.2108H56.6117V16.3179Z" fill="white" />
            <path d="M56.6117 19.2109H53.7188V22.1038H56.6117V19.2109Z" fill="white" />
            <path d="M56.6117 24.9966H53.7188V27.8895H56.6117V24.9966Z" fill="white" />
            <path d="M56.6117 39.4614H53.7188V42.3543H56.6117V39.4614Z" fill="white" />
            <path d="M56.6117 45.2471H53.7188V48.14H56.6117V45.2471Z" fill="white" />
            <path d="M56.6117 48.1401H53.7188V51.033H56.6117V48.1401Z" fill="white" />
            <path d="M56.6117 51.0332H53.7188V53.9261H56.6117V51.0332Z" fill="white" />
            <path d="M56.6117 59.7119H53.7188V62.6048H56.6117V59.7119Z" fill="white" />
            <path d="M56.6117 62.6045H53.7188V65.4974H56.6117V62.6045Z" fill="white" />
            <path d="M56.6117 68.3901H53.7188V71.283H56.6117V68.3901Z" fill="white" />
            <path d="M56.6117 74.1763H53.7188V77.0692H56.6117V74.1763Z" fill="white" />
            <path d="M56.6117 77.0693H53.7188V79.9622H56.6117V77.0693Z" fill="white" />
            <path d="M56.6117 79.9619H53.7188V82.8548H56.6117V79.9619Z" fill="white" />
            <path d="M56.6117 82.8545H53.7188V85.7474H56.6117V82.8545Z" fill="white" />
            <path d="M59.5047 7.63916H56.6118V10.5321H59.5047V7.63916Z" fill="white" />
            <path d="M59.5047 10.5322H56.6118V13.4251H59.5047V10.5322Z" fill="white" />
            <path d="M59.5047 13.4253H56.6118V16.3182H59.5047V13.4253Z" fill="white" />
            <path d="M59.5047 16.3179H56.6118V19.2108H59.5047V16.3179Z" fill="white" />
            <path d="M59.5047 36.5684H56.6118V39.4613H59.5047V36.5684Z" fill="white" />
            <path d="M59.5047 39.4614H56.6118V42.3543H59.5047V39.4614Z" fill="white" />
            <path d="M59.5047 42.3545H56.6118V45.2474H59.5047V42.3545Z" fill="white" />
            <path d="M59.5047 51.0332H56.6118V53.9261H59.5047V51.0332Z" fill="white" />
            <path d="M59.5047 53.9253H56.6118V56.8182H59.5047V53.9253Z" fill="white" />
            <path d="M59.5047 56.8188H56.6118V59.7118H59.5047V56.8188Z" fill="white" />
            <path d="M59.5047 68.3901H56.6118V71.283H59.5047V68.3901Z" fill="white" />
            <path d="M59.5047 74.1763H56.6118V77.0692H59.5047V74.1763Z" fill="white" />
            <path d="M62.3978 1.85352H59.5049V4.74642H62.3978V1.85352Z" fill="white" />
            <path d="M62.3978 7.63916H59.5049V10.5321H62.3978V7.63916Z" fill="white" />
            <path d="M62.3978 13.4253H59.5049V16.3182H62.3978V13.4253Z" fill="white" />
            <path d="M62.3978 16.3179H59.5049V19.2108H62.3978V16.3179Z" fill="white" />
            <path d="M62.3978 19.2109H59.5049V22.1038H62.3978V19.2109Z" fill="white" />
            <path d="M62.3978 22.104H59.5049V24.9969H62.3978V22.104Z" fill="white" />
            <path d="M62.3978 24.9966H59.5049V27.8895H62.3978V24.9966Z" fill="white" />
            <path d="M62.3978 30.7827H59.5049V33.6756H62.3978V30.7827Z" fill="white" />
            <path d="M62.3978 33.6753H59.5049V36.5682H62.3978V33.6753Z" fill="white" />
            <path d="M62.3978 45.2471H59.5049V48.14H62.3978V45.2471Z" fill="white" />
            <path d="M62.3978 48.1401H59.5049V51.033H62.3978V48.1401Z" fill="white" />
            <path d="M62.3978 51.0332H59.5049V53.9261H62.3978V51.0332Z" fill="white" />
            <path d="M62.3978 59.7119H59.5049V62.6048H62.3978V59.7119Z" fill="white" />
            <path d="M62.3978 62.6045H59.5049V65.4974H62.3978V62.6045Z" fill="white" />
            <path d="M62.3978 65.4971H59.5049V68.39H62.3978V65.4971Z" fill="white" />
            <path d="M62.3978 68.3901H59.5049V71.283H62.3978V68.3901Z" fill="white" />
            <path d="M62.3978 71.2837H59.5049V74.1766H62.3978V71.2837Z" fill="white" />
            <path d="M62.3978 74.1763H59.5049V77.0692H62.3978V74.1763Z" fill="white" />
            <path d="M62.3978 77.0693H59.5049V79.9622H62.3978V77.0693Z" fill="white" />
            <path d="M65.2904 24.9966H62.3975V27.8895H65.2904V24.9966Z" fill="white" />
            <path d="M65.2904 27.8896H62.3975V30.7826H65.2904V27.8896Z" fill="white" />
            <path d="M65.2904 33.6753H62.3975V36.5682H65.2904V33.6753Z" fill="white" />
            <path d="M65.2904 39.4614H62.3975V42.3543H65.2904V39.4614Z" fill="white" />
            <path d="M65.2904 42.3545H62.3975V45.2474H65.2904V42.3545Z" fill="white" />
            <path d="M65.2904 48.1401H62.3975V51.033H65.2904V48.1401Z" fill="white" />
            <path d="M65.2904 59.7119H62.3975V62.6048H65.2904V59.7119Z" fill="white" />
            <path d="M65.2904 71.2837H62.3975V74.1766H65.2904V71.2837Z" fill="white" />
            <path d="M65.2904 82.8545H62.3975V85.7474H65.2904V82.8545Z" fill="white" />
            <path d="M68.1829 24.9966H65.29V27.8895H68.1829V24.9966Z" fill="white" />
            <path d="M68.1829 27.8896H65.29V30.7826H68.1829V27.8896Z" fill="white" />
            <path d="M68.1829 30.7827H65.29V33.6756H68.1829V30.7827Z" fill="white" />
            <path d="M68.1829 39.4614H65.29V42.3543H68.1829V39.4614Z" fill="white" />
            <path d="M68.1829 51.0332H65.29V53.9261H68.1829V51.0332Z" fill="white" />
            <path d="M68.1829 59.7119H65.29V62.6048H68.1829V59.7119Z" fill="white" />
            <path d="M68.1829 65.4971H65.29V68.39H68.1829V65.4971Z" fill="white" />
            <path d="M68.1829 71.2837H65.29V74.1766H68.1829V71.2837Z" fill="white" />
            <path d="M68.1829 74.1763H65.29V77.0692H68.1829V74.1763Z" fill="white" />
            <path d="M68.1829 77.0693H65.29V79.9622H68.1829V77.0693Z" fill="white" />
            <path d="M68.1829 79.9619H65.29V82.8548H68.1829V79.9619Z" fill="white" />
            <path d="M71.076 24.9966H68.1831V27.8895H71.076V24.9966Z" fill="white" />
            <path d="M71.076 30.7827H68.1831V33.6756H71.076V30.7827Z" fill="white" />
            <path d="M71.076 33.6753H68.1831V36.5682H71.076V33.6753Z" fill="white" />
            <path d="M71.076 36.5684H68.1831V39.4613H71.076V36.5684Z" fill="white" />
            <path d="M71.076 39.4614H68.1831V42.3543H71.076V39.4614Z" fill="white" />
            <path d="M71.076 51.0332H68.1831V53.9261H71.076V51.0332Z" fill="white" />
            <path d="M71.076 59.7119H68.1831V62.6048H71.076V59.7119Z" fill="white" />
            <path d="M71.076 71.2837H68.1831V74.1766H71.076V71.2837Z" fill="white" />
            <path d="M71.076 77.0693H68.1831V79.9622H71.076V77.0693Z" fill="white" />
            <path d="M73.9696 27.8896H71.0767V30.7826H73.9696V27.8896Z" fill="white" />
            <path d="M73.9696 30.7827H71.0767V33.6756H73.9696V30.7827Z" fill="white" />
            <path d="M73.9696 36.5684H71.0767V39.4613H73.9696V36.5684Z" fill="white" />
            <path d="M73.9696 59.7119H71.0767V62.6048H73.9696V59.7119Z" fill="white" />
            <path d="M73.9696 62.6045H71.0767V65.4974H73.9696V62.6045Z" fill="white" />
            <path d="M73.9696 65.4971H71.0767V68.39H73.9696V65.4971Z" fill="white" />
            <path d="M73.9696 68.3901H71.0767V71.283H73.9696V68.3901Z" fill="white" />
            <path d="M73.9696 71.2837H71.0767V74.1766H73.9696V71.2837Z" fill="white" />
            <path d="M73.9696 74.1763H71.0767V77.0692H73.9696V74.1763Z" fill="white" />
            <path d="M76.8621 24.9966H73.9692V27.8895H76.8621V24.9966Z" fill="white" />
            <path d="M76.8621 33.6753H73.9692V36.5682H76.8621V33.6753Z" fill="white" />
            <path d="M76.8621 39.4614H73.9692V42.3543H76.8621V39.4614Z" fill="white" />
            <path d="M76.8621 45.2471H73.9692V48.14H76.8621V45.2471Z" fill="white" />
            <path d="M76.8621 48.1401H73.9692V51.033H76.8621V48.1401Z" fill="white" />
            <path d="M76.8621 51.0332H73.9692V53.9261H76.8621V51.0332Z" fill="white" />
            <path d="M76.8621 56.8188H73.9692V59.7118H76.8621V56.8188Z" fill="white" />
            <path d="M76.8621 59.7119H73.9692V62.6048H76.8621V59.7119Z" fill="white" />
            <path d="M76.8621 62.6045H73.9692V65.4974H76.8621V62.6045Z" fill="white" />
            <path d="M76.8621 65.4971H73.9692V68.39H76.8621V65.4971Z" fill="white" />
            <path d="M76.8621 71.2837H73.9692V74.1766H76.8621V71.2837Z" fill="white" />
            <path d="M76.8621 82.8545H73.9692V85.7474H76.8621V82.8545Z" fill="white" />
            <path d="M79.7552 36.5684H76.8623V39.4613H79.7552V36.5684Z" fill="white" />
            <path d="M79.7552 39.4614H76.8623V42.3543H79.7552V39.4614Z" fill="white" />
            <path d="M79.7552 42.3545H76.8623V45.2474H79.7552V42.3545Z" fill="white" />
            <path d="M79.7552 53.9253H76.8623V56.8182H79.7552V53.9253Z" fill="white" />
            <path d="M79.7552 56.8188H76.8623V59.7118H79.7552V56.8188Z" fill="white" />
            <path d="M79.7552 59.7119H76.8623V62.6048H79.7552V59.7119Z" fill="white" />
            <path d="M79.7552 71.2837H76.8623V74.1766H79.7552V71.2837Z" fill="white" />
            <path d="M79.7552 74.1763H76.8623V77.0692H79.7552V74.1763Z" fill="white" />
            <path d="M79.7552 77.0693H76.8623V79.9622H79.7552V77.0693Z" fill="white" />
            <path d="M79.7552 82.8545H76.8623V85.7474H79.7552V82.8545Z" fill="white" />
            <path d="M82.6478 24.9966H79.7549V27.8895H82.6478V24.9966Z" fill="white" />
            <path d="M82.6478 33.6753H79.7549V36.5682H82.6478V33.6753Z" fill="white" />
            <path d="M82.6478 45.2471H79.7549V48.14H82.6478V45.2471Z" fill="white" />
            <path d="M82.6478 51.0332H79.7549V53.9261H82.6478V51.0332Z" fill="white" />
            <path d="M82.6478 56.8188H79.7549V59.7118H82.6478V56.8188Z" fill="white" />
            <path d="M82.6478 59.7119H79.7549V62.6048H82.6478V59.7119Z" fill="white" />
            <path d="M82.6478 65.4971H79.7549V68.39H82.6478V65.4971Z" fill="white" />
            <path d="M82.6478 68.3901H79.7549V71.283H82.6478V68.3901Z" fill="white" />
            <path d="M82.6478 79.9619H79.7549V82.8548H82.6478V79.9619Z" fill="white" />
            <path d="M85.5404 27.8896H82.6475V30.7826H85.5404V27.8896Z" fill="white" />
            <path d="M85.5404 30.7827H82.6475V33.6756H85.5404V30.7827Z" fill="white" />
            <path d="M85.5404 36.5684H82.6475V39.4613H85.5404V36.5684Z" fill="white" />
            <path d="M85.5404 48.1401H82.6475V51.033H85.5404V48.1401Z" fill="white" />
            <path d="M85.5404 51.0332H82.6475V53.9261H85.5404V51.0332Z" fill="white" />
            <path d="M85.5404 59.7119H82.6475V62.6048H85.5404V59.7119Z" fill="white" />
            <path d="M85.5404 62.6045H82.6475V65.4974H85.5404V62.6045Z" fill="white" />
            <path d="M85.5404 65.4971H82.6475V68.39H85.5404V65.4971Z" fill="white" />
            <path d="M85.5404 71.2837H82.6475V74.1766H85.5404V71.2837Z" fill="white" />
            <path d="M85.5404 79.9619H82.6475V82.8548H85.5404V79.9619Z" fill="white" />
        </mask>
        <g mask="url(#mask1_14_1007)">
            <path d="M85.5408 1.85352H1.64648V85.7479H85.5408V1.85352Z" fill="#161345" />
        </g>
        <mask
            id="mask2_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="21"
            height="22"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.64648 1.85352V22.1039H21.8968V1.85352H1.64648ZM4.53939 4.74642H19.0039V19.211H4.53939V4.74642Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask2_14_1007)">
            <path d="M21.8968 1.85352H1.64648V22.1039H21.8968V1.85352Z" fill="#161345" />
        </g>
        <mask
            id="mask3_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="7"
            y="7"
            width="10"
            height="10"
        >
            <path d="M16.1109 7.63916H7.43213V16.3179H16.1109V7.63916Z" fill="white" />
        </mask>
        <g mask="url(#mask3_14_1007)">
            <path d="M16.1109 7.63916H7.43213V16.3179H16.1109V7.63916Z" fill="#161345" />
        </g>
        <mask
            id="mask4_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="65"
            y="1"
            width="21"
            height="22"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M85.541 1.85352L65.2907 1.85352V22.1039H85.541V1.85352ZM82.6481 4.74642V19.211H68.1836V4.74642L82.6481 4.74642Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask4_14_1007)">
            <path d="M85.5409 1.85352H65.2905V22.1039H85.5409V1.85352Z" fill="#161345" />
        </g>
        <mask
            id="mask5_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="71"
            y="7"
            width="9"
            height="10"
        >
            <path d="M79.7549 16.3184V7.63965L71.0762 7.63965V16.3184H79.7549Z" fill="white" />
        </mask>
        <g mask="url(#mask5_14_1007)">
            <path d="M79.7549 7.63965H71.0762V16.3184H79.7549V7.63965Z" fill="#161345" />
        </g>
        <mask
            id="mask6_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="1"
            y="65"
            width="21"
            height="21"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.64648 85.748H21.8968V65.4977H1.64648L1.64648 85.748ZM4.53939 82.8551L4.53939 68.3906H19.0039V82.8551H4.53939Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask6_14_1007)">
            <path d="M21.8968 65.4976H1.64648V85.7479H21.8968V65.4976Z" fill="#161345" />
        </g>
        <mask
            id="mask7_14_1007"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="7"
            y="71"
            width="10"
            height="9"
        >
            <path d="M7.43262 71.2832L7.43262 79.9619H16.1113V71.2832H7.43262Z" fill="white" />
        </mask>
        <g mask="url(#mask7_14_1007)">
            <path d="M16.1113 71.2832H7.43262V79.9619H16.1113V71.2832Z" fill="#161345" />
        </g>
    </svg>
</template>
