﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import QuizProgress from '@/components/home-page/quiz/QuizProgress.vue';
import ZeroStage from '@/components/home-page/quiz/ZeroStage.vue';
import QuizTitle from '@/components/home-page/quiz/QuizTitle.vue';
import DogSelectSize from '@/components/home-page/quiz/DogSelectSize.vue';
import { ref, watch } from 'vue';
import DogSelectAge from '@/components/home-page/quiz/DogSelectAge.vue';
import QuizResult from '@/components/home-page/quiz/QuizResult.vue';
import CatSelectCategory from '@/components/home-page/quiz/CatSelectCategory.vue';

const data = [
    {
        type: 'dog',
        stages: [
            {
                title: 'Размер',
            },
            {
                title: 'Возраст',
            },
            {
                title: 'Результат',
                items: [],
            },
        ],
    },
    {
        type: 'cat',
        stages: [
            {
                title: 'У меня...',
                items: [],
            },
            {
                title: 'Результат',
                items: [],
            },
        ],
    },
];

interface Props {
    show: Boolean;
}

export type AnimalTypes = 'dog' | 'cat' | null;
export type CatCategories = 'Котенок' | 'Взрослый' | 'Стерилизованный';
export type DogSizes = 'Крупный' | 'Средний' | 'Маленький';
export type DogAges = 'До 1 года' | 'Старше 1 года';

interface CatData {
    category?: CatCategories;
}

interface DogData {
    size?: DogSizes;
    age?: DogAges;
}

export interface Data {
    type: AnimalTypes;
    data: CatData | DogData | {};
}

const props = defineProps<Props>();
const answers = ref<Data>({ type: null, data: {} });
const currentStage = ref(0);
const isNextEnable = ref(false);
const isResult = ref(false);
const emit = defineEmits(['close']);

watch(
    () => props.show,
    (value) => {
        document.documentElement.style.overflow = value ? 'hidden' : 'visible';
        if (isResult.value) {
            resetState();
        }
    },
);

function resetState() {
    answers.value = { type: null, data: {} };
    currentStage.value = 0;
    isNextEnable.value = false;
    isResult.value = false;
}

watch(currentStage, (value) => {
    if (value === 3 && answers.value.type === 'dog') {
        isResult.value = true;
        return;
    }

    if (value === 2 && answers.value.type === 'cat') {
        isResult.value = true;
        return;
    }

    isResult.value = false;
});

function getStagesCount() {
    if (!answers.value.type) return 0;

    return answers.value.type === 'cat' ? 2 : 3;
}

function getTitle(): string {
    return currentStage.value
        ? //@ts-ignore
          data.find((item) => item.type === answers.value.type).stages[currentStage.value - 1].title
        : 'У меня...';
}

function setType(type: AnimalTypes) {
    answers.value.type = type;
    isNextEnable.value = true;
}

function setDogSize(size: DogSizes) {
    //@ts-ignore
    answers.value.data.size = size;
    isNextEnable.value = true;
}

function setDogAge(age: DogAges) {
    //@ts-ignore
    answers.value.data.age = age;
    isNextEnable.value = true;
}

function setCatCategory(category: CatCategories) {
    //@ts-ignore
    answers.value.data.category = category;
    isNextEnable.value = true;
}

function nextStep() {
    currentStage.value++;
    isNextEnable.value = false;
}

function prevStep() {
    if (currentStage.value === 3) {
        //@ts-ignore
        answers.value.data.age = null;
    }
    if (currentStage.value === 2) {
        //@ts-ignore
        answers.value.data.category = null;
        //@ts-ignore
        answers.value.data.size = null;
    }
    // console.log(currentStage.value);
    currentStage.value--;
    isNextEnable.value = false;
}
</script>

<template>
    <Transition name="quiz">
        <div
            v-if="show"
            class="modal-mask"
            @click="$event.target === $event.currentTarget && emit('close')"
        >
            <div
                class="modal-container md:px-7.5 px-5 md:pt-12 pt-8 md:rounded-[65px] rounded-20"
                :class="isResult ? 'md:pb-24 pb-10' : 'pb-10'"
            >
                <QuizTitle :title="getTitle()" />
                <div
                    class="md:px-7.5 px-5 grow flex flex-col justify-end md:h-auto h-[calc(100%_-_180px)]"
                    v-if="!isResult"
                >
                    <ZeroStage v-if="!currentStage" @set-type="setType" />
                    <template v-if="answers.type === 'dog'">
                        <DogSelectSize v-if="currentStage === 1" @set-size="setDogSize" />
                        <DogSelectAge
                            v-if="currentStage === 2"
                            :size="
                                //@ts-ignore
                                answers.data.size
                            "
                            @set-age="setDogAge"
                        />
                    </template>
                    <template v-if="answers.type === 'cat'">
                        <CatSelectCategory
                            v-if="currentStage === 1"
                            @set-category="setCatCategory"
                        />
                    </template>
                </div>
                <QuizProgress
                    :stage="currentStage"
                    :stages-count="getStagesCount()"
                    :type="answers.type"
                    :category="answers.data.category"
                    :dogSize="answers.data.size"
                    :dogAge="answers.data.age"
                />
                <QuizResult v-if="isResult" :answers="answers" @go-to-catalog="emit('close')" />
                <div class="flex items-center justify-center gap-5 md:mt-7.5 mt-5" v-if="!isResult">
                    <button
                        class="block px-7.5 py-2 text-[#2C323D] bg-turquoise-600 rounded-10 font-medium disabled:bg-gray-300"
                        v-html="`Назад`"
                        @click="prevStep"
                        :disabled="!currentStage"
                    />
                    <button
                        class="block px-7.5 py-2 text-[#2C323D] bg-turquoise-600 rounded-10 font-medium disabled:bg-gray-300"
                        :disabled="!isNextEnable"
                        v-if="!isResult"
                        v-html="`Далее`"
                        @click="nextStep"
                    />
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped lang="scss">
.modal {
    &-mask {
        padding: 20px;
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        transition: opacity 0.3s ease;
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 450px;
        height: 60vh;
        width: 100%;
        margin: auto;
        background-color: #fff;
        box-shadow: 0 9px 10px rgba(#9cabab, 0.5);
        transition: all 0.3s ease;

        @media (min-width: 767px) {
            height: 620px;
            max-width: 730px;
        }
    }
}

.quiz {
    &-enter-from {
        opacity: 0;
    }

    &-leave-to {
        opacity: 0;
    }

    &-enter-from .modal-container,
    &-leave-to .modal-container {
        transform: scale(1.1);
    }
}
</style>
