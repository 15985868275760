<script setup lang="ts">
import * as yup from 'yup';
import { ref } from 'vue';

const schema = yup.object().shape({
    email: yup.string().email('Введите корректный email').required('Введите email'),
    isAgree: yup
        .boolean()
        .oneOf(
            [true],
            'Чтобы продолжить, вам необходимо принять наши Условия обслуживания и Политику конфиденциальности.',
        )
        .default(false),
});

interface FormData {
    email: string;
    isAgree: boolean;
}

const formData = ref<FormData>({
    email: '',
    isAgree: false,
});

const errors = ref({
    email: null,
    isAgree: null,
});

const loading = ref(false);
const successMessage = ref('');

async function validate() {
    try {
        await schema.validate(
            {
                email: formData.value.email,
                isAgree: formData.value.isAgree,
            },
            { abortEarly: false },
        );
    } catch (e) {
        //@ts-ignore
        const errorList = e.inner;

        console.log(errorList);

        if (!errorList.length) {
            errors.value = {
                email: null,
                isAgree: null,
            };
        } else {
            //@ts-ignore
            errors.value.email = errorList.find((error) => error?.path === 'email')?.errors[0];
            //@ts-ignore
            errors.value.isAgree = errorList.find((error) => error?.path === 'isAgree')?.errors[0];
        }
    }
}

function handleInput() {
    errors.value.email = null;
}

function handleCheckbox() {
    formData.value.isAgree = !formData.value.isAgree;
    errors.value.isAgree = null;
}

function resetForm() {
    formData.value = {
        email: '',
        isAgree: false,
    };
}

async function submitHandler() {
    await validate();

    if (errors.value.email || errors.value.isAgree) {
        return;
    }

    loading.value = true;

    fetch(`${import.meta.env.VITE_API_URL}/forms/subscribe?` + new URLSearchParams(formData.value))
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            loading.value = false;
            successMessage.value = 'Данные успешно отправлены!';
            resetForm();
            setTimeout(() => {
                successMessage.value = '';
            }, 2000);
        });

    /*
    setTimeout(() => {
        loading.value = false;
        successMessage.value = 'Данные успешно отправлены!';
        resetForm();

        setTimeout(() => {
            successMessage.value = '';
        }, 2000);
    }, 3000);
    */


}
</script>

<template>
    <form class="w-full max-w-[532px] md:mx-0 mx-auto" @submit.prevent="submitHandler">
        <div class="md:flex items-stretch gap-x-[15px] w-full">
            <input
                type="text"
                class="border rounded-40 block md:w-full w-[260px] md:mx-0 mx-auto md:mb-0 mb-4 outline-none placeholder:text-blue px-7.5 xl:py-5 md:py-3 py-5"
                :class="{
                    'border-blue': !errors.email,
                    'border-red-500': errors.email,
                }"
                placeholder="Ваш email"
                v-model.trim="formData.email"
                @input="handleInput"
                name="email"
            />
            <button
                type="submit"
                class="bg-blue text-white border-none outline-none rounded-80 lg:px-7.5 md:px-3 px-7.5 md:py-0 py-5 hover:underline md:mx-0 mx-auto block"
                v-text="'Подписаться'"
                :disabled="loading"
            />
        </div>
        <div class="grid gap-2 mt-2" v-if="errors.email || errors.isAgree">
            <p v-if="errors.email" v-html="errors.email" class="text-14 leading-1.2 text-red-700" />
            <p
                v-if="errors.isAgree"
                v-html="errors.isAgree"
                class="text-14 leading-1.2 text-red-700"
            />
        </div>
        <p class="mt-3 text-center" v-text="'Отправка данных...'" v-if="loading" />
        <p class="mt-3 text-center text-green-600" v-text="successMessage" v-if="successMessage" />
        <label
            for="is-agree"
            class="md:pl-5 flex items-start cursor-pointer md:mt-6 mt-8"
            :class="{
                invalid: errors.isAgree,
            }"
        >
            <input
                type="checkbox"
                :checked="formData.isAgree"
                id="is-agree"
                hidden
                @change="handleCheckbox"
            />
            <span
                class="relative pl-5 text-[13px] select-none font-inter font-light"
                v-html="
                    'Нажимая кнопку отправить, вы даете свое согласие на обработку персональных данных'
                "
            />
        </label>
    </form>
</template>

<style scoped lang="scss">
label[for='is-agree'] {
    &.invalid {
        span {
            &:before {
                border: 1px solid red;
            }
        }
    }

    & span {
        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
        }

        &:before {
            top: 3px;
            left: 0;
            width: 12px;
            height: 12px;
            border: 1px solid var(--dark-blue-color);
            border-radius: 3px;
        }

        &:after {
            display: none;
            width: 8px;
            height: 8px;
            background-color: var(--dark-blue-color);
            top: 5px;
            left: 2px;
            border-radius: 2px;
        }
    }

    & input:checked {
        & + span {
            &:after {
                display: block;
            }
        }
    }
}
</style>
