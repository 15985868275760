﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import defaultCat from '@/components/home-page/quiz/images/cat.png';
import defaultDog from '@/components/home-page/quiz/images/dog.png';
import DogSilhouette from '@/components/home-page/quiz/DogSilhouette.vue';
import CatSilhouette from '@/components/home-page/quiz/CatSilhouette.vue';
import type { AnimalTypes } from '@/components/home-page/quiz/QuizTest.vue';
import { ref } from 'vue';
import CatBg from '@/components/home-page/quiz/CatBg.vue';
import DogBg from '@/components/home-page/quiz/DogBg.vue';

const type = ref<AnimalTypes>(null);
const emit = defineEmits(['setType']);

function selectType(selectedType: AnimalTypes) {
    type.value = selectedType;
    emit('setType', selectedType);
}
</script>

<template>
    <div class="grid grid-cols-2 gap-15 items-end">
        <div class="text-center cursor-pointer" @click="selectType('cat')">
            <div class="relative">
                <CatSilhouette
                    class="block w-fit mx-auto max-w-full h-auto"
                    :class="{
                        'opacity-0': type === 'cat',
                    }"
                />
                <img
                    :src="defaultCat"
                    alt="cat"
                    width="140"
                    class="h-auto absolute bottom-0 top-auto left-1/2 -translate-x-1/2 z-20"
                    :class="{
                        'opacity-0': type !== 'cat',
                    }"
                />
                <CatBg
                    v-if="type === 'cat'"
                    class="absolute rotate-[-15deg] top-auto bottom-0 left-1/2 -translate-x-1/2 z-10 h-auto w-[166px]"
                />
            </div>
            <p
                class="md:mt-7.5 mt-4 text-[#161345] text-24 font-gill leading-1.2"
                v-html="`Кошка`"
            />
        </div>
        <div class="text-center cursor-pointer" @click="selectType('dog')">
            <div class="relative">
                <DogSilhouette
                    class="block w-fit mx-auto max-w-full h-auto"
                    :class="{
                        'opacity-0': type === 'dog',
                    }"
                />
                <img
                    :src="defaultDog"
                    alt="cat"
                    width="187"
                    class="h-auto absolute bottom-0 top-auto left-1/2 -translate-x-1/2 z-20"
                    :class="{
                        'opacity-0': type !== 'dog',
                    }"
                />
                <DogBg
                    v-if="type === 'dog'"
                    class="absolute z-10 left-1/2 -translate-x-1/2 top-auto bottom-5 h-auto w-[200px]"
                />
            </div>
            <p
                class="md:mt-7.5 mt-4 text-[#161345] text-24 font-gill leading-1.2"
                v-html="`Собака`"
            />
        </div>
    </div>
</template>
