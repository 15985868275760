﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg width="73" height="59" viewBox="0 0 73 59" fill="none">
        <g clip-path="url(#clip0_1_2708)">
            <rect
                x="11.5645"
                y="14.5293"
                width="50.2905"
                height="30.3838"
                class="fill-blue-400 transition-all duration-300 group-hover:fill-white"
            />
            <path
                d="M70.6965 8.87304C69.8717 5.78913 67.4404 3.35787 64.3565 2.5331C58.7625 1.03418 36.336 1.03418 36.336 1.03418C36.336 1.03418 13.9095 1.03418 8.31543 2.5331C5.23152 3.35787 2.80025 5.78913 1.97549 8.87304C0.476562 14.4671 0.476562 29.7217 0.476562 29.7217C0.476562 29.7217 0.476562 44.9763 1.97549 50.5703C2.80025 53.6542 5.23152 56.0855 8.31543 56.9103C13.9095 58.4092 36.336 58.4092 36.336 58.4092C36.336 58.4092 58.7625 58.4092 64.3565 56.9103C67.444 56.0855 69.8717 53.6542 70.6965 50.5703C72.1954 44.9763 72.1954 29.7217 72.1954 29.7217C72.1954 29.7217 72.1954 14.4671 70.6965 8.87304ZM29.1641 42.1434V17.3L50.6797 29.7217L29.1641 42.1434Z"
                class="fill-white transition-all duration-300 group-hover:fill-blue-400"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_2708">
                <rect
                    width="72.5919"
                    height="57.6245"
                    fill="white"
                    transform="translate(0.0390625 0.90918)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
