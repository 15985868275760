﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import hugeDog from '@/components/home-page/quiz/images/dog.png';
import middleDog from '@/components/home-page/quiz/images/middle-dog.png';
import smallDog from '@/components/home-page/quiz/images/small-dog.png';
import hugeYoungDog from '@/components/home-page/quiz/images/huge-yong-dog.png';
import mediumYoungDog from '@/components/home-page/quiz/images/medium-yong-dog.png';
import smallYoungDog from '@/components/home-page/quiz/images/small-yong-dog.png';
import type { DogSizes } from '@/components/home-page/quiz/QuizTest.vue';
import DogBg from '@/components/home-page/quiz/DogBg.vue';

const data = [
    {
        size: 'Крупный',
        items: [
            {
                age: 'До 1 года',
                img: {
                    src: hugeYoungDog,
                    w: 151,
                },
            },
            {
                age: 'Старше 1 года',
                img: {
                    src: hugeDog,
                    w: 199,
                },
            },
        ],
    },
    {
        size: 'Средний',
        items: [
            {
                age: 'До 1 года',
                img: {
                    src: mediumYoungDog,
                    w: 162,
                },
            },
            {
                age: 'Старше 1 года',
                img: {
                    src: middleDog,
                    w: 184,
                },
            },
        ],
    },
    {
        size: 'Маленький',
        items: [
            {
                age: 'До 1 года',
                img: {
                    src: smallYoungDog,
                    w: 145,
                },
            },
            {
                age: 'Старше 1 года',
                img: {
                    src: smallDog,
                    w: 164,
                },
            },
        ],
    },
];

interface Props {
    size: DogSizes;
}

const props = defineProps<Props>();
const emit = defineEmits(['setAge']);

const items = ref();
const activeItem = ref();

function setAge(idx: number): void {
    activeItem.value = items.value[idx];
    emit('setAge', items.value[idx].age);
}

onBeforeMount(() => {
    //@ts-ignore
    items.value = data.find((item) => item.size === props.size).items;
    setAge(0);
});
</script>

<template>
    <div class="h-full flex flex-col justify-end">
        <div
            v-for="({ age, img }, idx) in items"
            :key="idx"
            class="md:h-auto h-[calc(100%_-_45px)] relative z-10"
            :class="{
                hidden: age !== activeItem.age,
            }"
        >
            <div class="relative h-full">
                <img
                    :src="img.src"
                    :width="img.w"
                    alt="img"
                    class="h-auto max-h-full md:w-[unset] w-auto absolute left-1/2 -translate-x-1/2 top-auto bottom-0 z-20"
                />
                <DogBg class="absolute z-10 left-1/2 -translate-x-1/2 top-auto bottom-9" />
                <img
                    :src="items[0].img.src"
                    :width="items[0].img.w"
                    alt="img"
                    class="h-auto opacity-0"
                />
            </div>
        </div>

        <div class="flex items-center justify-center gap-1.5 md:my-5 my-2.5 relative z-20">
            <div
                class="rounded-full w-20 h-3 cursor-pointer"
                :class="{
                    'bg-turquoise-300': age !== activeItem.age,
                    'bg-turquoise-600': age === activeItem.age,
                }"
                v-for="({ age }, idx) in items"
                :key="idx"
                @click="setAge(idx)"
            />
        </div>
        <p class="text-center font-gill leading-1.2" v-html="activeItem.age" />
    </div>
</template>
