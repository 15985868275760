﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import Flicking from '@egjs/vue3-flicking';
import { Arrow, Pagination } from '@egjs/flicking-plugins';
import '@egjs/vue3-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import ozonLogo from '@/assets/images/ozon-logo-2x.png';
import zoozavrLogo from '@/assets/images/zoozavr-logo-2x.png';
import sberLogo from '@/assets/images/sber-logo-2x.png';
import RatingStar from '@/components/icons/RatingStar.vue';
import dogImg from '@/assets/images/reviews-section-dog-2x.png';
import catImg from '@/assets/images/reviews-section-cat-2x.png';
import ReviewItem from '@/components/home-page/ReviewItem.vue';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const sliderData = [
    {
        value: 4.8,
        img: ozonLogo,
        text: 'Более 1100 отзывов',
        url: 'https://www.ozon.ru/category/tovary-dlya-zhivotnyh-12300/brit-18090538/review/',
    },

    {
        value: 4.7,
        img: zoozavrLogo,
        text: 'Более 850 отзывов',
        url: 'https://zoozavr.ru/catalog/index/name/sortforbrand/brand/18795/reviews/',
    },
    {
        value: 4.9,
        img: sberLogo,
        text: 'Более 1000 отзывов',
        url: 'https://megamarket.ru/review/korma-dlya-sobak/brand-brit/',
    },
];

const sliderOptions = {
    align: 'center',
    circular: true,
    panelsPerView: -1,
    defaultIndex: 2,
};

const plugins = [new Pagination({ type: 'bullet' }), new Arrow()];

function calculatedUnfilledStarWidth(rating: number, index: number): string {
    return `${
        rating - index > -1 ? (100 - Math.abs(rating - index) * 100).toFixed(0).toString() : '0'
    }%`;
}
</script>

<template>
    <section
        class="relative reviews-section bg-turquoise-200 md:pt-21.5 pt-12 overflow-hidden z-20"
    >
        <svg class="absolute -z-50 opacity-0">
            <defs>
                <clipPath id="reviews-mask-image" clipPathUnits="objectBoundingBox">
                    <path
                        d="M-0.0445792 0.20634L-0.119071 -0.0455912C-0.0672388 0.0861273 0.142246 0.201593 0.299107 0.0937562C0.4357 -0.000145909 0.513957 0.000149324 0.593537 0.0172517C0.607836 0.0203248 0.62595 0.0261544 0.646799 0.0328685C0.741979 0.063521 0.89437 0.112596 1.00309 0.00184255C1.15783 -0.155789 1.33004 -0.00763869 1.39743 0.0503313C1.40763 0.0591107 1.41543 0.065822 1.42041 0.0690892L1.1683 0.407634L1.36852 1.87959C1.30971 1.66927 1.07434 1.47954 0.899171 1.64518C0.74664 1.78943 0.658949 1.78639 0.569685 1.7567C0.553646 1.75136 0.533328 1.74154 0.509929 1.73022C0.403108 1.67855 0.232085 1.59583 0.110877 1.76768C-0.0616342 2.01226 -0.255423 1.77198 -0.331251 1.67796C-0.342735 1.66372 -0.351513 1.65284 -0.357102 1.6475L-0.111995 0.211106L-0.0445792 0.20634Z"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="reviews-mask-image-mob" clipPathUnits="objectBoundingBox">
                    <path
                        d="M-2.1423 0.423517L-2.49393 -0.104004C-2.24926 0.171802 -1.2604 0.413576 -0.519943 0.187776C0.124833 -0.00884609 0.494245 -0.00822791 0.869894 0.0275829C0.937392 0.0340177 1.0229 0.0462245 1.12131 0.0602831C1.57061 0.124467 2.28996 0.227225 2.80317 -0.00468243C3.53362 -0.33475 4.34653 -0.0245353 4.66462 0.0968487C4.71279 0.115232 4.74961 0.129285 4.77308 0.136126L3.58301 0.845009L4.52816 3.92715C4.25054 3.48677 3.13948 3.08947 2.31262 3.43633C1.59261 3.73836 1.17867 3.73201 0.757304 3.66983C0.681591 3.65866 0.585682 3.63808 0.475231 3.61438C-0.0290136 3.5062 -0.836321 3.33299 -1.40847 3.69282C-2.2228 4.20496 -3.13757 3.70182 -3.49551 3.50495C-3.54972 3.47514 -3.59116 3.45235 -3.61754 3.44117L-2.46053 0.433496L-2.1423 0.423517Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
        <figure
            class="opacity-30 absolute lg:top-5 md:top-4 top-0 left-auto lg:-right-18 md:-right-9.5 -right-17.5"
        >
            <img
                :src="dogImg"
                alt="img"
                width="479"
                height="718"
                class="lg:w-[479px] h-auto md:w-[250px] w-[220px]"
            />
        </figure>
        <figure
            class="opacity-30 absolute lg:top-17 md:top-10 top-2.5 lg:-left-25 md:-left-13 -left-18.5"
        >
            <img
                :src="catImg"
                alt="img"
                width="601"
                height="432"
                class="lg:w-[601px] h-auto md:w-[313px] w-[276px]"
            />
        </figure>
        <div class="masked bg-turquoise-300 md:pt-32.5 pt-13.5 md:pb-19 pb-16 relative z-20">
            <h2
                class="text-center xl:text-60 lg:text-48 md:text-32 text-24 font-gill font-bold lg:mb-18 md:mb-9.5 mb-8.5 px-5"
                v-html="`Реальные отзывы`"
            />
            <Flicking :options="sliderOptions" :plugins="plugins" v-if="isMobile">
                <div v-for="(review, idx) in sliderData" :key="idx">
                    <ReviewItem :review="review" />
                </div>
                <template #viewport>
                    <div
                        class="relative flex items-center md:justify-center justify-between md:gap-x-12 gap-x-5 md:mt-12 mt-8 px-5"
                    >
                        <span class="flicking-arrow-prev flicking-arrow is-thin">
                            <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                class="md:w-auto h-auto w-1.5"
                            >
                                <path
                                    d="M0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1L1 9H2L2 7Z"
                                />
                            </svg>
                        </span>
                        <div class="flicking-pagination"></div>
                        <span class="flicking-arrow-next flicking-arrow is-thin">
                            <svg
                                width="9"
                                height="16"
                                viewBox="0 0 9 16"
                                fill="none"
                                class="md:w-auto h-auto w-1.5"
                            >
                                <path
                                    d="M8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711L2.34315 15.0711C1.95262 15.4616 1.31946 15.4616 0.928932 15.0711C0.538408 14.6805 0.538408 14.0474 0.928932 13.6569L6.58579 8L0.928932 2.34315C0.538408 1.95262 0.538408 1.31946 0.928932 0.928932C1.31946 0.538408 1.95262 0.538408 2.34315 0.928932L8.70711 7.29289ZM7 7H8V9H7V7Z"
                                />
                            </svg>
                        </span>
                    </div>
                </template>
            </Flicking>
            <div class="flex gap-10 items-center justify-center" v-else>
                <ReviewItem :review="review" v-for="(review, idx) in sliderData" :key="idx" />
            </div>
        </div>
    </section>
</template>

<style scoped lang="scss">
.masked {
    clip-path: url(#reviews-mask-image);

    @media (max-width: 767px) {
        clip-path: url(#reviews-mask-image-mob);
    }
}

.reviews-section {
    .flicking-pagination,
    .flicking-arrow {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        transform: unset;
    }

    .flicking-pagination {
        width: fit-content;
    }

    .flicking-arrow {
        background-color: #fff;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        border: 1px solid #e0e0e0;
        transition: all ease 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
            width: 28px;
            height: 28px;
            border: 1px solid var(--turquoise-color-600);
        }

        &:before,
        &:after {
            content: none;
        }

        svg path {
            fill: var(--dark-blue-color);
            transition: all ease 0.3s;
        }

        &:hover {
            background-color: var(--dark-blue-color);

            svg path {
                fill: #fff;
            }
        }
    }

    .flicking-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 28px;

        @media (max-width: 767px) {
            column-gap: 18px;
        }
    }
}
</style>

<style lang="scss">
.reviews-section {
    .flicking-viewport {
        overflow: visible;
    }

    .flicking-pagination {
        & > span {
            margin: 0;
            padding: 0;
            background-color: #fff;
            width: 12px;
            height: 12px;

            @media (max-width: 767px) {
                width: 8px;
                height: 8px;
            }

            &.flicking-pagination-bullet-active {
                background-color: var(--dark-blue-color);
            }
        }
    }
}
</style>
