﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg width="215" height="180" viewBox="0 0 215 180" fill="none" class="max-w-full">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M107.164 0.892866C125.993 -0.492312 143.6 7.29936 160.257 16.1802C179.452 26.4148 201.947 35.6194 210.097 55.7746C218.394 76.2918 213.029 100.722 202.264 120.061C192.503 137.596 171.925 144.182 154.932 154.876C139.337 164.69 125.561 178.791 107.164 179.907C88.2265 181.056 71.6993 169.91 54.987 160.938C35.3958 150.421 8.28659 144.645 1.85687 123.374C-4.58144 102.074 14.1352 81.8754 25.2625 62.6021C34.124 47.2534 45.3032 34.2642 59.4633 23.5952C73.8777 12.7346 89.1586 2.21744 107.164 0.892866Z"
            fill="#C2E9E9"
        />
    </svg>
</template>
