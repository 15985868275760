﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="123" height="140" viewBox="0 0 123 140" fill="none">
<path d="M118.487 129.773C118.351 130.715 118.249 131.454 118.183 131.991C118.414 131.92 118.668 131.864 118.946 131.821C119.224 131.779 119.485 131.758 119.73 131.758C120.446 131.758 121.006 131.934 121.411 132.287C121.821 132.636 122.026 133.121 122.026 133.743C122.026 134.256 121.889 134.706 121.616 135.092C121.348 135.478 120.966 135.777 120.472 135.989C119.978 136.196 119.408 136.3 118.763 136.3H116.022L116.933 129.773H118.487ZM117.731 135.127H118.713C119.288 135.127 119.704 135.014 119.963 134.788C120.222 134.562 120.352 134.242 120.352 133.827C120.352 133.559 120.26 133.342 120.076 133.177C119.893 133.013 119.608 132.93 119.222 132.93C118.845 132.93 118.445 132.991 118.021 133.114L117.731 135.127Z" fill="#54565A"/>
<path d="M114.27 136.3H112.723L113.288 132.252C113.312 132.05 113.323 131.892 113.323 131.779C113.323 131.468 113.267 131.235 113.154 131.08C113.041 130.924 112.888 130.846 112.695 130.846C112.445 130.846 112.165 130.95 111.854 131.157C111.543 131.364 111.211 131.689 110.858 132.132L110.265 136.3H108.704L109.255 132.351C109.283 132.167 109.297 131.981 109.297 131.793C109.297 131.468 109.238 131.23 109.121 131.08C109.008 130.924 108.833 130.846 108.598 130.846C108.433 130.846 108.252 130.903 108.054 131.016C107.856 131.124 107.647 131.282 107.425 131.489C107.209 131.692 107.011 131.906 106.832 132.132L106.246 136.3H104.692L105.617 129.773H107.171L107.023 130.776L107.051 130.804C107.767 130.041 108.485 129.66 109.205 129.66C109.634 129.66 109.985 129.78 110.258 130.02C110.531 130.255 110.703 130.583 110.773 131.002C111.24 130.507 111.663 130.161 112.045 129.963C112.426 129.761 112.808 129.66 113.189 129.66C113.712 129.66 114.126 129.832 114.432 130.175C114.739 130.514 114.892 130.988 114.892 131.595C114.892 131.779 114.877 131.963 114.849 132.146L114.27 136.3Z" fill="#54565A"/>
<path d="M103.964 131.164C103.771 131.098 103.578 131.051 103.385 131.023C103.192 130.99 102.959 130.974 102.686 130.974C102.224 130.974 101.798 131.082 101.407 131.299C101.021 131.51 100.71 131.805 100.475 132.181C100.244 132.558 100.128 132.961 100.128 133.389C100.128 133.945 100.281 134.376 100.588 134.682C100.894 134.988 101.325 135.141 101.88 135.141C102.2 135.141 102.49 135.104 102.749 135.028C103.013 134.953 103.326 134.828 103.689 134.654L103.512 136.109C102.9 136.354 102.264 136.476 101.605 136.476C101.002 136.476 100.465 136.349 99.9942 136.095C99.528 135.84 99.1654 135.478 98.9064 135.007C98.6521 134.531 98.525 133.992 98.525 133.389C98.525 132.697 98.7063 132.059 99.0689 131.475C99.4362 130.891 99.9424 130.434 100.588 130.105C101.237 129.77 101.953 129.603 102.735 129.603C102.966 129.603 103.211 129.62 103.47 129.653C103.729 129.681 103.959 129.721 104.162 129.773L103.964 131.164Z" fill="#54565A"/>
<path d="M95.9609 132.492C95.9609 132.012 95.8502 131.621 95.6289 131.32C95.4075 131.014 95.1085 130.861 94.7318 130.861C94.3927 130.861 94.089 130.985 93.8205 131.235C93.5521 131.48 93.3402 131.819 93.1848 132.252C93.0294 132.681 92.9517 133.119 92.9517 133.566C92.9517 133.872 93.0059 134.15 93.1142 134.399C93.2225 134.644 93.3685 134.835 93.5521 134.972C93.7405 135.104 93.9453 135.169 94.1667 135.169C94.501 135.169 94.8048 135.052 95.0779 134.816C95.3557 134.581 95.5724 134.258 95.7278 133.849C95.8832 133.434 95.9609 132.982 95.9609 132.492ZM97.5643 132.57C97.5643 133.29 97.4183 133.952 97.1264 134.555C96.8344 135.153 96.4271 135.624 95.9044 135.968C95.3863 136.307 94.8165 136.476 94.1949 136.476C93.644 136.476 93.1518 136.349 92.7186 136.095C92.2854 135.84 91.951 135.483 91.7156 135.021C91.4801 134.555 91.3624 134.032 91.3624 133.453C91.3624 132.751 91.5131 132.101 91.8144 131.503C92.1205 130.905 92.5373 130.439 93.0647 130.105C93.5922 129.77 94.162 129.603 94.7742 129.603C95.3157 129.603 95.7984 129.726 96.2222 129.971C96.646 130.215 96.9757 130.566 97.2111 131.023C97.4466 131.475 97.5643 131.991 97.5643 132.57Z" fill="#54565A"/>
<path d="M90.3946 129.773L89.4834 136.3H87.9223L88.3179 133.552H85.7396L85.3511 136.3H83.8041L84.7154 129.773H86.2623L85.9162 132.294H88.4874L88.8476 129.773H90.3946Z" fill="#54565A"/>
<path d="M79.3399 129.773C79.2033 130.715 79.1021 131.454 79.0361 131.991C79.2669 131.92 79.5212 131.864 79.799 131.821C80.0769 131.779 80.3382 131.758 80.5831 131.758C81.2989 131.758 81.8593 131.934 82.2643 132.287C82.674 132.636 82.8788 133.121 82.8788 133.743C82.8788 134.256 82.7422 134.706 82.4691 135.092C82.2007 135.478 81.8193 135.777 81.3248 135.989C80.8303 136.196 80.2605 136.3 79.6154 136.3H76.8746L77.7859 129.773H79.3399ZM78.5841 135.127H79.5659C80.1404 135.127 80.5572 135.014 80.8162 134.788C81.0752 134.562 81.2047 134.242 81.2047 133.827C81.2047 133.559 81.1129 133.342 80.9292 133.177C80.7456 133.013 80.4607 132.93 80.0745 132.93C79.6978 132.93 79.2975 132.991 78.8737 133.114L78.5841 135.127Z" fill="#54565A"/>
<path d="M74.3176 136.3C74.1433 135.612 73.9738 134.932 73.809 134.258C73.6489 133.585 73.4793 132.904 73.3004 132.217C72.4669 133.648 71.6922 135.009 70.9764 136.3H69.2811C69.9404 135.174 70.5997 134.058 71.259 132.951C71.923 131.845 72.5893 130.729 73.258 129.603H74.2116L76.0199 136.3H74.3176Z" fill="#54565A"/>
<path d="M68.1086 136.3H66.5475L66.6464 135.622L66.6393 135.614L66.6181 135.607C66.0342 136.154 65.4361 136.427 64.8239 136.427C64.4142 136.427 64.0398 136.309 63.7008 136.074C63.3617 135.838 63.0957 135.516 62.9026 135.106C62.7095 134.691 62.613 134.237 62.613 133.743C62.613 132.914 62.8037 132.179 63.1851 131.539C63.5666 130.898 64.1011 130.416 64.7886 130.091C65.4761 129.766 66.2743 129.603 67.1832 129.603C67.8001 129.603 68.4123 129.674 69.0198 129.815L68.1086 136.3ZM67.3104 130.931C67.1691 130.917 67.0325 130.91 66.9007 130.91C66.5098 130.91 66.1543 130.969 65.834 131.087C65.5138 131.204 65.236 131.383 65.0005 131.623C64.7651 131.859 64.5743 132.163 64.4284 132.535C64.2871 132.902 64.2164 133.279 64.2164 133.665C64.2164 134.131 64.3177 134.505 64.5202 134.788C64.7274 135.071 65.0029 135.212 65.3466 135.212C65.568 135.212 65.8105 135.139 66.0742 134.993C66.3379 134.847 66.5875 134.651 66.823 134.407L67.3104 130.931Z" fill="#54565A"/>
<path d="M61.6452 129.773L60.734 136.3H59.1729L59.5685 133.552H56.9902L56.6017 136.3H55.0547L55.966 129.773H57.5129L57.1668 132.294H59.738L60.0983 129.773H61.6452Z" fill="#54565A"/>
<path d="M52.4906 132.492C52.4906 132.012 52.38 131.621 52.1586 131.32C51.9373 131.014 51.6383 130.861 51.2616 130.861C50.9225 130.861 50.6188 130.985 50.3503 131.235C50.0819 131.48 49.87 131.819 49.7146 132.252C49.5592 132.681 49.4815 133.119 49.4815 133.566C49.4815 133.872 49.5356 134.15 49.644 134.399C49.7523 134.644 49.8982 134.835 50.0819 134.972C50.2703 135.104 50.4751 135.169 50.6965 135.169C51.0308 135.169 51.3345 135.052 51.6077 134.816C51.8855 134.581 52.1021 134.258 52.2575 133.849C52.4129 133.434 52.4906 132.982 52.4906 132.492ZM54.0941 132.57C54.0941 133.29 53.9481 133.952 53.6562 134.555C53.3642 135.153 52.9568 135.624 52.4341 135.968C51.9161 136.307 51.3463 136.476 50.7247 136.476C50.1737 136.476 49.6816 136.349 49.2484 136.095C48.8151 135.84 48.4808 135.483 48.2453 135.021C48.0099 134.555 47.8922 134.032 47.8922 133.453C47.8922 132.751 48.0428 132.101 48.3442 131.503C48.6503 130.905 49.0671 130.439 49.5945 130.105C50.1219 129.77 50.6917 129.603 51.3039 129.603C51.8455 129.603 52.3282 129.726 52.752 129.971C53.1758 130.215 53.5055 130.566 53.7409 131.023C53.9764 131.475 54.0941 131.991 54.0941 132.57Z" fill="#54565A"/>
<path d="M46.9526 129.773L46.0273 136.3H44.4803L45.0454 132.464L41.2805 136.3H40.4399L41.3511 129.773H42.9193L42.3118 133.721L46.0132 129.773H46.9526Z" fill="#54565A"/>
<path d="M38.8718 129.773L38.1371 135.056H39.3521L38.9212 138.051H37.5014L37.7769 136.3H32.2813L33.1925 129.773H34.7395L33.9978 135.056H36.5761L37.3248 129.773H38.8718Z" fill="#54565A"/>
<path d="M27.4144 132.379H27.5133C27.7393 132.379 27.9277 132.349 28.0784 132.287C28.229 132.226 28.368 132.123 28.4951 131.977C28.627 131.826 28.82 131.522 29.0743 131.065C29.258 130.726 29.444 130.458 29.6324 130.26C29.8207 130.062 30.0185 129.921 30.2257 129.836C30.4376 129.747 30.7343 129.702 31.1158 129.702C31.3559 129.702 31.5537 129.716 31.7091 129.744L31.4478 130.903C31.2547 130.903 31.104 130.919 30.9957 130.952C30.8921 130.985 30.7979 131.039 30.7131 131.115C30.6331 131.19 30.5153 131.36 30.3599 131.623C30.1574 131.981 29.9408 132.266 29.7101 132.478C29.484 132.69 29.2086 132.836 28.8836 132.916C29.1332 133.01 29.3239 133.112 29.4558 133.22C29.5923 133.328 29.7195 133.467 29.8372 133.637C29.955 133.801 30.068 134.009 30.1763 134.258L31.0592 136.3H29.364L28.9189 135.141C28.7117 134.623 28.5375 134.256 28.3962 134.039C28.2597 133.818 28.109 133.662 27.9441 133.573C27.784 133.479 27.5768 133.432 27.3225 133.432H27.2519L26.8634 136.3H25.3023L26.2135 129.773H27.8029L27.4144 132.379Z" fill="#54565A"/>
<path d="M24.3346 129.773L23.4234 136.3H21.8623L22.2579 133.552H19.6796L19.2911 136.3H17.7441L18.6554 129.773H20.2023L19.8562 132.294H22.4274L22.7876 129.773H24.3346Z" fill="#54565A"/>
<path d="M17.4898 129.773L12.7712 139.627H11.1324L12.962 135.805L11.3232 129.773H12.8701L13.9297 133.954H13.9579L15.851 129.773H17.4898Z" fill="#54565A"/>
<path d="M5.58744 126.304H7.21916L7.04257 127.435C7.79132 127.435 8.45296 127.571 9.02748 127.844C9.60199 128.117 10.0447 128.511 10.3555 129.024C10.6663 129.533 10.8217 130.126 10.8217 130.804C10.8217 131.623 10.605 132.37 10.1718 133.043C9.74327 133.717 9.15462 134.242 8.40587 134.618C7.66182 134.995 6.84714 135.184 5.96182 135.184L5.7711 136.427H4.1535L4.33716 135.184C3.5884 135.184 2.92677 135.049 2.35225 134.781C1.78244 134.508 1.34214 134.115 1.03133 133.601C0.720527 133.088 0.565125 132.492 0.565125 131.814C0.565125 130.976 0.781746 130.222 1.21499 129.554C1.65294 128.88 2.24158 128.36 2.98092 127.993C3.72026 127.621 4.53023 127.435 5.41085 127.435L5.58744 126.304ZM6.14547 133.87C6.65877 133.87 7.14617 133.733 7.60767 133.46C8.06916 133.187 8.43412 132.815 8.70255 132.344C8.97568 131.868 9.11224 131.36 9.11224 130.818C9.11224 130.149 8.91446 129.641 8.51889 129.292C8.12332 128.939 7.56528 128.763 6.84478 128.763L6.14547 133.87ZM5.23425 128.763C4.71624 128.763 4.22649 128.899 3.765 129.172C3.3035 129.441 2.93854 129.81 2.67012 130.281C2.4064 130.748 2.27455 131.254 2.27455 131.8C2.27455 132.45 2.46291 132.958 2.83965 133.326C3.21638 133.688 3.77441 133.87 4.51375 133.87L5.23425 128.763Z" fill="#54565A"/>
<path d="M114.412 54.206C114.412 83.0386 91.0386 106.412 62.206 106.412C33.3734 106.412 10 83.0386 10 54.206C10 25.3734 33.3734 2 62.206 2C91.0386 2 114.412 25.3734 114.412 54.206Z" fill="white"/>
<path d="M61.985 104.823C33.9553 104.823 11.1571 82.0248 11.1571 53.9951C11.1571 25.9654 33.9553 3.15714 61.985 3.15714C90.0147 3.15714 112.813 25.9553 112.813 53.985C112.813 82.0147 90.0147 104.813 61.985 104.813M61.985 0C32.2148 0 8 24.2148 8 53.985C8 83.7552 32.2148 107.97 61.985 107.97C91.7552 107.97 115.97 83.7552 115.97 53.985C115.97 24.2148 91.7552 0 61.985 0Z" fill="#54565A"/>
<path d="M62.9463 92.0123C62.8451 92.0123 62.7034 92.0123 62.582 91.9921L62.3492 91.9718C53.3939 91.3444 45.663 86.7605 40.5731 79.0701C30.7779 64.2862 28.9261 48.0046 28.5922 41.7005C28.5213 40.2737 29.088 38.9076 30.1606 37.9666C31.2332 37.0255 32.6398 36.641 34.0463 36.9041C40.5124 38.1082 52.5338 37.2684 60.7606 18.1535C61.1248 17.3035 61.9647 16.747 62.8957 16.747C63.8266 16.747 64.7272 17.3035 65.0915 18.1535C73.3081 37.2684 85.3599 38.0981 91.8057 36.9041C93.2224 36.641 94.6289 37.0255 95.6914 37.9666C96.7641 38.9076 97.3408 40.2636 97.2599 41.7005C96.9158 48.0148 95.0742 64.2862 85.279 79.0701C80.1891 86.7605 72.448 91.3343 63.5028 91.9718L63.2701 91.9921C63.1891 91.9921 63.0475 92.0123 62.9665 92.0123H62.9463ZM33.1761 41.488C33.5404 47.3874 35.2707 62.6772 44.4386 76.5099C48.7796 83.057 55.0838 86.801 62.6629 87.3373C62.754 87.3373 62.835 87.3474 62.9058 87.3575C62.9766 87.3575 63.0576 87.3474 63.1486 87.3373C70.7278 86.801 77.032 83.057 81.373 76.5099C90.5409 62.6772 92.2712 47.3874 92.5849 41.4576C86.7766 42.5809 72.7617 42.8743 62.8957 24.397C53.0397 42.8743 39.0249 42.5707 33.166 41.488H33.1761Z" fill="#54565A"/>
<path d="M68.0666 75.1439H57.7755C55.8833 75.1439 54.3351 73.6058 54.3351 71.7034V68.1112H50.7327C48.8404 68.1112 47.2922 66.5731 47.2922 64.6707V54.3796C47.2922 52.4874 48.8303 50.9392 50.7327 50.9392H54.3351V47.3368C54.3351 45.4445 55.8732 43.8963 57.7755 43.8963H68.0666C69.9589 43.8963 71.5071 45.4344 71.5071 47.3368V50.9392H75.0993C76.9916 50.9392 78.5398 52.4773 78.5398 54.3796V64.6707C78.5398 66.5629 77.0017 68.1112 75.0993 68.1112H71.5071V71.7034C71.5071 73.5957 69.969 75.1439 68.0666 75.1439ZM58.9898 70.4891H66.8422V66.8969C66.8422 65.0046 68.3803 63.4564 70.2827 63.4564H73.8749V55.5939H70.2827C68.3904 55.5939 66.8422 54.0558 66.8422 52.1535V48.5511H58.9898V52.1535C58.9898 54.0457 57.4517 55.5939 55.5494 55.5939H51.947V63.4564H55.5494C57.4416 63.4564 58.9898 64.9945 58.9898 66.8969V70.4891Z" fill="#54565A"/>
</svg>


</template>
