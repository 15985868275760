﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>

<svg xmlns="http://www.w3.org/2000/svg" width="137" height="141" viewBox="0 0 137 141" fill="none">
<path d="M119.412 55.587C119.412 84.4195 96.0386 107.793 67.206 107.793C38.3734 107.793 15 84.4195 15 55.587C15 26.7544 38.3734 3.38098 67.206 3.38098C96.0386 3.38098 119.412 26.7544 119.412 55.587Z" fill="white"/>
<path d="M67.6306 105.742C39.5493 105.742 16.6908 82.8832 16.6908 54.8019C16.6908 26.7206 39.5259 3.86213 67.6306 3.86213C95.7353 3.86213 118.57 26.7206 118.57 54.8019C118.57 82.8832 95.7119 105.742 67.6306 105.742ZM67.6306 0.700348C37.7928 0.700348 13.5291 24.9641 13.5291 54.8019C13.5291 84.6398 37.7928 108.904 67.6306 108.904C97.4685 108.904 121.732 84.6398 121.732 54.8019C121.732 24.9641 97.445 0.700348 67.6306 0.700348Z" fill="#54565A"/>
<path d="M93.2057 69.4163C88.6387 69.4163 84.7977 66.2545 83.7204 62.0154H102.668C101.614 66.2545 97.7728 69.4163 93.2057 69.4163ZM93.2057 40.6792L99.8572 57.2844H86.5309L93.1823 40.6792H93.2057ZM66.4126 76.1614V26.5097C66.4126 26.5097 66.4126 26.3458 66.4126 26.2521C66.4126 26.2521 66.4126 26.2521 66.4126 26.2287V22.9732H68.8015V76.1614H66.4126ZM90.4655 85.436V86.6304H44.7719V85.436C44.7719 82.9299 46.8095 80.8924 49.3155 80.8924H85.9688C88.4748 80.8924 90.4889 82.9299 90.4889 85.436M35.3803 57.3078L42.0317 40.7026L48.6832 57.3078H35.3569H35.3803ZM42.0317 69.4397C37.4647 69.4397 33.6237 66.2779 32.5464 62.0388H51.4936C50.4397 66.2779 46.5987 69.4397 42.0317 69.4397ZM104.986 57.3078L95.5244 33.6999C95.4307 33.0675 95.0794 32.4586 94.5173 32.0838C88.264 27.8681 81.0504 25.1982 73.5793 24.2848V20.6312C73.5793 19.3196 72.5253 18.2657 71.2138 18.2657H64.0705C62.759 18.2657 61.705 19.3196 61.705 20.6312V24.3316C54.3041 25.2919 47.1843 27.9852 41.0012 32.2244C40.486 32.482 40.0878 32.9036 39.8536 33.4656L30.298 57.3312H27.5578V59.6967C27.5578 67.6831 34.0687 74.1941 42.0552 74.1941C50.0416 74.1941 56.5525 67.6831 56.5525 59.6967V57.3312H53.8123L44.9827 35.2925C50.065 32.0838 55.7796 29.976 61.705 29.1328V76.2082H49.3155C44.2098 76.2082 40.041 80.3771 40.041 85.4828V89.0427C40.041 90.3543 41.0949 91.4082 42.4065 91.4082H92.8778C94.1894 91.4082 95.2433 90.3543 95.2433 89.0427V85.4828C95.2433 80.3771 91.0745 76.2082 85.9688 76.2082H73.5793V29.0392C79.5281 29.8355 85.2661 31.873 90.3953 35.0348L81.472 57.2844H78.7318V59.6499C78.7318 67.6363 85.2427 74.1472 93.2291 74.1472C101.216 74.1472 107.727 67.6363 107.727 59.6499V57.2844H104.986V57.3078Z" fill="#54565A"/>
<path d="M132.846 130.82C132.707 131.776 132.605 132.526 132.538 133.071C132.772 132.999 133.03 132.942 133.312 132.899C133.594 132.856 133.859 132.834 134.108 132.834C134.834 132.834 135.403 133.013 135.814 133.372C136.23 133.726 136.438 134.218 136.438 134.849C136.438 135.37 136.299 135.826 136.022 136.218C135.749 136.61 135.362 136.913 134.86 137.128C134.359 137.339 133.78 137.444 133.126 137.444H130.344L131.269 130.82H132.846ZM132.079 136.254H133.075C133.658 136.254 134.081 136.139 134.344 135.91C134.607 135.68 134.739 135.355 134.739 134.935C134.739 134.662 134.645 134.442 134.459 134.275C134.273 134.108 133.983 134.024 133.592 134.024C133.209 134.024 132.803 134.086 132.373 134.211L132.079 136.254Z" fill="#33363C"/>
<path d="M128.566 137.444H126.996L127.57 133.336C127.593 133.131 127.605 132.97 127.605 132.856C127.605 132.54 127.548 132.304 127.433 132.146C127.319 131.988 127.163 131.909 126.967 131.909C126.714 131.909 126.43 132.015 126.114 132.225C125.799 132.435 125.462 132.765 125.103 133.214L124.501 137.444H122.917L123.476 133.436C123.505 133.25 123.519 133.061 123.519 132.87C123.519 132.54 123.459 132.299 123.34 132.146C123.225 131.988 123.048 131.909 122.809 131.909C122.642 131.909 122.458 131.967 122.257 132.081C122.057 132.191 121.844 132.351 121.619 132.562C121.4 132.767 121.199 132.985 121.017 133.214L120.422 137.444H118.845L119.784 130.82H121.361L121.211 131.838L121.239 131.866C121.966 131.092 122.695 130.705 123.426 130.705C123.861 130.705 124.217 130.827 124.494 131.071C124.771 131.31 124.946 131.642 125.017 132.067C125.491 131.565 125.921 131.214 126.308 131.013C126.695 130.808 127.082 130.705 127.469 130.705C128 130.705 128.42 130.879 128.731 131.228C129.042 131.572 129.197 132.053 129.197 132.669C129.197 132.856 129.183 133.042 129.154 133.229L128.566 137.444Z" fill="#33363C"/>
<path d="M118.107 132.232C117.911 132.165 117.715 132.117 117.519 132.089C117.323 132.055 117.086 132.038 116.809 132.038C116.341 132.038 115.908 132.148 115.511 132.368C115.12 132.583 114.804 132.882 114.565 133.264C114.331 133.647 114.214 134.055 114.214 134.49C114.214 135.054 114.369 135.492 114.68 135.802C114.991 136.113 115.428 136.268 115.992 136.268C116.317 136.268 116.611 136.23 116.874 136.153C117.141 136.077 117.459 135.95 117.827 135.773L117.648 137.25C117.027 137.499 116.381 137.623 115.712 137.623C115.1 137.623 114.556 137.494 114.078 137.236C113.605 136.978 113.237 136.61 112.974 136.132C112.716 135.649 112.587 135.102 112.587 134.49C112.587 133.788 112.771 133.14 113.139 132.547C113.511 131.955 114.025 131.491 114.68 131.157C115.339 130.817 116.066 130.648 116.859 130.648C117.093 130.648 117.342 130.664 117.605 130.698C117.868 130.727 118.102 130.767 118.307 130.82L118.107 132.232Z" fill="#33363C"/>
<path d="M109.984 133.58C109.984 133.092 109.872 132.696 109.647 132.39C109.423 132.079 109.119 131.924 108.737 131.924C108.393 131.924 108.084 132.05 107.812 132.304C107.54 132.552 107.325 132.896 107.167 133.336C107.009 133.771 106.93 134.215 106.93 134.669C106.93 134.98 106.985 135.262 107.095 135.515C107.205 135.764 107.353 135.957 107.54 136.096C107.731 136.23 107.939 136.297 108.163 136.297C108.503 136.297 108.811 136.177 109.088 135.938C109.37 135.699 109.59 135.372 109.748 134.956C109.905 134.536 109.984 134.077 109.984 133.58ZM111.612 133.659C111.612 134.39 111.463 135.061 111.167 135.673C110.871 136.28 110.457 136.758 109.927 137.107C109.401 137.451 108.823 137.623 108.192 137.623C107.633 137.623 107.133 137.494 106.694 137.236C106.254 136.978 105.915 136.615 105.676 136.146C105.437 135.673 105.317 135.143 105.317 134.555C105.317 133.843 105.47 133.183 105.776 132.576C106.087 131.969 106.51 131.496 107.045 131.157C107.58 130.817 108.159 130.648 108.78 130.648C109.329 130.648 109.819 130.772 110.249 131.02C110.68 131.269 111.014 131.625 111.253 132.089C111.492 132.547 111.612 133.071 111.612 133.659Z" fill="#33363C"/>
<path d="M104.335 130.82L103.41 137.444H101.826L102.227 134.655H99.6108L99.2165 137.444H97.6465L98.5713 130.82H100.141L99.79 133.379H102.399L102.765 130.82H104.335Z" fill="#33363C"/>
<path d="M96.6643 130.82L95.7395 137.444H94.1552L94.5567 134.655H91.94L91.5457 137.444H89.9757L90.9005 130.82H92.4705L92.1192 133.379H94.7287L95.0943 130.82H96.6643Z" fill="#33363C"/>
<path d="M88.0257 137.444H86.4414L86.5418 136.756L86.5346 136.748L86.5131 136.741C85.9205 137.296 85.3135 137.573 84.6922 137.573C84.2764 137.573 83.8964 137.453 83.5523 137.214C83.2082 136.975 82.9382 136.648 82.7422 136.232C82.5463 135.812 82.4483 135.351 82.4483 134.849C82.4483 134.008 82.6419 133.262 83.029 132.612C83.4161 131.962 83.9586 131.472 84.6563 131.142C85.3541 130.813 86.1642 130.648 87.0866 130.648C87.7127 130.648 88.334 130.719 88.9505 130.863L88.0257 137.444ZM87.2157 131.995C87.0723 131.981 86.9337 131.974 86.7999 131.974C86.4032 131.974 86.0423 132.034 85.7173 132.153C85.3924 132.273 85.1104 132.454 84.8714 132.698C84.6324 132.937 84.4389 133.245 84.2907 133.623C84.1473 133.996 84.0757 134.378 84.0757 134.77C84.0757 135.243 84.1784 135.623 84.3839 135.91C84.5942 136.196 84.8738 136.34 85.2227 136.34C85.4473 136.34 85.6934 136.266 85.9611 136.118C86.2287 135.969 86.482 135.771 86.721 135.523L87.2157 131.995Z" fill="#33363C"/>
<path d="M75.5446 137.444L76.4551 131.013C76.8326 130.889 77.2389 130.798 77.6738 130.741C78.1087 130.679 78.5006 130.648 78.8495 130.648C79.6715 130.648 80.2857 130.793 80.6919 131.085C81.0982 131.377 81.3013 131.778 81.3013 132.289C81.3013 132.71 81.1842 133.064 80.95 133.35C80.7206 133.637 80.398 133.833 79.9822 133.938C80.3837 134.029 80.6991 134.213 80.9285 134.49C81.1579 134.767 81.2726 135.107 81.2726 135.508C81.2726 135.9 81.1698 136.242 80.9643 136.533C80.7588 136.825 80.4673 137.05 80.0897 137.207C79.7122 137.365 79.2796 137.444 78.7922 137.444H75.5446ZM78.4265 133.494C78.8232 133.494 79.1291 133.42 79.3442 133.272C79.564 133.123 79.6739 132.894 79.6739 132.583C79.6739 132.077 79.3609 131.823 78.7348 131.823C78.4194 131.823 78.135 131.862 77.8817 131.938L77.6451 133.494H78.4265ZM77.5304 134.476L77.2795 136.282H78.5412C78.8949 136.282 79.1673 136.196 79.3585 136.024C79.5497 135.848 79.6453 135.616 79.6453 135.329C79.6453 135.042 79.5521 134.83 79.3657 134.691C79.1841 134.548 78.9092 134.476 78.5412 134.476H77.5304Z" fill="#33363C"/>
<path d="M73.0068 133.58C73.0068 133.092 72.8944 132.696 72.6698 132.39C72.4452 132.079 72.1417 131.924 71.7594 131.924C71.4153 131.924 71.107 132.05 70.8346 132.304C70.5622 132.552 70.3471 132.896 70.1894 133.336C70.0317 133.771 69.9528 134.215 69.9528 134.669C69.9528 134.98 70.0078 135.262 70.1177 135.515C70.2276 135.764 70.3758 135.957 70.5622 136.096C70.7533 136.23 70.9612 136.297 71.1859 136.297C71.5252 136.297 71.8334 136.177 72.1106 135.938C72.3926 135.699 72.6125 135.372 72.7702 134.956C72.9279 134.536 73.0068 134.077 73.0068 133.58ZM74.6341 133.659C74.6341 134.39 74.486 135.061 74.1896 135.673C73.8933 136.28 73.4799 136.758 72.9494 137.107C72.4237 137.451 71.8454 137.623 71.2145 137.623C70.6554 137.623 70.1559 137.494 69.7162 137.236C69.2765 136.978 68.9372 136.615 68.6982 136.146C68.4593 135.673 68.3398 135.143 68.3398 134.555C68.3398 133.843 68.4927 133.183 68.7986 132.576C69.1093 131.969 69.5322 131.496 70.0675 131.157C70.6028 130.817 71.1811 130.648 71.8024 130.648C72.352 130.648 72.8419 130.772 73.272 131.02C73.7022 131.269 74.0367 131.625 74.2757 132.089C74.5146 132.547 74.6341 133.071 74.6341 133.659Z" fill="#33363C"/>
<path d="M65.7518 133.673C65.7518 133.109 65.606 132.66 65.3145 132.325C65.023 131.991 64.6311 131.823 64.1388 131.823C63.962 131.823 63.8066 131.838 63.6728 131.866C63.539 131.89 63.3908 131.943 63.2283 132.024L62.6405 136.254C62.6787 136.268 62.76 136.282 62.8842 136.297C63.0085 136.306 63.128 136.311 63.2427 136.311C63.735 136.311 64.1651 136.204 64.5331 135.989C64.9059 135.773 65.2022 135.456 65.422 135.035C65.6419 134.61 65.7518 134.156 65.7518 133.673ZM67.3648 133.644C67.3648 134.127 67.2764 134.605 67.0996 135.078C66.9227 135.551 66.6623 135.977 66.3182 136.354C65.9741 136.732 65.5487 137.038 65.0421 137.272C64.5403 137.506 63.9596 137.623 63.3 137.623C63.2618 137.623 63.1113 137.616 62.8484 137.602C62.5903 137.587 62.4565 137.573 62.4469 137.559L61.9953 140.82H60.4038L61.5365 132.834C61.3214 132.992 61.1422 133.15 60.9988 133.307C60.8602 133.46 60.7455 133.599 60.6547 133.723C60.5639 133.847 60.4898 133.948 60.4325 134.024C60.3751 134.101 60.3178 134.139 60.2604 134.139C60.2031 134.139 60.1744 134.101 60.1744 134.024C60.1744 133.876 60.2413 133.661 60.3751 133.379C60.5137 133.097 60.6953 132.81 60.9199 132.519C61.1494 132.227 61.405 131.969 61.687 131.745L62.024 129.307H63.6083L63.3861 130.82C63.7111 130.705 64.103 130.648 64.5618 130.648C65.0923 130.648 65.5726 130.774 66.0027 131.028C66.4329 131.281 66.7674 131.637 67.0064 132.096C67.2453 132.555 67.3648 133.071 67.3648 133.644Z" fill="#33363C"/>
<path d="M59.4862 130.82L58.547 137.444H56.977L57.5506 133.551L53.7295 137.444H52.8764L53.8012 130.82H55.3927L54.7762 134.827L58.5327 130.82H59.4862Z" fill="#33363C"/>
<path d="M52.1237 132.232C51.9277 132.165 51.7318 132.117 51.5358 132.089C51.3399 132.055 51.1033 132.038 50.8261 132.038C50.3577 132.038 49.9252 132.148 49.5285 132.368C49.1366 132.583 48.8212 132.882 48.5822 133.264C48.348 133.647 48.2309 134.055 48.2309 134.49C48.2309 135.054 48.3863 135.492 48.6969 135.802C49.0076 136.113 49.4449 136.268 50.0088 136.268C50.3338 136.268 50.6277 136.23 50.8906 136.153C51.1582 136.077 51.4761 135.95 51.8441 135.773L51.6649 137.25C51.0435 137.499 50.3983 137.623 49.7292 137.623C49.1175 137.623 48.5726 137.494 48.0947 137.236C47.6216 136.978 47.2536 136.61 46.9907 136.132C46.7326 135.649 46.6036 135.102 46.6036 134.49C46.6036 133.788 46.7876 133.14 47.1556 132.547C47.5284 131.955 48.0421 131.491 48.6969 131.157C49.3565 130.817 50.0829 130.648 50.8763 130.648C51.1105 130.648 51.359 130.664 51.6218 130.698C51.8847 130.727 52.1189 130.767 52.3244 130.82L52.1237 132.232Z" fill="#33363C"/>
<path d="M45.6214 130.82L44.6966 137.444H43.1123L43.5138 134.655H40.8971L40.5028 137.444H38.9328L39.8576 130.82H41.4276L41.0763 133.379H43.6858L44.0514 130.82H45.6214Z" fill="#33363C"/>
<path d="M36.9828 137.444H35.3985L35.4989 136.756L35.4917 136.748L35.4702 136.741C34.8776 137.296 34.2706 137.573 33.6493 137.573C33.2335 137.573 32.8535 137.453 32.5094 137.214C32.1653 136.975 31.8953 136.648 31.6993 136.232C31.5034 135.812 31.4054 135.351 31.4054 134.849C31.4054 134.008 31.599 133.262 31.9861 132.612C32.3732 131.962 32.9157 131.472 33.6134 131.142C34.3112 130.813 35.1213 130.648 36.0437 130.648C36.6698 130.648 37.2911 130.719 37.9076 130.863L36.9828 137.444ZM36.1727 131.995C36.0294 131.981 35.8908 131.974 35.7569 131.974C35.3603 131.974 34.9994 132.034 34.6744 132.153C34.3494 132.273 34.0675 132.454 33.8285 132.698C33.5895 132.937 33.396 133.245 33.2478 133.623C33.1044 133.996 33.0327 134.378 33.0327 134.77C33.0327 135.243 33.1355 135.623 33.341 135.91C33.5513 136.196 33.8309 136.34 34.1798 136.34C34.4044 136.34 34.6505 136.266 34.9182 136.118C35.1858 135.969 35.4391 135.771 35.6781 135.523L36.1727 131.995Z" fill="#33363C"/>
<path d="M28.7601 137.444C28.5832 136.746 28.4112 136.055 28.2439 135.372C28.0814 134.689 27.9094 133.998 27.7277 133.3C26.8818 134.753 26.0956 136.134 25.3692 137.444H23.6486C24.3177 136.302 24.9868 135.169 25.6559 134.046C26.3298 132.923 27.0061 131.79 27.6847 130.648H28.6525L30.4878 137.444H28.7601Z" fill="#33363C"/>
<path d="M22.4585 137.444H20.8742L20.9746 136.756L20.9674 136.748L20.9459 136.741C20.3533 137.296 19.7463 137.573 19.125 137.573C18.7092 137.573 18.3292 137.453 17.9851 137.214C17.641 136.975 17.371 136.648 17.175 136.232C16.9791 135.812 16.8811 135.351 16.8811 134.849C16.8811 134.008 17.0747 133.262 17.4618 132.612C17.8489 131.962 18.3914 131.472 19.0891 131.142C19.7869 130.813 20.597 130.648 21.5194 130.648C22.1455 130.648 22.7668 130.719 23.3833 130.863L22.4585 137.444ZM21.6485 131.995C21.5051 131.981 21.3665 131.974 21.2327 131.974C20.836 131.974 20.4751 132.034 20.1501 132.153C19.8252 132.273 19.5432 132.454 19.3042 132.698C19.0652 132.937 18.8717 133.245 18.7235 133.623C18.5801 133.996 18.5085 134.378 18.5085 134.77C18.5085 135.243 18.6112 135.623 18.8167 135.91C19.027 136.196 19.3066 136.34 19.6555 136.34C19.8801 136.34 20.1262 136.266 20.3939 136.118C20.6615 135.969 20.9148 135.771 21.1538 135.523L21.6485 131.995Z" fill="#33363C"/>
<path d="M11.2893 134.533C11.2893 135.116 11.3945 135.558 11.6048 135.86C11.8151 136.161 12.1162 136.311 12.5081 136.311C12.8474 136.311 13.1604 136.194 13.4472 135.96C13.7387 135.721 13.9681 135.391 14.1354 134.971C14.3027 134.545 14.3863 134.077 14.3863 133.565C14.3863 133.03 14.2836 132.624 14.0781 132.347C13.8725 132.065 13.5691 131.924 13.1676 131.924C12.8187 131.924 12.4985 132.038 12.207 132.268C11.9202 132.497 11.6956 132.815 11.5331 133.221C11.3706 133.628 11.2893 134.065 11.2893 134.533ZM11.3754 131.995C11.6095 131.608 11.9321 131.298 12.3432 131.063C12.7542 130.825 13.2106 130.705 13.7124 130.705C14.1712 130.705 14.5751 130.82 14.924 131.049C15.2777 131.279 15.5525 131.606 15.7484 132.031C15.9444 132.457 16.0423 132.935 16.0423 133.465C16.0423 134.23 15.8846 134.93 15.5692 135.566C15.2585 136.201 14.8236 136.701 14.2644 137.064C13.71 137.427 13.1007 137.609 12.4364 137.609C11.8628 137.609 11.3658 137.477 10.9452 137.214C10.5246 136.947 10.202 136.56 9.97742 136.053C9.75757 135.546 9.64764 134.932 9.64764 134.211C9.64764 132.089 10.1041 130.459 11.0169 129.321C11.9345 128.179 13.3014 127.515 15.1175 127.328L16.8381 127.149L16.4868 128.562L15.2538 128.705C14.4461 128.796 13.7889 128.963 13.2823 129.207C12.7805 129.446 12.379 129.783 12.0779 130.218C11.7768 130.648 11.5426 131.24 11.3754 131.995Z" fill="#33363C"/>
<path d="M8.77304 128.984C8.62966 128.946 8.40742 128.91 8.10633 128.877C7.81001 128.844 7.53281 128.827 7.27473 128.827C6.53394 128.827 5.86723 128.93 5.27459 129.135C4.68674 129.336 4.18013 129.632 3.75478 130.024C3.32942 130.411 2.99726 130.884 2.75829 131.443C2.51933 132.003 2.39985 132.583 2.39985 133.185C2.39985 133.759 2.51216 134.263 2.73679 134.698C2.96141 135.133 3.27924 135.468 3.69026 135.702C4.10128 135.931 4.5816 136.046 5.13121 136.046C5.62348 136.046 6.1492 135.943 6.70838 135.738C7.27234 135.527 7.84346 135.231 8.42176 134.849L8.15651 136.741C7.06205 137.296 5.99149 137.573 4.94482 137.573C4.10845 137.573 3.36049 137.391 2.70094 137.028C2.0414 136.665 1.53001 136.149 1.16679 135.48C0.803561 134.81 0.621948 134.055 0.621948 133.214C0.621948 132.067 0.901537 131.042 1.46071 130.139C2.02467 129.235 2.81804 128.538 3.8408 128.045C4.86835 127.548 6.03211 127.3 7.33208 127.3C7.59016 127.3 7.89604 127.317 8.24971 127.35C8.60815 127.383 8.88535 127.419 9.0813 127.458L8.77304 128.984Z" fill="#33363C"/>
</svg>

</template>
