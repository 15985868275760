﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <g clip-path="url(#clip0_14_989)">
            <path
                d="M12.4375 12.6445C14.0944 12.6445 15.4375 11.3014 15.4375 9.64453C15.4375 7.98768 14.0944 6.64453 12.4375 6.64453C10.7806 6.64453 9.4375 7.98768 9.4375 9.64453C9.4375 11.3014 10.7806 12.6445 12.4375 12.6445Z"
                fill="#FCFBF6"
            />
            <rect x="0.4375" y="3.64453" width="24" height="18" rx="1" fill="#161345" />
            <path
                d="M4.4375 7.64453L12.4375 13.6445L20.4375 7.64453"
                stroke="#FCFBF6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_14_989">
                <rect width="24" height="24" fill="white" transform="translate(0.4375 0.644531)" />
            </clipPath>
        </defs>
    </svg>
</template>
