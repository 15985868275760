﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
        <ellipse
            cx="31.9292"
            cy="31.6601"
            rx="31.4315"
            ry="31.4315"
            class="fill-white transition-all duration-300 group-hover:fill-blue-400"
        />
        <g clip-path="url(#clip0_1_2704)">
            <path
                d="M11.4749 29.2081L47.4618 17.7254C48.0023 17.5524 48.5179 18.0497 48.3685 18.599L42.0723 41.7345C41.9609 42.1432 41.5237 42.3668 41.1291 42.2166L28.4151 37.3871C27.9152 37.197 27.7913 36.5429 28.1876 36.1825L38.1612 27.1063C38.7743 26.5487 38.1126 25.5647 37.3683 25.9285L20.6722 34.0842C20.4894 34.1736 20.2778 34.1803 20.0897 34.1032L11.4216 30.5581C10.8021 30.3042 10.8372 29.4114 11.475 29.2083L11.4749 29.2081ZM31.4794 31.1643C31.7925 30.8912 31.4449 30.3991 31.0842 30.6051L23.1277 34.9691C22.4102 35.3793 22.0732 36.2353 22.3172 37.0278L24.7749 45.338C24.8998 45.7589 25.426 45.8947 25.7372 45.5864L30.6426 40.923C30.8429 40.7241 30.7792 40.3844 30.5201 40.2736L25.4301 37.7257C25.1505 37.5855 25.0955 37.2095 25.3222 36.9946L31.4789 31.1642L31.4794 31.1643Z"
                class="fill-blue-400 transition-all duration-300 group-hover:fill-white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_2704">
                <rect
                    width="37.4185"
                    height="37.4185"
                    fill="white"
                    transform="translate(10.9762 11.4541)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
