﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import * as yup from 'yup';
import { ref } from 'vue';
import { formatGeneral } from 'cleave-zen';

const phoneRegExp = /^\+7\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$/;

const schema = yup.object().shape({
    name: yup.string().required('Введите имя'),
    phone: yup
        .string()
        .matches(phoneRegExp, 'Введите корректный номер телефона')
        .required('Введите имя'),
    isAgree: yup
        .boolean()
        .oneOf(
            [true],
            'Чтобы продолжить, вам необходимо принять наши Условия обслуживания и Политику конфиденциальности.',
        )
        .default(false),
});

interface FormData {
    form: string;
    name: string;
    phone: string;
    isAgree: boolean;
}

const formData = ref<FormData>({
    form: 'Заявка на звонок с сайта Брит',
    name: '',
    phone: '',
    isAgree: false,
});

const errors = ref({
    name: null,
    phone: null,
    isAgree: null,
});

const loading = ref(false);
const successMessage = ref('');

async function validate() {
    try {
        await schema.validate(
            {
                name: formData.value.name,
                phone: formData.value.phone,
                isAgree: formData.value.isAgree,
            },
            { abortEarly: false },
        );
    } catch (e) {
        //@ts-ignore
        const errorList = e.inner;

        console.log(errorList);

        if (!errorList.length) {
            errors.value = {
                name: null,
                phone: null,
                isAgree: null,
            };
        } else {
            //@ts-ignore
            errors.value.name = errorList.find((error) => error?.path === 'name')?.errors[0];
            //@ts-ignore
            errors.value.phone = errorList.find((error) => error?.path === 'phone')?.errors[0];
            //@ts-ignore
            errors.value.isAgree = errorList.find((error) => error?.path === 'isAgree')?.errors[0];
        }
    }
}

function handleNameInput() {
    errors.value.name = null;
}

function handlePhoneInput() {
    formData.value.phone = formatGeneral(formData.value.phone, {
        prefix: '+7',
        delimiters: [' (', ') ', ' '],
        blocks: [2, 3, 3, 2, 2],
        uppercase: true,
    });
    errors.value.phone = null;
}

function handleCheckbox() {
    formData.value.isAgree = !formData.value.isAgree;
    errors.value.isAgree = null;
}

function resetForm() {
    formData.value.name = '';
    formData.value.phone = '';
    formData.value.isAgree = false;
    /*
    formData.value = {
        name: '',
        phone: '',
        isAgree: false,
    };
    */
}

async function submitHandler() {
    await validate();

    if (errors.value.name || errors.value.phone || errors.value.isAgree) {
        return;
    }

    loading.value = true;

    fetch(`${import.meta.env.VITE_API_URL}/forms/application?` + new URLSearchParams(formData.value))
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            loading.value = false;
            successMessage.value = 'Данные успешно отправлены!';
            resetForm();
            setTimeout(() => {
                successMessage.value = '';
            }, 2000);
        });

    /*
    setTimeout(() => {
        loading.value = false;
        successMessage.value = 'Данные успешно отправленны!';
        resetForm();

        setTimeout(() => {
            successMessage.value = '';
        }, 2000);
    }, 3000);
    */


}
</script>

<template>
    <form @submit.prevent="submitHandler" class="">
        <div class="md:grid grid-cols-[auto_147px] gap-x-4 mb-3.5">
            <input
                type="text"
                class="border rounded-40 block outline-none placeholder:text-white px-7.5 xl:py-5 md:py-3 py-5 bg-transparent md:mx-0 mx-auto"
                placeholder="Имя"
                v-model.trim="formData.name"
                :class="{
                    'border-white': !errors.name,
                    'border-red-500': errors.name,
                }"
                @input="handleNameInput"
            />
            <span class="md:block hidden" />
        </div>
        <div class="md:grid grid-cols-[auto_147px] gap-x-4">
            <input
                type="text"
                class="border rounded-40 block outline-none placeholder:text-white px-7.5 xl:py-5 md:py-3 py-5 bg-transparent md:mx-0 mx-auto"
                placeholder="Телефон"
                v-model.trim="formData.phone"
                :class="{
                    'border-white': !errors.phone,
                    'border-red-500': errors.phone,
                }"
                @input="handlePhoneInput"
            />
            <button
                type="submit"
                class="bg-white text-blue outline-none rounded-80 lg:px-7.5 md:px-3 px-7.5 md:py-0 py-5 hover:underline block md:mt-0 mt-4 md:mx-0 mx-auto"
                v-html="'Отправить'"
                :disabled="loading"
            />
        </div>
        <div
            class="grid gap-2 mt-2 text-14 leading-1.2 text-white"
            v-if="errors.name || errors.phone || errors.isAgree"
        >
            <p v-if="errors.name" v-html="errors.name" />
            <p v-if="errors.phone" v-html="errors.phone" />
            <p v-if="errors.isAgree" v-html="errors.isAgree" />
        </div>
        <p class="mt-3 text-center" v-text="'Отправка данных...'" v-if="loading" />
        <p class="mt-3 text-center text-white" v-text="successMessage" v-if="successMessage" />

        <div class="md:grid grid-cols-[auto_147px] gap-x-4 md:mt-6.5 mt-9.5 md:px-0 px-7.5">
            <label
                for="about-is-agree"
                class="md:pl-5 flex items-start cursor-pointer"
                :class="{
                    invalid: errors.isAgree,
                }"
            >
                <input
                    type="checkbox"
                    :checked="formData.isAgree"
                    id="about-is-agree"
                    hidden
                    @change="handleCheckbox"
                />
                <span
                    class="relative pl-5 text-[13px] select-none font-inter font-light"
                    v-html="
                        'Нажимая кнопку отправить, вы даете свое согласие на обработку персональных данных'
                    "
                />
            </label>
        </div>
    </form>
</template>

<style scoped lang="scss">
label[for='about-is-agree'] {
    &.invalid {
        span {
            &:before {
                border: 1px solid red;
            }
        }
    }

    & span {
        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
        }

        &:before {
            top: 3px;
            left: 0;
            width: 12px;
            height: 12px;
            border: 1px solid white;
            border-radius: 3px;
        }

        &:after {
            display: none;
            width: 8px;
            height: 8px;
            background-color: white;
            top: 5px;
            left: 2px;
            border-radius: 2px;
        }
    }

    & input:checked {
        & + span {
            &:after {
                display: block;
            }
        }
    }
}
</style>
