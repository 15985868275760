﻿<script setup lang="ts">
import DogPaw from '@/components/images/DogPaw.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted, ref } from 'vue';
import icon1 from '@/assets/images/seven-section-icon-1.svg';
import icon2 from '@/assets/images/seven-section-icon-2.svg';
import icon3 from '@/assets/images/seven-section-icon-3.svg';
import icon4 from '@/assets/images/seven-section-icon-4.svg';

const data = [
    {
        title: '#оbritитедруга',
        text: 'Благотворительный проект',
        url: 'https://obrititedruga.brit-rus.ru/',
        icon: icon1,
        button: 'Перейти на сайт',
    },
    {
        title: 'Оптовым покупателям',
        text: 'Оптовым покупателям',
        url: 'https://continentzoo.ru/partners/',
        icon: icon2,
        button: 'Подробнее',
    },
    {
        title: 'Заводчикам/питомникам',
        text: 'Оптовым покупателям',
        url: '/for-breeders-&-kennels',
        icon: icon3,
        button: 'Подробнее',
    },
    {
        title: 'Ветеринарным клиникам',
        text: 'Оптовым покупателям',
        url: '/for-veterinary-clinics',
        icon: icon4,
        button: 'Подробнее',
    },
];

gsap.registerPlugin(ScrollTrigger);
const wrap = ref(null);

function timeline(item: HTMLElement, idx: number) {
    const multiplier = idx % 2 === 0 ? -1 : 1;

    gsap.set(item, {
        opacity: 0,
        xPercent: 75 * multiplier,
    });

    return { opacity: 1, xPercent: 0 };
}

function animate() {
    if (window.matchMedia('(max-width: 1024px)').matches) return;

    //@ts-ignore
    const cards = [...wrap.value.querySelectorAll('.seven-section-card')];

    //@ts-ignore
    const tl = gsap.timeline({
        stagger: 1,
        scrollTrigger: {
            trigger: wrap.value,
            start: '-=5%',
            end: '+=200%',
            pin: true,
            scrub: true,
        },
    });

    cards.forEach((item, idx) => tl.to(item, timeline(item, idx)));
}

onMounted(animate);
</script>

<template>
    <section class="relative z-10 px-5 pt-7.5 pb-15 bg-turquoise-200" ref="wrap">
        <div class="absolute z-10 left-0 w-full block bg-turquoise-200 -top-[5%] -bottom-[200%]" />
        <div
            class="max-w-[1239px] hg:pl-8.5 mx-auto grid md:grid-cols-8 grid-cols-2 md:gap-x-0 gap-x-3 md:gap-y-6.5 gap-y-4 relative z-20"
        >
            <DogPaw
                color="#E0F2F2"
                class="absolute z-10 w-[114px] h-auto top-10.5 rotate-[75deg] left-[70%] md:block hidden"
                data-speed="0.92"
            />
            <DogPaw
                color="#E0F2F2"
                class="absolute z-10 w-[216px] h-auto top-[450px] rotate-[43.5deg] left-auto right-10.5 md:block hidden"
                data-speed="1.1"
            />
            <DogPaw
                color="#E0F2F2"
                class="absolute z-10 w-[216px] h-auto top-auto bottom-0 -rotate-[24deg] left-19 md:block hidden"
                data-speed="0.9"
            />
            <div
                class="rounded-30 bg-turquoise overflow-hidden relative z-20 md:pt-7 pt-5.5 md:pl-9 md:pr-9 md:pb-3.5 pb-2.5 md:shadow-none shadow-[0_4px_10px_0_rgba(156,171,171,.5)] seven-section-card"
                v-for="(item, idx) in data"
                :key="idx"
                :class="
                    idx % 2 === 0
                        ? 'col-start-1 lg:col-end-6 md:col-end-7 pl-6.5 pr-15.5'
                        : 'lg:col-start-4 md:col-start-2 md:col-end-9 md:text-right pl-12.5 pr-6.5'
                "
            >
                <p
                    class="relative z-20 font-gill 2xl:text-30 md:text-28 text-18 leading-1 md:mb-2.5"
                >
                    {{ item.title }}
                </p>
                <p class="relative z-20 mb-6 text-20 leading-1 md:block hidden">
                    {{ item.text }}
                </p>

                <RouterLink
                    v-if="!item.url.includes('http')"
                    :to="item.url"
                    class="md:relative absolute md:inset-[unset] inset-0 z-40 text-white block md:w-fit md:bg-blue md:border border-blue md:rounded-full transition-all duration-300 hover:text-blue hover:bg-transparent text-20 2xl:py-5 py-2.5 px-9.5"
                    :class="idx % 2 === 0 ? '' : 'ml-auto'"
                >
                    <span class="md:inline hidden" v-html="item.button" />
                </RouterLink>
                <a
                    v-else
                    :href="item.url"
                    class="md:relative absolute md:inset-[unset] inset-0 z-40 text-white block md:w-fit md:bg-blue md:border border-blue md:rounded-full transition-all duration-300 hover:text-blue hover:bg-transparent text-20 2xl:py-5 py-2.5 px-9.5"
                    :class="idx % 2 === 0 ? '' : 'ml-auto'"
                    target="_blank"
                >
                    <span class="md:inline hidden" v-html="item.button" />
                </a>
                <img
                    :src="item.icon"
                    alt="img"
                    class="absolute z-10 h-[90%] w-auto top-1/2 -translate-y-1/2"
                    :class="
                        idx % 2 === 0
                            ? 'left-auto right-[.525rem] md:translate-x-0 translate-x-[50%]'
                            : 'left-[.525rem] md:-translate-x-0 -translate-x-[50%]'
                    "
                />
            </div>
        </div>
    </section>
</template>
