﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts"></script>

<template>
    <svg width="66" height="64" viewBox="0 0 66 64" fill="none">
        <g clip-path="url(#clip0_1_2701)">
            <rect
                x="8.08203"
                y="16.9922"
                width="50.2905"
                height="30.3838"
                class="fill-blue-400 transition-all duration-300 group-hover:fill-white"
            />
            <path
                d="M58.243 0.519531H8.4442C4.51365 0.519531 1.33008 3.61628 1.33008 7.43963V55.8803C1.33008 59.7037 4.51365 62.8005 8.4442 62.8005H58.243C62.1736 62.8005 65.3572 59.7037 65.3572 55.8803V7.43963C65.3572 3.61628 62.1736 0.519531 58.243 0.519531ZM54.3552 44.4068L48.1837 44.4899C48.1837 44.4899 46.8533 44.7424 45.1104 43.5729C42.8018 42.0298 40.6249 38.0161 38.9282 38.5386C37.2066 39.0714 37.2599 42.6837 37.2599 42.6837C37.2599 42.6837 37.2742 43.3204 36.8793 43.7356C36.4525 44.1819 35.6166 44.1369 35.6166 44.1369H32.8528C32.8528 44.1369 26.7559 44.6283 21.3812 39.1787C15.5263 33.2378 10.3543 21.5117 10.3543 21.5117C10.3543 21.5117 10.052 20.7712 10.3757 20.3941C10.7421 19.9754 11.7309 19.965 11.7309 19.965L18.3364 19.9339C18.3364 19.9339 18.9589 20.0411 19.4071 20.3595C19.7734 20.6224 19.9833 21.1172 19.9833 21.1172C19.9833 21.1172 21.0504 23.7503 22.4661 26.1343C25.2264 30.7811 26.5105 31.7984 27.446 31.3001C28.8119 30.5735 28.4029 24.733 28.4029 24.733C28.4029 24.733 28.4278 22.612 27.7164 21.6674C27.1615 20.9304 26.1192 20.7124 25.6604 20.657C25.2869 20.6086 25.8951 19.7678 26.6884 19.3872C27.8764 18.8232 29.9786 18.7886 32.4579 18.8128C34.3894 18.8336 34.9479 18.9478 35.702 19.1277C37.982 19.664 37.2066 21.7331 37.2066 26.6983C37.2066 28.2899 36.9114 30.5251 38.0887 31.2621C38.5974 31.5804 39.8353 31.3105 42.9263 26.1896C44.3954 23.7607 45.4945 20.9062 45.4945 20.9062C45.4945 20.9062 45.7364 20.3975 46.1099 20.1795C46.4941 19.9546 47.0099 20.0238 47.0099 20.0238L53.9639 19.9823C53.9639 19.9823 56.0519 19.7367 56.3934 20.6605C56.7491 21.6258 55.6144 23.8783 52.7723 27.5633C48.109 33.6184 47.5897 33.0544 51.4633 36.556C55.1626 39.9018 55.9274 41.5281 56.0555 41.7322C57.585 44.2096 54.3552 44.4068 54.3552 44.4068Z"
                class="fill-white transition-all duration-300 group-hover:fill-blue-400"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_2701">
                <rect
                    width="65.1912"
                    height="62.863"
                    fill="white"
                    transform="translate(0.748047 0.228516)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
