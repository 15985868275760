import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

window.statePreventScrollTop = false;
window.stateOptions = {};
window.statePage = {};
window.statePages = {};


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/catalog',
            name: 'catalog',
            component: () => import('@/views/CatalogView.vue'),
        },
        {
            path: '/catalog/:filters*',
            name: 'catalog-filters',
            component: () => import('@/views/CatalogView.vue'),
        },

        // {
        //     path: '/catalog/category/:cat/:filters*',
        //     name: 'catalog-category',
        //     component: () => import('@/views/CatalogView.vue'),
        // },
        // {
        //     path: '/catalog/category/:cat/:filters*',
        //     name: 'catalog-category',
        //     component: () => import('@/views/CatalogView.vue'),
        // },
        // {
        //     path: '/catalog/category/:cat*',
        //     name: 'catalog-category',
        //     component: () => import('@/views/CatalogView.vue'),
        // },
        // {
        //     path: '/catalog/page/:page',
        //     name: 'catalog-page',
        //     component: () => import('@/views/CatalogView.vue'),
        // },
        // {
        //     path: '/catalog/category/:cat/page/:page',
        //     name: 'catalog-category-page',
        //     component: () => import('@/views/CatalogView.vue'),
        // },

        {
            path: '/product/:slug',
            name: 'product',
            component: () => import('@/views/ProductView.vue'),
        },
        {
            path: '/where-to-buy',
            name: 'where-to-buy',
            component: () => import('@/views/WhereToBuyView.vue'),
        },
        {
            path: '/where-to-buy/:article',
            name: 'where-to-buy-article',
            component: () => import('@/views/WhereToBuyView.vue'),
        },
        {
            path: '/product-line',
            name: 'product-line',
            component: () => import('@/views/ProductLineView.vue'),
        },
        {
            path: '/brit-premium',
            name: 'brit-premium',
            component: () => import('@/views/PresentationView.vue'),
        },

        {
            path: '/brit-care',
            name: 'brit-care',
            component: () => import('@/views/BritCareView.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/AboutView.vue'),
        },
        {
            path: '/interesting',
            name: 'interesting',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles',
            name: 'articles',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/articles/:slug',
            name: 'article',
            component: () => import('@/views/SingleArticleView.vue'),
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/news/:slug',
            name: 'news-single',
            component: () => import('@/views/SingleNewsView.vue'),
        },
        {
            path: '/events',
            name: 'events',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/events/:slug',
            name: 'event',
            component: () => import('@/views/SingleEventView.vue'),
        },
        {
            path: '/contests',
            name: 'contests',
            component: () => import('@/views/InterestingView.vue'),
        },
        {
            path: '/contests/:id',
            name: 'contest',
            component: () => import('@/views/SingleContestView.vue'),
        },
        {
            path: '/for-pet-owners',
            name: 'for-pet-owners',
            component: () => import('@/views/ForPetOwnersView.vue'),
        },
        {
            path: '/for-breeders-&-kennels',
            name: 'for-breeders-&-kennels',
            component: () => import('@/views/ForBreeders&KennelsView.vue'),
        },
        {
            path: '/for-veterinary-clinics',
            name: 'for-veterinary-clinics',
            component: () => import('@/views/ForVetClinicsView.vue'),
        },
        {
            path: '/faq',
            name: 'faq',
            component: () => import('@/views/FAQView.vue'),
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('@/views/Search.vue'),
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () => import('@/views/Page.vue'),
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/404',
        },
        {
            path: '/404',
            component: () => import('@/views/NotFound.vue'),
        },

        /*
        {
            path: '/presentation',
            name: 'presentation',
            //component: () => import('@/views/PresentationView.vue'),
            redirect: to => { return { path: '/brit-premium' } },
        },
        */

        // {
        //     path: '/ambassadors',
        //     name: 'ambassadors',
        //     component: () => import('@/views/AmbassadorsView.vue'),
        // },
        // {
        //     path: '/ambassador/:id',
        //     name: 'ambassador',
        //     component: () => import('@/views/AmbassadorView.vue'),
        // },
        // {
        //     path: '/for-partners',
        //     name: 'for-partners',
        //     component: () => import('@/views/ForPartnersView.vue'),
        // },
    ],
    async scrollBehavior(to, from, savedPosition) {

        await getStateOptions();
        let delay = 100;

        if (from.name === 'home') {
            resetFooterDogPawsPosition();
            delay = 500;
        }
        if(!to.path.includes('/catalog')){
            setStatePage(to.path);
        }

        if (savedPosition) {
            await new Promise(resolve => setTimeout(resolve, delay));
            return savedPosition;
        }
        else if (window.statePreventScrollTop) {
            window.statePreventScrollTop = false;
            return;
        }
        else {
            return { top: 0 }
        }
    },
});




async function getStateOptions() {
    if(window.stateOptionsRequest === undefined) {
        window.stateOptionsRequest = true;
        try {
            const response = await fetch(`${import.meta.env.VITE_API_URL}/options?`)
                .then((response) => {
                    return response.json();
                });
            if(response) {
                window.stateOptions = response;
            }
        } catch (e) {
            console.log(e);
        }
    }
    window.dispatchEvent(new CustomEvent('stateOptionsLoaded'));
}


async function getStatePage(url: string) {
    let page = null;
    if(window.statePages[url] !== undefined){
        page = window.statePages[url];
    }
    else{
        const response = await fetch(`${import.meta.env.VITE_API_URL}/pages?` + new URLSearchParams({url}))
            .then((response) => {
                return response.json();
            });
        page = response.page !== undefined ? response.page : {};
        window.statePages[url] = page;
    }
    window.statePage = page;
    return page;
}

function setStatePage(url: string) {
    getStatePage(url).then(function (page){
        window.dispatchEvent(new CustomEvent('statePageLoaded'));
        document.title = page?.meta_title ?? 'Brit — сухие и влажные корма для кошек и собак | Брит-Рус';
        // @ts-ignore
        document.querySelector('meta[name="description"]').setAttribute("content", page?.meta_description ?? '');
        // @ts-ignore
        document.querySelector('meta[name="keywords"]').setAttribute("content", page?.meta_keywords ?? '');
    });
}

function resetFooterDogPawsPosition() {
    const appFooter = document.querySelector('footer.app-footer');
    if (!appFooter) return;
    const paws = [...appFooter.querySelectorAll('svg[data-speed]')];
    paws.forEach((paw) => paw.removeAttribute('style'));
}

export default router;
