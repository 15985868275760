﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import FeedImage from '@/components/home-page/quiz/FeedImage.vue';
import DogImage from '@/components/home-page/quiz/DogImage.vue';
import DogSmallImage from '@/components/home-page/quiz/DogSmallImage.vue';
import DogSmallSizeSmall from '@/components/home-page/quiz/DogSmallSizeSmall.vue';
import CatSmallImage from '@/components/home-page/quiz/CatSmallImage.vue';
import DogSizeSmallPic from '@/components/home-page/quiz/DogSizeSmallPic.vue';
import DogMedium from '@/components/home-page/quiz/DogMedium.vue';
import CatImage from '@/components/home-page/quiz/CatImage.vue';
import type { AnimalTypes } from '@/components/home-page/quiz/QuizTest.vue';
import { ref } from 'vue';

interface Props {
    stage: number;
    stagesCount: number;
    type: AnimalTypes;
    category: 'Котенок' | 'Взрослый' | 'Стерилизованный' | null;
    dogSize: 'Крупный' | 'Средний' | 'Маленький' | null;
    dogAge: 'До 1 года' | 'Старше 1 года';
}

const props = defineProps<Props>();
const progressLine = ref(null);

function getDogPosition(type: AnimalTypes): number {
    if (!progressLine.value) return 0;

    const progressLineWidth = parseInt(window.getComputedStyle(progressLine.value).width) - 120;
    let percent = (100 / props.stagesCount) * props.stage;
    if (type === 'cat') {
      percent = (100 / props.stagesCount) * (props.stage * 0.9);
    }
    return (progressLineWidth * percent) / 100;
}
</script>

<template>
  <div class="h-[61px] w-full relative" ref="progressLine">
    <div class="absolute w-full h-2.5 bg-turquoise-600 rounded-full left-0 top-auto bottom-0.5 z-10" />
    <FeedImage class="absolute z-20 bottom-0 top-auto left-auto -right-1" />
    <p class="absolute z-20 text-46 leading-1.2 font-gill left-1 -bottom-3.5" v-if="!type">?</p>
    <DogImage v-else-if="type === 'dog' && dogAge !== 'До 1 года' && dogSize !== 'Маленький' && dogSize !== 'Средний'"
      class="absolute z-20 bottom-0.5 transition-all duration-300" :offset="getDogPosition(type)" />
    <CatImage v-else-if="type === 'cat' && category !== 'Котенок'"
      class="absolute z-20 bottom-0.5 transition-all duration-300" :offset="getDogPosition(type)" />
    <CatSmallImage v-else-if="category === 'Котенок'" class="absolute z-20 bottom-0.5 transition-all duration-300"
      :offset="getDogPosition(type)" />
    <DogMedium v-else-if="dogSize === 'Средний' && dogAge !== 'До 1 года'" class="absolute z-20 bottom-0.5 transition-all duration-300"
      :offset="getDogPosition(type)" />
    <DogSmallSizeSmall v-else-if="dogSize === 'Маленький' && dogAge === 'До 1 года'" class="absolute z-20 bottom-0.5 transition-all duration-300"
      :offset="getDogPosition(type)" />
    <DogSizeSmallPic v-else-if="dogSize === 'Маленький'" class="absolute z-20 bottom-0.5 transition-all duration-300"
      :offset="getDogPosition(type)" />
    <DogSmallImage v-else-if="dogAge === 'До 1 года'" class="absolute z-20 bottom-0.5 transition-all duration-300"
      :offset="getDogPosition(type)" />
  </div>
</template>

<style scoped lang="scss"></style>
