﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg width="214" height="251" viewBox="0 0 214 251" fill="none" class="max-w-full">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M99.9245 1.46056C126.364 6.03065 135.083 39.6244 155.115 57.5474C172.215 72.8475 198.58 77.6525 208.339 98.4609C218.551 120.234 211.996 145.283 207.337 168.891C202.038 195.74 202.792 229.602 179.615 244.001C156.332 258.467 126.168 243.462 99.9245 235.625C80.3338 229.774 64.9599 217.094 48.5488 204.859C32.3444 192.778 12.8086 183.503 4.96432 164.828C-3.19544 145.402 1.96571 123.856 5.81836 103.13C10.2692 79.1863 12.4473 53.581 28.9598 35.7414C47.28 15.9487 73.407 -3.12303 99.9245 1.46056Z"
            fill="#C2E9E9"
        />
    </svg>
</template>
