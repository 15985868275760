<script setup lang="ts">
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const isMobile = window.matchMedia('(max-width: 767px)').matches;

const slides = ref();

window.addEventListener('stateOptionsLoaded', function(event) {
    if(window?.stateOptions?.promo?.home_slider?.length){
        slides.value = window.stateOptions.promo.home_slider;
    }
});

const modules = [EffectCoverflow, Pagination, Navigation];

function spaceBetween(): number {
    if (window.matchMedia('(max-width: 767px)').matches) return 35;
    if (window.matchMedia('(max-width: 1024px)').matches) return 70;
    return 100;
}

</script>

<template>

    <div class="second-section md:pt-8 md:pb-20 pb-10 overflow-hidden" v-if="slides?.length">
        <swiper
            slides-per-view="auto"
            :grabCursor="true"
            :centeredSlides="true"
            effect="coverflow"
            :freeMode="true"
            :modules="modules"
            :loop="true"
            :navigation="{}"
            :coverflowEffect="{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }"
            :space-between="spaceBetween()"
            :pagination="{ clickable: true }"
            :mousewheel="{
                forceToAxis: true,
            }"
        >
            <swiper-slide
                v-for="(slide, idx) in slides"
                :key="idx"
                class="w-fit xl:max-w-[1106px] lg:max-w-[800px] md:max-w-[650px] max-w-[75vw] md:rounded-30 rounded-10 overflow-hidden select-none"
            >

                <RouterLink v-if="slide.url && !slide.url.includes('http')"
                    :to="slide.url"
                    class="absolute w-full h-full z-20 top-0 left-0"
                />
                <a v-else
                   :href="slide.url"
                   :class="isMobile ? 'aspect-square' : ''"
                   target="_blank"
                   class="absolute w-full h-full z-20 top-0 left-0"
                />

                <figure class="relative z-10 w-full h-full" v-if="slide.images?.length">
                    <img
                        :src="isMobile ? (slide.images[1] ?? slide.images[0]) : slide.images[0]"
                        alt="img"
                        class="relative z-0 md:h-[500px] h-[268px] object-center object-cover w-full"
                        :class="isMobile ? 'h-full' : ''"
                    />
                </figure>
            </swiper-slide>
        </swiper>
    </div>
</template>

<style lang="scss">
.second-section {
    .swiper-3d {
        .swiper-slide-shadow-left {
            background-image: linear-gradient(
                to left,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }

        .swiper-slide-shadow-right {
            background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }
    }

    .swiper {
        position: relative;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
        top: 30px;
        bottom: 50px;
        height: auto;
        width: calc((100% - 1200px) / 2);
        opacity: 1;

        @media (max-width: 1440px) {
            width: calc((100% - 0px) / 2);
        }

        @media (max-width: 1280px) {
            width: calc((100% - 900px) / 2);
        }

        @media (max-width: 1024px) {
            width: calc((100% - 650px) / 2);
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .swiper-pagination {
        position: relative;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 28px;

        @media (max-width: 767px) {
            column-gap: 18px;
            margin-top: 26px;
        }

        .swiper-pagination-bullet {
            display: block;
            width: 12px;
            height: 12px;
            background-color: #5fd0df;
            margin: 0;
            opacity: 1;

            @media (max-width: 767px) {
                width: 8px;
                height: 8px;
            }

            &.swiper-pagination-bullet-active {
                background-color: var(--dark-blue-color);
            }
        }
    }
}
</style>
