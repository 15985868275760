﻿<script setup lang="js">
import img from '@/assets/images/fourth-section-img-2x.png';
import jar from '@/assets/images/fourth-section-jar.png';
import packBottom from '@/assets/images/fourth-section-pack-bottom.png';
import packTop from '@/assets/images/fourth-section-pack-top.png';
import DogPaw from '@/components/images/DogPaw.vue';
import ProductSlider from '@/components/ProductSlider.vue';
import { ref } from 'vue';
import QuizTest from '@/components/home-page/quiz/QuizTest.vue';

const isQuizOpen = ref(false);

const props = defineProps([ 'products', 'categories' ]);

function closeQuiz() {
    isQuizOpen.value = false;
}
</script>

<template>

    <div class="relative z-20 bg-turquoise-200 mt-33.5">
        <svg class="absolute -z-50 opacity-0">
            <defs>
                <clipPath id="mask-image" clipPathUnits="objectBoundingBox">
                    <path
                        d="M-0.156592 0.0477309L-0.234657 -0.101281C-0.180961 -0.0238781 0.0300993 0.0375311 0.185366 -0.0336637C0.320569 -0.0956589 0.398804 -0.098554 0.478599 -0.0913648C0.492937 -0.090073 0.511128 -0.0872686 0.532065 -0.0840381C0.627651 -0.0692897 0.780689 -0.0456776 0.88779 -0.116741C1.04022 -0.217884 1.21449 -0.135298 1.28268 -0.102984C1.29301 -0.0980897 1.3009 -0.0943485 1.30592 -0.0925733L1.05873 0.121499L1.27991 1.00136C1.21812 0.876827 0.980117 0.77164 0.807379 0.87842C0.656962 0.971401 0.569259 0.973015 0.479601 0.958612C0.463492 0.956024 0.44304 0.950896 0.419489 0.944989C0.311967 0.918024 0.139822 0.874853 0.0211111 0.983251C-0.147846 1.13753 -0.344999 1.00023 -0.422143 0.946504C-0.433827 0.938367 -0.442757 0.932148 -0.448421 0.929148L-0.223916 0.0532525L-0.156592 0.0477309Z"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="mask-image-mob" clipPathUnits="objectBoundingBox">
                    <path
                        d="M0.682963 0.957782C0.37253 0.961475 0.261843 1.00701 -0.0435005 0.936244L-0.292864 -3.17837H1.29492V1.13086C1.18296 1.06445 0.993395 0.95409 0.682963 0.957782Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>


        <div class="bg-turquoise-300 masked overflow-hidden">
            <h2
                class="xl:mb-7.5 mb-6 font-bold font-gill xl:text-60 md:text-48 text-24 text-center"
            >
                Наши продукты
            </h2>
            <ProductSlider :products="props.products" :categories="props.categories"/>
        </div>
        <div class="md:pt-16.5 pt-14 pb-24 px-5 relative md:overflow-visible overflow-hidden">
            <div class="flex justify-center max-w-[1528px] mx-auto xl:items-start items-center relative">
                <DogPaw
                    color="#E0F2F2"
                    class="w-50.5 h-auto absolute z-10 -rotate-[27.35deg] left-auto right-11 -top-33.5 md:block hidden"
                    data-speed="0.93"
                />
                <DogPaw
                    color="#E0F2F2"
                    class="w-24.5 h-auto absolute z-10 -rotate-[15deg] left-auto right-7 top-auto -bottom-18.5 md:block hidden"
                    data-speed="0.95"
                />
                <DogPaw
                    color="#E0F2F2"
                    class="w-38 h-auto absolute z-10 rotate-[75deg] left-1/2 -translate-x-1/2 top-auto -bottom-7 md:block hidden"
                    data-speed="0.9"
                />

                <figure class="absolute md:hidden block z-30 -left-6 -bottom-10 top-auto">
                    <img :src="jar" alt="jar" />
                </figure>
                <figure
                    class="absolute md:hidden block z-30 rotate-[15deg] top-auto left-auto -bottom-8.5 -right-13"
                >
                    <img :src="packBottom" alt="img" />
                </figure>
                <figure
                    class="absolute md:hidden block z-10 -top-13 left-1/2 -translate-x-1/2 rotate-[15deg]"
                >
                    <img :src="packTop" alt="img" />
                </figure>

                <div
                    class="md:bg-white  bg-turquoise-300 xl:rounded-60 rounded-30 shadow-[0_9px_10px_1px_rgba(156,171,171,.5)] xl:pt-22 md:pt-11 pt-18 2xl:pl-21 xl:pl-15 md:pl-11 pl-3 hg:pr-28 2xl:pr-20 xl:pr-15 md:pr-11 pr-3 hg:pb-37.5 xl:pb-20 md:pb-11 pb-19.5 mt-1 z-20 lg:min-w-[425px] md:min-w-[365px] overflow-hidden relative max-w-[816px]"
                >
                    <DogPaw
                        color="#ffffff"
                        class="w-100 h-auto absolute z-10 -rotate-[17deg] md:hidden block left-auto top-auto right-4.5 -bottom-3.5"
                    />
                    <p
                        class="hg:mb-28.5 xl:mb-20 md:mb-6.5 mb-7.5 font-gill 2xl:text-46 xl:text-34 lg:text-32 text-24 md:text-left text-center relative z-20 leading-1.2"
                        v-html="'Не знаете что подойдет вашему питомцу?'"
                    />
                    <a
                        href="#"
                        class="transition-all duration-300 bg-blue border border-blue text-white xl:text-20 text-center xl:py-4.5 py-2.5 block w-fit xl:px-9.5 px-5 rounded-full hover:bg-white hover:text-blue md:mx-0 mx-auto relative z-20"
                        v-html="'Пройти тест'"
                        @click.prevent="isQuizOpen = true"
                    />
                </div>
                <figure class="md:block hidden max-w-[682px]">
                    <img :src="img" class="relative z-20" alt="img" />
                </figure>
            </div>
        </div>
    </div>

    <Teleport to="body">
        <QuizTest :show="isQuizOpen" @close="closeQuiz" />
    </Teleport>

</template>

<style scoped lang="scss">
.masked {
    clip-path: url(#mask-image);

    @media (max-width: 767px) {
        clip-path: url(#mask-image-mob);
    }
}
</style>
