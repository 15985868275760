﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<script setup lang="ts">
import RatingStar from '@/components/icons/RatingStar.vue';

interface Review {
    value: number;
    img: string;
    text: string;
    url: string;
}

interface Props {
    review: Review;
}

defineProps<Props>();

function calculatedUnfilledStarWidth(rating: number, index: number): string {
    return `${
        rating - index > -1 ? (100 - Math.abs(rating - index) * 100).toFixed(0).toString() : '0'
    }%`;
}
</script>

<template>
    <article
        class="bg-white rounded-30 pb-7 pt-3.5 xl:px-9.5 px-5 xl:w-[415px] md:w-[250px] w-[331px] xl:mr-9 lg:mr-5 mr-4.5 xl:h-[287px] h-50 flex flex-col justify-between gap-y-4"
    >
        <div class="grow flex flex-col justify-center">
            <figure class="lg:h-10 h-8 lg:max-w-[235px] max-w-[150px] mb-5.5 flex items-end">
                <img :src="review.img" alt="img" class="h-auto w-auto max-w-full max-h-full" />
            </figure>
            <div class="flex items-center xl:gap-x-7.5 gap-x-5">
                <div class="flex items-center gap-x-1 pb-2">
                    <span v-for="n in 5" :key="n" class="relative">
                        <RatingStar
                            class="relative z-10"
                            :color="review.value >= n ? '#8BD1D9' : '#C2E9E9'"
                        />
                        <template v-if="review.value < n">
                            <span
                                class="absolute z-20 top-0 left-0 overflow-hidden"
                                :style="{
                                    width: calculatedUnfilledStarWidth(review.value, n),
                                }"
                            >
                                <RatingStar color="#8BD1D9" />
                            </span>
                        </template>
                    </span>
                </div>
                <p class="font-gill xl:text-36 text-24 leading-none">
                    {{ review.value }}
                </p>
            </div>
        </div>
        <a
            target="_blank"
            :href="review.url"
            class="font-inter xl:text-28 text-20 font-medium cursor-pointer"
            v-html="`${review.text}&nbsp;&#8594;`"
        />
    </article>
</template>

<style scoped lang="scss"></style>
