﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg
        width="158"
        height="44"
        viewBox="0 0 158 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M131.88 5.27007C131.88 8.02073 129.623 10.2717 126.86 10.2717C124.098 10.2717 121.839 8.02073 121.839 5.27007C121.839 2.5206 124.098 0.270996 126.86 0.270996C129.623 0.270996 131.88 2.5206 131.88 5.27007Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M67.4566 24.9646H74.9287C78.6944 24.9646 81.4564 26.6546 81.4564 29.7794C81.4564 33.4656 79.3873 35.0282 73.4862 35.0282H67.4566V24.9646ZM56.7865 0.211548V42.8423H77.6254C90.121 42.8423 92.7556 35.2152 92.7556 30.3423C92.7556 25.3398 89.7437 20.9646 83.6542 19.4001C87.2345 17.6505 89.4898 14.4646 89.4898 10.9001C89.4898 6.15212 85.4112 0.211548 75.3062 0.211548H56.7865ZM67.4566 8.02653H73.0415C76.9396 8.02653 78.5059 10.3381 78.5059 12.5266C78.5059 14.7745 76.9396 17.1522 73.0415 17.1522H67.4566V8.02653Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M67.4566 24.9646H74.9287C78.6944 24.9646 81.4564 26.6546 81.4564 29.7794C81.4564 33.4656 79.3873 35.0282 73.4862 35.0282H67.4566V24.9646ZM56.7865 0.211548V42.8423H77.6254C90.121 42.8423 92.7556 35.2152 92.7556 30.3423C92.7556 25.3398 89.7437 20.9646 83.6542 19.4001C87.2345 17.6505 89.4898 14.4646 89.4898 10.9001C89.4898 6.15212 85.4112 0.211548 75.3062 0.211548H56.7865ZM67.4566 8.02653H73.0415C76.9396 8.02653 78.5059 10.3381 78.5059 12.5266C78.5059 14.7745 76.9396 17.1522 73.0415 17.1522H67.4566V8.02653Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M104.581 22.2764H104.707C107.406 16.2769 110.416 13.4646 113.934 13.4646C115.757 13.4646 117.324 14.1499 119.961 16.0879L117.45 24.0886C115.064 22.5879 113.495 21.9639 112.052 21.9639C108.726 21.9639 106.59 25.0909 104.581 30.4027V42.8415H95.4784V14.0242H104.581V22.2764Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M104.581 22.2764H104.707C107.406 16.2769 110.416 13.4646 113.934 13.4646C115.757 13.4646 117.324 14.1499 119.961 16.0879L117.45 24.0886C115.064 22.5879 113.495 21.9639 112.052 21.9639C108.726 21.9639 106.59 25.0909 104.581 30.4027V42.8415H95.4784V14.0242H104.581V22.2764Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M122.284 42.8433H131.384V14.0251H122.284V42.8433Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M122.284 42.8433H131.384V14.0251H122.284V42.8433Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M158 20.2119H147.458V32.0889C147.458 34.8988 149.089 36.7145 151.913 36.7145C153.794 36.7145 155.555 36.2773 158 35.0268V41.5882C154.8 42.9029 152.416 43.4029 149.59 43.4029C143.565 43.4029 138.352 40.6526 138.352 32.5898V20.2119H134.212V18.463L146.328 4.96143H147.458V14.0236H158V20.2119Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M158 20.2119H147.458V32.0889C147.458 34.8988 149.089 36.7145 151.913 36.7145C153.794 36.7145 155.555 36.2773 158 35.0268V41.5882C154.8 42.9029 152.416 43.4029 149.59 43.4029C143.565 43.4029 138.352 40.6526 138.352 32.5898V20.2119H134.212V18.463L146.328 4.96143H147.458V14.0236H158V20.2119Z"
            class="fill-[#545254] group-hover:fill-black/80"
        />
        <path
            d="M3.45578 3.10793C2.6753 3.86879 2.29006 4.85613 2.28834 5.85069C2.29006 6.83272 2.66426 7.80736 3.42715 8.56581L3.47578 8.61853L10.996 17.3566L17.3812 10.2148L17.4019 10.1923L18.9265 8.60101C19.4466 7.84926 19.7549 6.85264 19.7532 5.86889C19.7508 4.77182 19.3864 3.71974 18.6009 2.93948C17.8277 2.17312 16.813 1.78969 15.7893 1.78849C14.7782 1.78969 13.7768 2.16351 13.006 2.91406L11.5296 4.34855C11.178 4.68957 10.616 4.68648 10.2685 4.34237L9.05069 3.13506C8.27625 2.36767 7.26142 1.98476 6.23763 1.98424C5.2278 1.98476 4.22711 2.35789 3.45578 3.10793ZM10.9741 19.6112C10.7144 19.6088 10.4699 19.4957 10.3007 19.2987L2.13314 9.80764C1.04123 8.71125 0.490616 7.27728 0.491823 5.85069C0.490616 4.39337 1.06623 2.92986 2.20004 1.8285C3.31886 0.738809 4.78307 0.194137 6.23763 0.194823C7.71167 0.194823 9.19486 0.753233 10.3185 1.86662L10.9084 2.45181L11.7505 1.63412C12.8686 0.543743 14.3347 -0.00144415 15.7893 -0.000242157C17.2633 -0.000929008 18.7463 0.557652 19.8694 1.67275C21.0417 2.83645 21.5514 4.38118 21.5495 5.86889C21.5471 7.24397 21.1293 8.59672 20.3554 9.68761L20.2721 9.78755L18.7148 11.4135L11.6544 19.3114C11.4833 19.5029 11.24 19.6112 10.9833 19.6112H10.9741Z"
            fill="#FFC222"
        />
        <path
            d="M4.5814 30.9646C4.67383 31.1808 4.68107 31.4274 4.6002 31.6486C4.31308 32.444 4.15426 33.3017 4.15426 34.2013C4.15736 34.9058 4.42499 35.4733 4.89594 36.1184C5.36257 36.757 6.02371 37.4058 6.62658 38.1798C7.37481 39.1492 8.07148 40.033 8.82022 40.6321C9.57639 41.2317 10.3308 41.5739 11.4143 41.5806C12.4636 41.5727 13.1541 41.2705 13.8592 40.7351C14.5564 40.1983 15.2206 39.3908 15.9768 38.4786C16.6247 37.7044 17.3365 37.0084 17.8506 36.3087C18.3682 35.6024 18.6712 34.9511 18.6717 34.2013C18.6717 33.4634 18.5702 32.7359 18.3829 32.0411C18.3287 31.8406 18.3444 31.6237 18.426 31.4322L20.5193 26.5334L16.5462 28.1151C16.2542 28.2308 15.927 28.1951 15.6673 28.0194C14.4815 27.2107 12.988 26.8205 11.4143 26.8205C9.95973 26.8212 8.61278 27.255 7.47396 28.0048C7.21788 28.174 6.89369 28.2066 6.60778 28.0928L2.68694 26.5322L4.5814 30.9646ZM11.4143 43.4507C9.88058 43.4585 8.6195 42.8855 7.63951 42.0858C6.6521 41.2868 5.89111 40.288 5.13925 39.3217C4.64762 38.6814 3.97543 38.0248 3.3824 37.2258C2.79351 36.4328 2.27463 35.4333 2.27635 34.2013C2.27635 33.2156 2.42965 32.2635 2.71384 31.3699L0.0754602 25.1965C-0.0737032 24.8482 0.00234427 24.4466 0.267562 24.1765C0.533469 23.9062 0.934917 23.8214 1.28791 23.9613L6.86455 26.1809C8.20219 25.4006 9.759 24.9513 11.4143 24.9513C13.1225 24.9513 14.8252 25.3328 16.2949 26.2015L21.9192 23.9613C22.2713 23.8209 22.6734 23.9062 22.9394 24.1765C23.2046 24.4466 23.2806 24.8482 23.1315 25.1965L20.2779 31.8763C20.4543 32.6256 20.5503 33.4064 20.5503 34.2013C20.5503 35.4934 20.0004 36.5565 19.3634 37.4149C18.7229 38.2805 17.9789 39.0001 17.4222 39.6725C16.6747 40.566 15.9525 41.479 15.012 42.2113C14.0854 42.9389 12.8975 43.4507 11.4483 43.4507H11.4143Z"
            fill="#8AD4DF"
        />
        <path
            d="M34.0642 43.4034C33.0739 43.4022 32.1618 43.0861 31.4665 42.5325C30.7743 41.9835 30.2863 41.1531 30.287 40.208V38.3272H28.3974C27.4472 38.3277 26.6138 37.8413 26.0632 37.152C25.5079 36.459 25.1915 35.5498 25.1898 34.5648V32.7668C25.1915 31.7819 25.5079 30.8739 26.0632 30.1808C26.6138 29.4916 27.4472 29.0063 28.3974 29.0068H30.287V27.1254C30.2863 26.1786 30.7743 25.3487 31.4665 24.8004C32.1625 24.2475 33.0744 23.9319 34.0642 23.9307H35.8697C36.86 23.9319 37.7721 24.2475 38.4674 24.8004C39.1595 25.3487 39.6481 26.1786 39.6469 27.1254V29.0068H41.5358V30.7957H37.8505V27.1254C37.8493 26.8007 37.6929 26.4808 37.343 26.1968C36.9969 25.9181 36.4701 25.7194 35.8697 25.7201H34.0642C33.4643 25.7194 32.9375 25.9181 32.5914 26.1973C32.2416 26.4808 32.0846 26.8007 32.084 27.1254V30.7957H28.3974C28.0715 30.7969 27.7507 30.9527 27.4655 31.3009C27.1863 31.6455 26.9861 32.1696 26.9868 32.7668V34.5648C26.9861 35.1627 27.1863 35.6878 27.4655 36.0325C27.7507 36.3814 28.0715 36.5366 28.3974 36.5378H32.084V40.208C32.0846 40.5315 32.2416 40.8526 32.5914 41.1368C32.9375 41.4153 33.465 41.6147 34.0642 41.6135H35.8697C36.4696 41.6147 36.9957 41.4153 37.3425 41.1368C37.6929 40.8526 37.8493 40.5315 37.8505 40.208V36.5378H41.5358C41.8619 36.5366 42.1837 36.3814 42.4691 36.0325C42.7488 35.6878 42.949 35.1627 42.9478 34.5648V32.7668C42.949 32.1696 42.7488 31.6455 42.4691 31.3009C42.1837 30.9527 41.8624 30.7969 41.5358 30.7957V29.0068C42.4867 29.0063 43.3201 29.4916 43.8707 30.1808C44.4267 30.8739 44.7436 31.7819 44.7448 32.7668V34.5648C44.7436 35.5503 44.4267 36.459 43.8707 37.1525C43.3201 37.8413 42.4867 38.3277 41.5358 38.3272L39.6469 38.3265V40.208C39.6481 41.1531 39.1602 41.9835 38.4679 42.5325C37.7721 43.0861 36.86 43.4022 35.8697 43.4034H34.0642Z"
            fill="#8DC63F"
        />
        <path
            d="M29.3854 3.78375C29.0343 3.43431 29.0343 2.86732 29.3854 2.51771C30.1835 1.72182 30.9682 1.12392 31.8806 0.744091C32.792 0.363575 33.7879 0.219509 34.962 0.220711C35.4578 0.220711 35.8605 0.621144 35.8605 1.11482C35.8605 1.60901 35.4578 2.01013 34.962 2.01013C33.9253 2.01064 33.2038 2.13307 32.5749 2.39476C31.9458 2.65766 31.3649 3.07801 30.6561 3.78323C30.4809 3.95821 30.2504 4.04544 30.0205 4.04544C29.7905 4.04544 29.5606 3.95821 29.3854 3.78375Z"
            fill="#BE854C"
        />
        <path
            d="M31.92 18.3019C31.1455 17.5362 30.598 16.5578 30.1843 15.5348C29.3656 13.4824 29.0802 11.2406 29.0759 9.93744C29.0759 9.44377 29.4775 9.04333 29.9738 9.04333C30.4702 9.04333 30.8724 9.44377 30.8724 9.93744C30.8705 10.7196 31.0214 12.1268 31.4004 13.5098C31.7722 14.898 32.3944 16.2659 33.1828 17.0299C33.7146 17.5423 34.2535 17.8046 34.961 17.8106C35.4575 17.8106 35.8596 18.2111 35.8596 18.7053C35.8596 19.1991 35.4575 19.5994 34.961 19.5994L34.9415 19.6001C33.7255 19.5994 32.6822 19.0621 31.92 18.3019Z"
            fill="#BE854C"
        />
        <path
            d="M23.6758 18.5166C23.1454 17.8812 22.9191 17.0186 22.9185 15.956C22.9204 14.7204 23.2214 13.1586 23.8991 11.1135C24.463 9.40225 24.5379 8.31067 24.5403 7.40574C24.541 7.00291 24.5227 6.63029 24.522 6.24685C24.5123 5.4611 24.631 4.55806 25.2211 3.64747C25.6208 3.01574 26.1111 2.48755 26.6738 2.10034C27.2347 1.7169 27.8795 1.46912 28.5663 1.46792C29.1078 1.46552 29.6649 1.63328 30.1395 1.96709C30.546 2.25007 30.6444 2.80848 30.3597 3.21321C30.075 3.61776 29.5153 3.71598 29.1083 3.4318C28.9203 3.30473 28.7591 3.25991 28.5663 3.25682C28.3273 3.25614 28.0219 3.34835 27.6927 3.57415C27.3649 3.7984 27.0266 4.14973 26.7419 4.60047C26.3671 5.21589 26.3289 5.56601 26.3185 6.24685C26.3185 6.56796 26.3373 6.95071 26.3373 7.40574C26.3392 8.43121 26.2321 9.79306 25.6049 11.6745C24.9619 13.6056 24.7131 15.019 24.7155 15.956C24.7143 16.7739 24.8998 17.1911 25.0526 17.3663C25.2047 17.5364 25.3609 17.6122 25.7236 17.622C26.3185 17.6364 27.3113 17.2438 28.1466 16.6545C28.9885 16.0832 29.6943 15.2951 29.8671 14.8668C29.9193 14.7494 29.9265 14.6761 29.9253 14.6761C29.9253 14.1817 30.3275 13.7814 30.8233 13.7814C31.3197 13.7814 31.7219 14.1817 31.7219 14.6761C31.7072 15.3473 31.409 15.8664 31.0393 16.3765C30.6608 16.8822 30.1669 17.3651 29.606 17.8036C28.4744 18.6687 27.1149 19.3908 25.7236 19.4114L25.6834 19.4119C24.9742 19.4119 24.1961 19.1447 23.6758 18.5166Z"
            fill="#BE854C"
        />
        <path
            d="M39.415 3.78391C38.7065 3.07869 38.1248 2.65834 37.4964 2.39544C36.8674 2.13375 36.1459 2.01132 35.1098 2.01081C34.6128 2.01081 34.2107 1.60969 34.2107 1.11601C34.2107 0.621138 34.6128 0.220705 35.1098 0.220705C36.2833 0.220189 37.2786 0.364256 38.1905 0.744771C39.1024 1.1246 39.8872 1.7225 40.6859 2.51839V2.51908C41.0363 2.868 41.0363 3.43551 40.6852 3.78443C40.51 3.95889 40.2802 4.04612 40.0508 4.04612C39.8203 4.0456 39.5902 3.95837 39.415 3.78391Z"
            fill="#BE854C"
        />
        <path
            d="M34.2107 18.7053C34.2107 18.2111 34.6135 17.8106 35.1098 17.8106C35.8173 17.8046 36.3557 17.5423 36.8875 17.0299C37.41 16.518 37.868 15.7449 38.2179 14.8697C38.9267 13.1256 39.2034 10.9837 39.1986 9.93744C39.1986 9.44377 39.6006 9.04333 40.097 9.04333C40.5928 9.04333 40.995 9.44377 40.995 9.93744C40.9937 10.9171 40.8282 12.4163 40.4049 13.978C39.9748 15.5341 39.3081 17.1504 38.151 18.3019C37.3845 19.0664 36.3338 19.6055 35.1098 19.5994V19.6001C34.6135 19.6001 34.2107 19.1996 34.2107 18.7053Z"
            fill="#BE854C"
        />
        <path
            d="M44.3472 19.4114C43.2991 19.4023 42.2831 18.9788 41.3524 18.4185C40.4253 17.8496 39.5997 17.1324 39.0315 16.3765C38.6617 15.8664 38.3636 15.3473 38.3489 14.6761H38.3496C38.3496 14.1817 38.7518 13.7814 39.2475 13.7814C39.7438 13.7814 40.146 14.1817 40.146 14.6761C40.1448 14.6767 40.1515 14.7499 40.2044 14.8668C40.2555 14.9838 40.3467 15.1395 40.474 15.3085C40.7269 15.649 41.1187 16.0415 41.5738 16.3958C42.4736 17.1154 43.664 17.6431 44.3472 17.622C44.7098 17.6122 44.8656 17.5364 45.0182 17.3663C45.1703 17.1918 45.3565 16.7739 45.3553 15.956C45.357 15.019 45.1077 13.6056 44.4658 11.6738C43.8387 9.79306 43.7309 8.43121 43.7335 7.40506C43.7335 6.95191 43.7516 6.56916 43.7511 6.24754C43.7407 5.56653 43.703 5.21469 43.3282 4.60047C43.0447 4.14973 42.7054 3.79772 42.3774 3.57415C42.0489 3.34835 41.7435 3.25614 41.5045 3.25682C41.311 3.25991 41.1498 3.30404 40.9637 3.4318C40.5567 3.71598 39.9963 3.61845 39.7111 3.21372C39.4264 2.80917 39.5248 2.25127 39.9313 1.96709C40.4051 1.63328 40.963 1.46552 41.5045 1.46792C42.1913 1.46861 42.8368 1.71639 43.397 2.10034C43.9597 2.48755 44.4495 3.01574 44.8492 3.64747C45.4391 4.55858 45.5577 5.46178 45.5474 6.24754C45.5474 6.62977 45.5298 7.00102 45.5305 7.40506C45.5322 8.31015 45.6078 9.40173 46.1722 11.1142C46.8481 13.1586 47.1499 14.7197 47.1523 15.956C47.1511 17.0179 46.9247 17.8812 46.3943 18.5166C45.8742 19.1454 45.0954 19.4119 44.3862 19.4119L44.3472 19.4114Z"
            fill="#BE854C"
        />
    </svg>
</template>
