﻿import type { TProduct } from '@/types/TProduct';

type Response = {
    products: TProduct[];
    last_page: number;
};

export interface Params {
    page?: number;
    category_id?: number;
    category?: string;
    search?: string;
    animal_id?: number;
    sub_category_id?: number;
    animal_size?: string;
    food_type_id?: number;
    animal_age?: string;
    on_main_page?: boolean;
    measurements?: string[];
    product_advantages?: string[];
    // tag?: string;
    is_featured?: number | undefined;
    is_new?: number | undefined;
}

const BASE_URL = `${import.meta.env.VITE_API_URL}/products`;

export async function getProducts(
    params: Params = {},
    signal: AbortSignal,
): Promise<Response | undefined> {
    const headers = new Headers();
    // headers.append('Authorization', `Bearer ${import.meta.env.VITE_API_KEY}`);


    const searchParams = Object.values(params)
        ? `?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join('&')}`
        : '';

    try {
        const response = await fetch(BASE_URL + searchParams, {
            headers: headers,
            signal: signal,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        // Handle errors
        // @ts-ignore
        if (error.name === 'AbortError') {
            // @ts-ignore
            return 'abort';
        } else {
            console.error('Error fetching data:', error);
        }
    }
}
