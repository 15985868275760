﻿<!--
 © Copyright 2024 webzaytsev.ru.
 @author Nikita Zaytsev <nikita@webzaytsev.ru>
-->
<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <g clip-path="url(#clip0_14_1000)">
            <path
                d="M21.4375 9.8753C21.4375 18.183 12.4375 24.6445 12.4375 24.6445C12.4375 24.6445 3.4375 18.183 3.4375 9.8753C3.4375 7.42715 4.38571 5.07927 6.07354 3.34816C7.76137 1.61706 10.0506 0.644531 12.4375 0.644531C14.8244 0.644531 17.1136 1.61706 18.8015 3.34816C20.4893 5.07927 21.4375 7.42715 21.4375 9.8753Z"
                fill="#161345"
            />
            <path
                d="M12.4375 12.6445C14.0944 12.6445 15.4375 11.3014 15.4375 9.64453C15.4375 7.98768 14.0944 6.64453 12.4375 6.64453C10.7806 6.64453 9.4375 7.98768 9.4375 9.64453C9.4375 11.3014 10.7806 12.6445 12.4375 12.6445Z"
                fill="#FCFBF6"
            />
        </g>
        <defs>
            <clipPath id="clip0_14_1000">
                <rect width="24" height="24" fill="white" transform="translate(0.4375 0.644531)" />
            </clipPath>
        </defs>
    </svg>
</template>
